import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sections } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { isArrayEmpty } from '../../../../utility/Utils'

export const getSections = createAsyncThunk('frontSections/getSections', async () => {
  const response = await axiosRequest.get(sections.FRONT_SECTIONS)
  const responseData = response.data?.data ?? {}
  return {
    sections: responseData?.section ?? [],
    popularClasses: responseData?.popular_classes ?? [],
    mostWatchedVideos: responseData?.most_watched_videos ?? [],
    popularMeditations: responseData?.popular_meditations ?? []
  }
})

export const getSectionContentAndClasses = createAsyncThunk('frontSections/getSectionContentAndClasses', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CONTENT_AND_CLASSES, { id })
  console.log('---getSectionContentAndClasses--', response)
  return { data: response.data?.data ?? {} }
})

export const getSectionCategories = createAsyncThunk('frontSections/getSectionCategories', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CATEGORIES, { id })
  return { data: response.data?.data ?? [] }
})

export const getCategoryContent = createAsyncThunk('frontSections/getCategoryContent', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CATEGORY_CONTENT, { id })
  console.log('------getCategoryContent-------', response?.data?.data?.title)
  
  return { data: response.data?.data ?? [], detail: response?.data?.data?.title ?? '' }
})

export const getClassContentLessons = createAsyncThunk('frontSections/getClassContentLessons', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CLASS_CONTENT_AND_LESSONS, { id })
  return {
    data: response.data?.data ?? {},
    classInfo: response?.data?.detail ?? {},
    tasks: response?.data?.tasks ?? []
  }
})

export const getLessonContent = createAsyncThunk('frontSections/getLessonContent', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_LESSON_CONTENT, { id })
  return {
    data: response.data?.data ?? [],
    lessonDetail: response.data?.detail ?? {},
    tasks: response?.data?.tasks ?? []
  }
})

export const getContentDetail = createAsyncThunk('frontSections/getContentDetail', async (id) => {
  const response = await axiosRequest.post(sections.FRONT_SECTION_CONTENT_DETAIL, { id })
  const responseData = response?.data
  let data = {}
  if (!isArrayEmpty(response?.data?.data)) {
    data = responseData?.data[0] ?? {}
  }
  data.tasks = responseData?.tasks
  return { data, status: responseData?.status }
})
export const setContentDetail = createAsyncThunk('frontSections/setContentDetail', async (data) => {
  return { data }
})


export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    loading: false,
    sections: [],
    popularClasses: [],
    mostWatchedVideos: [],
    popularMeditations: [],
    sectionDetailLoading: false,
    sectionDetail: {},
    sectionCategories: [],
    categoryDetail: '',
    categoryContents: [],
    classInfo: {},
    classDetail: {},
    lessonContent: [],
    lessonDetail: {},
    contentDetail: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSections.fulfilled, (state, { payload }) => {
        state.loading = false
        state.sections = payload.sections
        state.popularClasses = payload.popularClasses
        state.mostWatchedVideos = payload.mostWatchedVideos
        state.popularMeditations = payload.popularMeditations
      })
      .addCase(getSections.pending, (state) => {
        state.loading = true
      })
      .addCase(getSections.rejected, (state) => {
        state.loading = false
      })
      .addCase(getSectionContentAndClasses.fulfilled, (state, { payload }) => {
        state.sectionDetail = payload?.data
        state.sectionDetailLoading = false
      })
      .addCase(getSectionContentAndClasses.pending, (state) => {
        state.sectionDetailLoading = true
      })
      .addCase(getSectionContentAndClasses.rejected, (state) => {
        state.sectionDetailLoading = false
      })
      .addCase(getSectionCategories.fulfilled, (state, { payload }) => {
        state.sectionCategories = payload.data
      })
      // GET CLASS CONTENT AND LESSONS
      .addCase(getClassContentLessons.fulfilled, (state, { payload }) => {
        state.loading = false
        state.classDetail = payload.data
        state.classInfo = payload.classInfo
        state.classTasks = payload.tasks
      })
      .addCase(getClassContentLessons.pending, (state) => {
        state.loading = true
      })
      .addCase(getClassContentLessons.rejected, (state) => {
        state.loading = false
      })
      // GET CATEGORY CONTENT
      .addCase(getCategoryContent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.categoryDetail = payload.detail ?? ''
        state.categoryContents = payload.data
      })
      .addCase(getCategoryContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getCategoryContent.rejected, (state) => {
        state.loading = false
      })
      // GET LESSON CONTENT
      .addCase(getLessonContent.fulfilled, (state, { payload }) => {
        state.loading = false
        state.lessonContent = payload.data
        state.lessonDetail = payload?.lessonDetail
        state.lessonTasks = payload.tasks
      })
      .addCase(getLessonContent.pending, (state) => {
        state.loading = true
      })
      .addCase(getLessonContent.rejected, (state) => {
        state.loading = false
      })
      .addCase(getContentDetail.fulfilled, (state, { payload }) => {
        state.contentDetail = payload.data
      })
      .addCase(setContentDetail.fulfilled, (state, { payload }) => {
        state.contentDetail = payload.data
      })
  }
})

export default appEcommerceSlice.reducer
