import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_PACKAGES: `${API_HOST}admin/packages/all_packages`,
  ADMIN_ADD_PACKAGE: `${API_HOST}admin/packages/add_package`,
  ADMIN_EDIT_PACKAGE: `${API_HOST}admin/packages/edit_package`,
  UPDATE_PACKAGES_STATUS: `${API_HOST}admin/packages/update_packages_status`,
  GET_PACKAGES_STATUS: `${API_HOST}admin/packages/get_packages_status`,
  ADMIN_DELETE_PACKAGE: `${API_HOST}admin/packages/delete_package`
}
