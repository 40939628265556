import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_EMAIL_CONFIG_LIST: `${API_HOST}admin/email_setting/get_emails`,
  ADMIN_EMAIL_CONFIG_ADD_EMAIL: `${API_HOST}admin/email_setting/add_email`,
  ADMIN_EMAIL_CONFIG_EDIT_EMAIL: `${API_HOST}admin/email_setting/edit_email`,
  ADMIN_EMAIL_CONFIG_DELETE_EMAIL: `${API_HOST}admin/email_setting/delete_email`,
  // ADMIN EMAIL TEMPLATES
  ADMIN_EMAIL_CONFIG_EMAIL_TEMPLATES: `${API_HOST}admin/email_setting/email_templates`,
  ADMIN_EMAIL_CONFIG_ADD_EMAIL_TEMPLATE: `${API_HOST}admin/email_setting/add_email_template`,
  ADMIN_EMAIL_CONFIG_EDIT_EMAIL_TEMPLATE: `${API_HOST}admin/email_setting/edit_email_template`,
  ADMIN_EMAIL_CONFIG_DELETE_EMAIL_TEMPLATE: `${API_HOST}admin/email_setting/delete_email_template`,
  // ADMIN EMAIL LOGS
  ADMIN_EMAIL_CONFIG_EMAIL_LOGS: `${API_HOST}admin/email_setting/email_logs`,
  ADMIN_EMAIL_CONFIG_SEND_EMAIL: `${API_HOST}admin/email_setting/send_email`,
  ADMIN_EMAIL_CONFIG_SEND_BULK_EMAIL: `${API_HOST}admin/email_setting/send_bulk_email`,
  ADMIN_EMAIL_CONFIG_GET_ALL_CLINICS: `${API_HOST}admin/users/all_clinics`,
  ADMIN_EMAIL_CONFIG_GET_CLINIC_USERS: `${API_HOST}admin/users/clinic_users`
}
