import axios from "axios"
import { API_HOST } from "./config"
import jwtDefaultConfig from '../@core/auth/jwt/jwtDefaultConfig'
import { isCurrentRouteAdmin } from "../utility/Utils"
const isAdminRoute = isCurrentRouteAdmin()
const jwtConfig = { ...jwtDefaultConfig }
let isAlreadyFetchingAccessToken = false
let subscribers = []
const axiosCall = axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosCall.interceptors.request.use(
  (request) => {
    const accessToken = getToken()
    if (accessToken) {
      request.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`
    }
    return request
  },
  (error) => {
    console.log("error response", error)
    Promise.reject(error)
  }
)
axiosCall.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error
    const originalRequest = config
    // ** if (status === 401) {
    if (response && response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        refreshToken().then(r => {
          isAlreadyFetchingAccessToken = false

          // ** Update accessToken in localStorage
          setToken(r.data.accessToken)
          setRefreshToken(r.data.refreshToken)

          onAccessTokenFetched(r.data.accessToken)
        })
      }
      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          // ** Make sure to assign accessToken according to your response.
          // ** Check: https://pixinvent.ticksy.com/ticket/2413870
          // ** Change Authorization header
          originalRequest.headers.Authorization = `${jwtConfig.tokenType} ${accessToken}`
          resolve(axios(originalRequest))
        })
      })
      return retryOriginalRequest
    }
    return response
    // return Promise.reject(error)
  }
)

function JsonParseData(data) {
  if (data) {
    data = JSON.parse(data)
  }
  return data
}
function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

function getToken() {
  if (isAdminRoute) {
    return JsonParseData(localStorage.getItem(jwtConfig.adminStorageTokenKeyName))
  } else {
    return JsonParseData(localStorage.getItem(jwtConfig.storageTokenKeyName))
  }
}

function getRefreshToken() {
  if (isAdminRoute) {
    return JsonParseData(localStorage.getItem(jwtConfig.adminStorageRefreshTokenKeyName))
  } else {
    return JsonParseData(localStorage.getItem(jwtConfig.storageRefreshTokenKeyName))
  }
}

function setToken(value) {
  if (isAdminRoute) {
    localStorage.setItem(jwtConfig.adminStorageTokenKeyName, value)
  } else {
    localStorage.setItem(jwtConfig.storageTokenKeyName, value)
  }
}

function setRefreshToken(value) {
  if (isAdminRoute) {
    localStorage.setItem(jwtConfig.adminStorageRefreshTokenKeyName, value)
  } else {
    localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, value)
  }
}

function refreshToken() {
  return axios.post(jwtConfig.refreshEndpoint, {
    refreshToken: getRefreshToken()
  })
}

export default axiosCall