import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { auth } from '../../src/APIRoutes'
import axiosRequest from '../../src/APIRoutes/axiosCall'
import { displayErrorToast, displaySuccessToast } from '../utility/helper'
import { isStringEmpty, isValidEmail } from '../utility/Utils'
import { setAlertPopup } from './common'
const config = useJwt.jwtConfig
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  return item ? JSON.parse(item) : {}
}
const initialAdminData = () => {
  const item = window.localStorage.getItem('adminData')
  return item ? JSON.parse(item) : {}
}
console.log("===========", config)

export const getAuthClinics = createAsyncThunk('auth/getAuthClinics', async () => {
  const response = await axiosRequest.get(auth.AUTH_GET_ALL_CLINICS)
  return { data: response?.data?.data ?? [] }
})
export const setAuthClinics = createAsyncThunk('auth/setAuthClinics', async (data) => {
  return { data }
})

export const handleLogin = createAsyncThunk('auth/handleLogin', async ({ email, password, displayToast, dispatch }) => {
  const formData = new FormData()
  if (isValidEmail(email)) {
    formData.append('email', email)
  } else {
    formData.append('username', email)
  }
  formData.append('password', password)
  const response = await axiosRequest.post(auth.FRONT_LOGIN, formData)
  if (response?.data?.status && dispatch) { dispatch(setAlertPopup(true)) }
  return {
    data: response?.data?.data ?? {},
    message: response?.data?.message,
    status: response?.data?.status ?? false,
    detail: response?.data?.detail ?? '',
    displayToast
  }
})

export const handleAdminLogin = createAsyncThunk('auth/handleAdminLogin', async ({ email, password }) => {
  const formData = new FormData()
  formData.append('email', email)
  formData.append('password', password)
  const response = await axiosRequest.post(auth.ADMIN_LOGIN, formData)
  return {
    data: response?.data?.data ?? {},
    message: response?.data?.message,
    status: response?.data?.status ?? false,
    detail: response?.data?.detail ?? ''
  }
})

export const handleLogout = createAsyncThunk('auth/handleLogout', async () => {
  console.log("user logged out")
  return {}
})

export const handleAdminLogout = createAsyncThunk('auth/handleAdminLogout', async () => {
  console.log("admin logged out")
  return {}
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (email) => {
  const response = await axiosRequest.post(auth.FORGOT_PASSWORD, email)
  return { status: response?.data?.status ?? false, message: response?.data?.msg ?? '' }
})

export const changeIsEmailSent = createAsyncThunk('auth/changeIsEmailSent', async (value) => {
  return { status: value }
})

export const checkEmailAvailability = createAsyncThunk('auth/checkEmailAvailability', async (email) => {
  const response = await axiosRequest.post(auth.CHECK_EMAIL_AVAILABILITY, { email })
  console.log('forgot password response', response?.data)
  return { status: response?.data?.status ?? false, message: response?.data?.msg ?? '' }
})

export const getCurrentCountory = createAsyncThunk('auth/getCurrentCountory', async () => {
  const response = await axiosRequest.get('http://api.hostip.info')
  console.log('--------getCurrentCountory------response-----', response)
  return { data: response?.data }
})
export const handleUserRegister = createAsyncThunk('auth/handleUserRegister', async ({ data, type }) => {
  console.log('----handleUserRegister---', type, data, auth.CLINIC_REGISTER)
  let url = auth.USER_REGISTER
  if (type === 'clinic') {
    url = auth.CLINIC_REGISTER
  }
  const response = await axiosRequest.post(url, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message ?? response?.data?.msg ?? '' }
})

export const resendConfirmationEmail = createAsyncThunk('auth/resendConfirmationEmail', async (email) => {
  const response = await axiosRequest.post(auth.RESEND_CONFIRMATION_AGAIN, { email })
  return { status: response?.data?.status ?? false, message: response?.data?.message ?? 'Something went wrong' }
})

export const gmailLogin = createAsyncThunk('auth/gmailLogin', async ({ email }) => {
  console.log('======== gmail login', { email })
  const response = await axiosRequest.post(auth.GMAIL_LOGIN, { email })
  console.log('------SocialLogins--------', response?.data)
  return {
    data: response?.data?.data,
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? ''
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    authClinics: [],
    loading: false,
    isLoggedIn: false,
    userData: initialUser(),
    adminData: initialAdminData(),
    forgotPasswordLoading: false,
    isEmailSent: false,
    isEmailAvailable: false,
    registerLoading: false
  },
  extraReducers: builder => {
    builder
      .addCase(getAuthClinics.fulfilled, (state, { payload }) => {
        state.authClinics = payload.data
      })
      .addCase(setAuthClinics.fulfilled, (state, { payload }) => {
        state.authClinics = payload.data
      })
      .addCase(handleLogin.fulfilled, (state, { payload }) => {
        state.loading = false
        if (payload?.status && payload?.data?.role) {
          const userData = payload?.data
          if (isStringEmpty(userData?.avatar)) {
            userData.avatar = require('../assets/images/avatars/avatar-blank.png').default
          }

          state.userData = userData
          state.isLoggedIn = true
          state[config.storageTokenKeyName] = userData.accessToken
          state[config.storageRefreshTokenKeyName] = userData.refreshToken
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem(config.storageTokenKeyName, JSON.stringify(userData.accessToken))
          localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(userData.refreshToken))
          if (payload.displayToast) {
            displaySuccessToast(userData.fullName || userData.username || `${userData?.fullName}`, `You have successfully logged in as ${(userData?.roleid === '5' ? 'Client User' : userData?.roleid === '4' ? 'Business User' : userData?.roleid === '3' ? 'Personal Development User' : userData?.roleid === '6' ? 'Employee' : 'Admin')}`)
          }
        } else {
          displayErrorToast(payload?.message ?? 'Invalid Email or Passord')
        }
      })
      .addCase(handleLogin.pending, (state) => {
        state.loading = true
        console.log('LOGIN LOADING')
        console.log('PAYLOAD IN LOGIN PENDING')
      })
      .addCase(handleLogin.rejected, (state) => {
        state.loading = false
        console.log('LOGIN REJECTED')
        console.log('PAYLOAD IN LOGIN REJECTED')
        displayErrorToast('Error', `Invalid Username OR Email`)
      })
      .addCase(handleLogout.fulfilled, (state) => {
        console.log('LOGOUT SUCCESS')
        state.userData = {}
        state[config.storageTokenKeyName] = null
        state[config.storageRefreshTokenKeyName] = null
        // ** Remove user, accessToken & refreshToken from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem(config.storageTokenKeyName)
        localStorage.removeItem(config.storageRefreshTokenKeyName)
      })

      // admin login functions
      .addCase(handleAdminLogin.fulfilled, (state, { payload }) => {
        state.loading = false
        if (payload?.status && payload?.data?.role) {
          const adminData = payload?.data
          if (isStringEmpty(adminData?.avatar)) {
            adminData.avatar = require('../assets/images/avatars/avatar-blank.png').default
          }
          state.adminData = adminData
          state.isLoggedIn = true
          state[config.adminStorageTokenKeyName] = adminData.accessToken
          state[config.adminStorageRefreshTokenKeyName] = adminData.refreshToken
          localStorage.setItem('adminData', JSON.stringify(adminData))
          localStorage.setItem(config.adminStorageTokenKeyName, JSON.stringify(adminData.accessToken))
          localStorage.setItem(config.adminStorageRefreshTokenKeyName, JSON.stringify(adminData.refreshToken))
          displaySuccessToast(adminData.fullName || adminData.username || `${adminData?.fullName}`, `You have successfully logged in as ${adminData?.role}`)
        } else {
          displayErrorToast(payload?.message ?? 'Invalid Email or Passord')
        }
      })
      .addCase(handleAdminLogin.pending, (state) => {
        state.loading = true
        console.log('LOGIN LOADING')
        console.log('PAYLOAD IN LOGIN PENDING')
      })
      .addCase(handleAdminLogin.rejected, (state) => {
        state.loading = false
        console.log('LOGIN REJECTED')
        console.log('PAYLOAD IN LOGIN REJECTED')
        displayErrorToast('Error', `Invalid Username OR Email`)
      })
      .addCase(handleAdminLogout.fulfilled, (state) => {
        console.log('LOGOUT SUCCESS')
        state.adminData = {}
        state[config.adminStorageTokenKeyName] = null
        state[config.adminStorageRefreshTokenKeyName] = null
        // ** Remove user, accessToken & refreshToken from localStorage
        localStorage.removeItem('adminData')
        localStorage.removeItem(config.adminStorageTokenKeyName)
        localStorage.removeItem(config.adminStorageRefreshTokenKeyName)
      })

      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.forgotPasswordLoading = false
        if (payload?.status) {
          state.isEmailSent = payload?.status ?? false
          displaySuccessToast('Success', payload?.message)
        } else {
          displayErrorToast('Error', payload?.message)
        }
        console.log('EMAIL SENT', payload?.status)
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true
        state.isEmailSent = false
        console.log('FORGOT PASSWORD LOADING')
      })
      .addCase(forgotPassword.rejected, (state, { payload }) => {
        state.forgotPasswordLoading = false
        state.isEmailSent = false
        console.log('FORGOT PASSWORD REJECTED', payload?.status)
        displayErrorToast('Error', payload?.message)
      })

      .addCase(changeIsEmailSent.fulfilled, (state) => {
        state.isEmailSent = false
      })

      .addCase(checkEmailAvailability.pending, (state) => {
        state.isEmailAvailable = false
      })

      .addCase(getCurrentCountory.fulfilled, (state, {payload}) => {
       console.log('--------getCurrentCountory-----', payload?.data)
      })
      .addCase(checkEmailAvailability.fulfilled, (state, { payload }) => {
        state.isEmailAvailable = payload?.status
      })
      // USER REGISTER
      .addCase(handleUserRegister.pending, (state) => {
        state.registerLoading = true
        console.log('REGISTER LOADING')
      })
      .addCase(handleUserRegister.rejected, (state) => {
        state.registerLoading = false
        console.log('REGISTER ERROR')
      })
      .addCase(handleUserRegister.fulfilled, (state, { payload }) => {
        state.registerLoading = false
        if (payload?.status) {
          console.log('USER REGISTERED')
          displaySuccessToast('Success', payload?.message)
        } else {
          displayErrorToast('Error', payload?.message)
        }
      })
      .addCase(resendConfirmationEmail.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          console.log('VERIFICATION EMAIL SENT')
          displaySuccessToast('Success', payload?.message)
        } else {
          displayErrorToast('Error', payload?.message)
        }
      })
      // .addCase(gmailLogin.fulfilled, (state, { payload }) => {
      //   if (payload?.status) {
      //     const userData = payload?.data
      //     console.log("==userData==", userData)
      //     if (isStringEmpty(userData?.avatar)) {
      //       userData.avatar = require('../assets/images/avatars/avatar-blank.png').default
      //     }
      //     state.userData = userData
      //     state.isLoggedIn = true
      //     state.loading = false
      //     state[config.storageTokenKeyName] = userData[config.storageTokenKeyName]
      //     state[config.storageRefreshTokenKeyName] = userData[config.storageRefreshTokenKeyName]
      //     localStorage.setItem('userData', JSON.stringify(userData))
      //     localStorage.setItem(config.storageTokenKeyName, JSON.stringify(userData.accessToken))
      //     localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(userData.refreshToken))
      //     displaySuccessToast(userData.fullName || userData.username || `${userData?.fullName}`, `You have successfully logged in as ${userData?.role}`)
      //   }
      // })
      // .addCase(gmailLogin.pending, (state) => {
      //   state.loading = true
      //   console.log('LOGIN LOADING')
      //   console.log('PAYLOAD IN LOGIN PENDING')
      // })
      // .addCase(gmailLogin.rejected, (state) => {
      //   state.loading = false
      //   console.log('LOGIN REJECTED')
      //   console.log('PAYLOAD IN LOGIN REJECTED')
      //   displayErrorToast('Error', `Invalid Username OR Email`)
      // })
      .addCase(gmailLogin.fulfilled, (state, { payload }) => {
        state.loading = false
        if (payload?.status) {
          const userData = payload?.data
          if (isStringEmpty(userData?.avatar)) {
            userData.avatar = require('../assets/images/avatars/avatar-blank.png').default
          }
          state.userData = userData
          state.isLoggedIn = true
          state[config.storageTokenKeyName] = userData[config.storageTokenKeyName]
          state[config.storageRefreshTokenKeyName] = userData[config.storageRefreshTokenKeyName]
          localStorage.setItem('userData', JSON.stringify(userData))
          localStorage.setItem(config.storageTokenKeyName, JSON.stringify(userData.accessToken))
          localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(userData.refreshToken))
          displaySuccessToast(userData.fullName || userData.username || `${userData?.fullName}`, `You have successfully 1122 logged in as ${userData?.role}`)
        } else {
          displayErrorToast(payload?.message ?? 'Invalid Email or Passord')
        }
      })
      .addCase(gmailLogin.pending, (state) => {
        state.loading = true
        console.log('LOGIN LOADING')
        console.log('PAYLOAD IN LOGIN PENDING')
      })
      .addCase(gmailLogin.rejected, (state) => {
        state.loading = false
        console.log('LOGIN REJECTED')
        console.log('PAYLOAD IN LOGIN REJECTED')
        displayErrorToast('Error', `Invalid Username OR Email`)
      })
  }
})

export default authSlice.reducer
