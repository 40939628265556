import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { users } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const getUserDetailById = createAsyncThunk('user/getUserDetailById', async (userid) => {
  const response = await axiosRequest.post(users.USER_DETAIL, { userid })
  return { data: response?.data?.data }
})

export const userSlice = createSlice({
  name: 'commonUser',
  initialState: {
    loading: false,
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      // GET CHAT CONTACTS
      .addCase(getUserDetailById.pending, (state) => {
        state.loading = true
      })
      .addCase(getUserDetailById.fulfilled, (state, { payload }) => {
        state.loading = false
        state.data = payload?.data
      })
      .addCase(getUserDetailById.rejected, (state) => {
        state.loading = false
      })
  }
})

export default userSlice.reducer
