// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { tests } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getTests = createAsyncThunk('tests/getTests', async () => {
  const response = await axiosRequest.get(tests.FRONT_GET_TESTS)
  return { data: response?.data?.data ?? [] }
})
export const getTestsData = createAsyncThunk('tests/getTestsData', async () => {
  const response = await axiosRequest.get(tests.FRONT_GET_TESTS_DETAILS)
  return {
    data: response?.data?.data ?? []
  }
})

export const getAssignedTest = createAsyncThunk('tests/getAssignedTest', async () => {
  const response = await axiosRequest.get(tests.GET_ASSIGNED_TEST)
  return {
    data: response?.data?.data ?? [], completeTest: response?.data?.total_completed ?? [], incompleteTest: response?.data?.total_incompleted ?? []
  }
})

export const getTestResult = createAsyncThunk('tests/getTestResult', async (data) => {
  const response = await axiosRequest.post(tests.FRONT_GET_TESTS_RESULT, data)
  return {
    testsResult: response?.data,
    testscondition: response?.data?.data?.condition ?? ''
  }
})

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (id, { dispatch, getState }) => {
  const response = await axios.post('/apps/ecommerce/cart', { productId: id })
  await dispatch(getSections(getState().ecommerce.params))
  return response.data
})


export const completeTest = createAsyncThunk('tests/completeTest', async (data) => {
  const response = await axiosRequest.post(tests.COMPLETE_TESTS, data)
  return { data: response.data?.data ?? [] }
})

export const deleteAssignTest = createAsyncThunk('tests/deleteAssignTest', async (data) => {
  const response = await axiosRequest.post(tests.DELETE_ASSIGN_TEST, data)
  return { data: response.data?.data ?? [] }
})

export const getWishlistItems = createAsyncThunk('appEcommerce/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist')
  return response.data
})

export const deleteWishlistItem = createAsyncThunk('appEcommerce/deleteWishlistItem', async (id, { dispatch }) => {
  const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`)
  dispatch(getWishlistItems())
  return response.data
})

export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async () => {
  const response = await axios.get('/apps/ecommerce/cart')
  return response.data
})

export const addToWishlist = createAsyncThunk('appEcommerce/addToWishlist', async id => {
  await axios.post('/apps/ecommerce/wishlist', { productId: id })
  return id
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (id, { dispatch }) => {
  await axios.delete(`/apps/ecommerce/cart/${id}`)
  dispatch(getCartItems())
  return id
})

export const appTestsSlice = createSlice({
  name: 'tests',
  initialState: {
    testsData: [],
    testLoading: false,
    testsDataDetail: [],
    getAssignedData: [],
    completeTestData: [],
    incompleteTestData: [],
    completeTests: [],
    deletetestData: [],
    testDetailLoading: false,
    testsResult: [],
    testscondition: '',
    testResultLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTests.fulfilled, (state, { payload }) => {
        state.testsData = payload.data
        state.testLoading = false
      })
      .addCase(getTests.pending, (state) => {
        state.testLoading = true
      })
      .addCase(getTests.rejected, (state) => {
        state.testLoading = false
      })
      .addCase(getTestsData.fulfilled, (state, { payload }) => {
        state.testsDataDetail = payload.data
        state.testDetailLoading = false
      })
      .addCase(getTestsData.pending, (state) => {
        state.testDetailLoading = true
      })
      .addCase(getTestsData.rejected, (state) => {
        state.testDetailLoading = false
      })

      //
      .addCase(getAssignedTest.fulfilled, (state, { payload }) => {
        state.getAssignedData = payload.data
        state.testDetailLoading = false
        state.completeTestData = payload?.completeTest
        state.incompleteTestData = payload?.incompleteTest
      })
      .addCase(getAssignedTest.pending, (state) => {
        state.testDetailLoading = true
      })
      .addCase(getAssignedTest.rejected, (state) => {
        state.testDetailLoading = false
      })
      //
      .addCase(completeTest.fulfilled, (state, { payload }) => {
        state.completeTestLoading = false
        state.completeTests = payload.data
      })
      .addCase(completeTest.pending, (state) => {
        state.completeTestLoading = true
      })
      .addCase(completeTest.rejected, (state) => {
        state.completeTestLoading = false
      })
      //
      .addCase(deleteAssignTest.fulfilled, (state, { payload }) => {
        state.completeTestLoading = false
        state.deletetestData = payload.data
      })
      .addCase(deleteAssignTest.pending, (state) => {
        state.completeTestLoading = true
      })
      .addCase(deleteAssignTest.rejected, (state) => {
        state.completeTestLoading = false
      })
      .addCase(getTestResult.fulfilled, (state, { payload }) => {
        state.testsResult = payload.testsResult ?? []
        state.testscondition = payload.testscondition ?? ''
        state.testResultLoading = false
      })
      .addCase(getTestResult.pending, (state) => {
        state.testResultLoading = true
      })
      .addCase(getTestResult.rejected, (state) => {
        state.testResultLoading = false
      })
  }
})

export default appTestsSlice.reducer
