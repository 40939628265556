export const SERVER_URL = "https://legacy.healmb.com"
const HOST_SUFFIX = '/'
const API = 'api/'
const VERSION = "v3/"
export const API_HOST = SERVER_URL + HOST_SUFFIX + API + VERSION
export const PUSHER_API_KEY = '650edc022f5e87f46270'
export const PUSHER_CLUSTER = 'mt1'
export const PAYPAL_CREDENTIALS = {
  client_id: "AcFJNQfXgXI9EoJy-N1deKT6-k0IhY4f63ZdatHRhDtGP3XJ8rFR6yLkBSw8uxffjpVEyhk8pFySztzO",
  data_client_token: "EOWppSxOv_tiGH0aE1CsLOzwD7fOVmzOydroMuvpeohOBi0SBrRI1KSDP-9iy2kXs5GvfnALqKLBaVTC"
}
