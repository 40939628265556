// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { features } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
import { isArrayEmpty } from '../../../../utility/Utils'
export const getAdminFeatures = createAsyncThunk('adminFeatures/getAdminFeatures', async () => {
  const response = await axiosRequest.get(features.ADMIN_FEATURES)
  const responseData = response?.data?.data
  const finalData = []
  if (!isArrayEmpty(responseData?.user_tabs)) {
    finalData.push({
      title: 'Front User Dashboard',
      tabtype: 'Users',
      features: responseData?.user_tabs
    })
  }
  if (!isArrayEmpty(responseData?.patient_tabs)) {
    finalData.push({
      title: 'Patient Dashboard',
      tabtype: 'Patients',
      features: responseData?.patient_tabs
    })
  }
  if (!isArrayEmpty(responseData?.clinics_tabs)) {
    finalData.push({
      title: 'Clinic Dashboard',
      tabtype: 'Clinics',
      features: responseData?.clinics_tabs
    })
  }
  return { data: finalData }
})

export const setAdminFeatures = createAsyncThunk('adminFeatures/setAdminFeatures', async (data) => {
  return { data }
})

export const editAdminFeatures = createAsyncThunk('adminFeatures/editAdminFeatures', async (data, { dispatch }) => {
  const response = await axiosRequest.post(features.ADMIN_EDIT_FEATURE, data)
  dispatch(getAdminFeatures())
  return { status: response.data?.status }
})

export const adminFeaturesSlice = createSlice({
  name: 'adminFeatures',
  initialState: {
    data: [],
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminFeatures.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAdminFeatures.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminFeatures.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminFeatures.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(editAdminFeatures.fulfilled, (state, { payload }) => {
        displaySuccessToast('Feature has been Updated Successfully')
        console.log(`UPDATED DATA STATUS ${payload?.status}`)
      })
  }
})

export default adminFeaturesSlice.reducer
