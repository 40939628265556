// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { contents } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminContents = createAsyncThunk('adminContents/getAdminContents', async () => {
  const response = await axiosRequest.get(contents.ADMIN_CONTENTS)
  return { data: response?.data?.data }
})

export const setAdminContents = createAsyncThunk('adminContents/setAdminContents', async (data) => {
  return { data }
})

export const addContent = createAsyncThunk('adminContents/addContent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(contents.ADMIN_ADD_CONTENT, data)
  dispatch(getAdminContents())
  return { status: response?.data?.status }
})

export const updateContent = createAsyncThunk('adminContents/updateContent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(contents.ADMIN_EDIT_CONTENT, data)
  dispatch(getAdminContents())
  return { status: response?.data?.status }
})

export const deleteContent = createAsyncThunk('adminContents/deleteContent', async (pageid) => {
  const response = await axiosRequest.post(contents.ADMIN_DELETE_CONTENT, { pageid })
  return { id, status: response.data?.status }
})

export const adminContentSlice = createSlice({
  name: 'adminContents',
  initialState: {
    data: [],
    loading: true,
    categories: [],
    parentCategories: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminContents.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminContents.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminContents.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminContents.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteContent.fulfilled, (state, { payload }) => {
        console.log(`DELETED ID ${payload?.id}`)
      })
      .addCase(addContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Content has been Added Successfully')
        console.log(`ADDED CONTENT ${payload?.status}`)
      })
      .addCase(updateContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Content has been Updated Successfully')
        console.log(`Updated CONTENT ${payload?.status}`)
      })
  }
})

export default adminContentSlice.reducer
