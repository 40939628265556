// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getFrontPrivacyPolicy = createAsyncThunk('privacy_policy/getFrontPrivacyPolicy', async () => {
  const response = await axiosRequest.get(meeting.PRIVACY_POLICY)
  //console.log("====response======", response)
  return {
    privacypolicy: response?.data?.data ?? []
  }
})


export const frontPrivacyPolicySlice = createSlice({
  name: 'privacy_policy',
  initialState: {
    privacypolicy: [],
    privacypolicyLoading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontPrivacyPolicy.fulfilled, (state, { payload }) => {
        state.privacypolicy = payload?.privacypolicy ?? []
      })
      .addCase(getFrontPrivacyPolicy.pending, (state) => {
        console.log("LOADING...")
        state.privacypolicyLoading = true
      })
      .addCase(getFrontPrivacyPolicy.rejected, (state) => {
        console.log("REJECTED...")
        state.privacypolicyLoading = false
      })
  }
})

export default frontPrivacyPolicySlice.reducer
