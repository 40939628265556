// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { events } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminEvents = createAsyncThunk('adminEvents/getAdminEvents', async () => {
  const response = await axiosRequest.get(events.ADMIN_EVENTS)
  return { data: response?.data?.data }
})

export const setAdminEvents = createAsyncThunk('adminEvents/setAdminEvents', async (data) => {
  return { data }
})

export const createAdminEvent = createAsyncThunk('adminEvents/createAdminEvent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(events.ADMIN_ADD_EVENT, data)
  dispatch(getAdminEvents())
  return { status: response?.data?.status }
})

export const updateAdminEvent = createAsyncThunk('adminEvents/updateAdminEvent', async (data) => {
  const response = await axiosRequest.post(events.ADMIN_EDIT_EVENT, data)
  return { status: response?.data?.status }
})

export const deleteAdminEvent = createAsyncThunk('adminEvents/deleteAdminEvent', async (id) => {
  const response = await axiosRequest.post(events.ADMIN_DELETE_EVENT, { id })
  return { id, status: response.data?.status }
})
// EVENT PARTICIPANTS
export const getEventParticipants = createAsyncThunk('adminEvents/getEventParticipants', async () => {
  const response = await axiosRequest.get(events.ADMIN_EVENT_PARTICIPANTS)
  return { data: response?.data?.data ?? [] }
})

export const setEventParticipants = createAsyncThunk('adminEvents/setEventParticipants', async (data) => {
  return { data }
})

export const addEventParticipant = createAsyncThunk('adminEvents/addEventParticipant', async (data, { dispatch }) => {
  const response = await axiosRequest.post(events.ADMIN_EVENT_ADD_PARTICIPANT, data)
  dispatch(getEventParticipants(data?.test_id))
  return { data: response?.data?.data ?? {}, status: response?.data?.status }
})

export const updateEventParticipant = createAsyncThunk('adminEvents/updateEventParticipant', async (data) => {
  const response = await axiosRequest.post(events.ADMIN_EVENT_EDIT_PARTICIPANT, data)
  return { data, status: response.data?.status }
})

export const deleteEventParticipant = createAsyncThunk('adminEvents/deleteEventParticipant', async (id) => {
  const response = await axiosRequest.post(events.ADMIN_EVENT_DELETE_PARTICIPANT, { id })
  return { id, status: response?.data?.status }
})
export const adminEventsSlice = createSlice({
  name: 'adminEvents',
  initialState: {
    data: [],
    loading: true,
    eventParticipants: [],
    participantsLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminEvents.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAdminEvents.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminEvents.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminEvents.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(createAdminEvent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Event has been Created Successfully')
        console.log(`CREATED Event ${payload?.status}`)
        state.status = payload?.status ?? []
      })
      .addCase(updateAdminEvent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Event has been Updeted Successfully')
        console.log(`UPDATED DATA ${payload?.status}`)
      })
      .addCase(deleteAdminEvent.fulfilled, (state, { payload }) => {
        console.log(`DELETED DATA ID ${payload?.id}`)
      })

      // EVENT PARTICIPANTS
      .addCase(getEventParticipants.fulfilled, (state, { payload }) => {
        state.eventParticipants = payload?.data ?? []
        state.participantsLoading = false
      })
      .addCase(getEventParticipants.pending, (state) => {
        console.log("LOADING...")
        state.participantsLoading = true
      })
      .addCase(getEventParticipants.rejected, (state) => {
        console.log("REJECTED...")
        state.participantsLoading = false
      })

      .addCase(setEventParticipants.fulfilled, (state, { payload }) => {
        state.eventParticipants = payload?.data ?? []
      })
      .addCase(addEventParticipant.fulfilled, (state, { payload }) => {
        displaySuccessToast('Event Participant has been Added Successfully')
        console.log(`ADDED DATA - PARTICIPANT ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteEventParticipant.fulfilled, (state, { payload }) => {
        console.log(`DELETED  DATA - PARTICIPANT ID ${payload?.id}`)
      })
      .addCase(updateEventParticipant.fulfilled, (state, { payload }) => {
        displaySuccessToast('Event Participant has been Updated Successfully')
        console.log(`UPDATED DATA - PARTICIPANT ${JSON.stringify(payload?.data)}`)
      })
  }
})

export default adminEventsSlice.reducer
