// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { assignments } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getAssignments = createAsyncThunk('clinicAssignments/getAssignments', async () => {
  const response = await axiosRequest.get(assignments.CLINIC_ASSIGNMENTS)
  return { data: response?.data?.data }
})
export const getAssignmentStats = createAsyncThunk('clinicAssignments/getAssignmentStats', async () => {
  const response = await axiosRequest.get(assignments.CLINIC_ASSIGNMENTS_STATS)
  return { data: response?.data?.stats }
})
export const setAssignments = createAsyncThunk('clinicAssignments/setAssignments', async (data) => {
  return { data }
})

export const getAssignmentChildTask = createAsyncThunk('clinicAssignments/getAssignmentChildTask', async (data) => {
  const response = await axiosRequest.post(assignments.CLINIC_ASSIGNMENT_CHILD_TASK, data)
  return { data: response?.data?.data }
})

export const setAssignmentChildTask = createAsyncThunk('clinicAssignments/setAssignmentChildTask', async (data) => {
  return { data }
})

export const assignmentTaskDone = createAsyncThunk('clinicAssignments/assignmentTaskDone', async (id) => {
  const response = await axiosRequest.post(assignments.CLINIC_ASSIGNMENT_TASK_DONE, { id })
  return { id, status: response.data?.status }
})

export const deleteAssignment = createAsyncThunk('clinicAssignments/deleteAssignment', async (id) => {
  const response = await axiosRequest.post(assignments.CLINIC_DELETE_ASSIGNMENT, {id})
  return { id, status: response.data?.status }
})
export const deleteSelectedAssignment = createAsyncThunk('clinicAssignments/deleteSelectedAssignment', async (id) => {
  const response = await axiosRequest.post(assignments.CLINIC_DELETE_ASSIGNMENT, id)
  return { id, status: response.data?.status }
})

export const deleteChildAssignment = createAsyncThunk('clinicAssignments/deleteChildAssignment', async (id) => {
  const response = await axiosRequest.post(assignments.CLINIC_DELETE_CHILD_ASSIGNMENT, { id })
  return { id, status: response.data?.status }
})

export const assignmentsSlice = createSlice({
  name: 'clinicAssignments',
  initialState: {
    data: [],
    dataStat: {},
    loading: true,
    assignmentChildTasks: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAssignments.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAssignments.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAssignments.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(getAssignmentStats.fulfilled, (state, { payload }) => {
        state.dataStat = payload?.data ?? []
      })
      .addCase(setAssignments.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAssignmentChildTask.fulfilled, (state, { payload }) => {
        console.log('ASSIGNMENT CHILD TASKS', payload?.data)
        state.assignmentChildTasks = payload?.data ?? []
      })
      .addCase(setAssignmentChildTask.fulfilled, (state, { payload }) => {
        state.assignmentChildTasks = payload?.data ?? []
      })
      .addCase(assignmentTaskDone.fulfilled, (state, { payload }) => {
        console.log(`TASK DONE ${payload.id}`)
      })
      .addCase(deleteAssignment.fulfilled, (state, { payload }) => {
        console.log(`ASSIGNMENT DELETED ${payload?.id}`)
      })
      .addCase(deleteSelectedAssignment.fulfilled, (state, { payload }) => {
        console.log(`ASSIGNMENT DELETED ${payload?.id}`)
      })
      .addCase(deleteChildAssignment.fulfilled, (state, { payload }) => {
        console.log(`CHILD ASSIGNMENT DELETED ${payload?.id}`)
      })
  }
})

export default assignmentsSlice.reducer
