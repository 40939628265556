// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { reviews } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getAdminReviews = createAsyncThunk('adminReviews/getAdminReviews', async () => {
  const response = await axiosRequest.get(reviews.ADMIN_REVIEWS)
  return { data: response?.data?.data }
})

export const setAdminReviews = createAsyncThunk('adminReviews/setAdminReviews', async (data) => {
  return { data }
})

export const deleteAdminReview = createAsyncThunk('adminReviews/deleteAdminReview', async (id) => {
  const response = await axiosRequest.post(reviews.ADMIN_DELETE_REVIEW, { id })
  return { id, status: response.data?.status }
})

export const getAdminTopBannerReviews = createAsyncThunk('adminReviews/getAdminTopBannerReviews', async () => {
  const response = await axiosRequest.get(reviews.ADMIN_REVIEWS_TOP_BANNERS)
  return {
    meditation: response?.data?.meditation ?? {},
    video: response?.data?.video ?? {},
    classes: response?.data?.classes ?? {}
  }
})

export const adminReviewsSlice = createSlice({
  name: 'adminReviews',
  initialState: {
    data: [],
    loading: true,
    meditation: {},
    video: {},
    classes: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminReviews.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAdminReviews.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminReviews.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminReviews.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminTopBannerReviews.fulfilled, (state, { payload }) => {
        state.meditation = payload?.meditation ?? {}
        state.video = payload?.video ?? {}
        state.classes = payload?.classes ?? {}
      })
      .addCase(deleteAdminReview.fulfilled, (state, { payload }) => {
        console.log(`DELETED DATA ID ${payload?.id}`)
      })
  }
})

export default adminReviewsSlice.reducer
