import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { common, dashboard } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const setSubmitLoading = createAsyncThunk('common/setSubmitLoading', async (status) => {
  return { status }
})
export const setSubmitLoadingDelete = createAsyncThunk('common/setSubmitLoadingDelete', async (status) => {
  return { status }
})

export const updateUserLogs = createAsyncThunk('favorites/updateUserLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(common.UPDATE_USER_LOGS, params)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})


export const setAlertPopup = createAsyncThunk('dashboard/setAlertPopup', async (status) => {
  return { status }
})
export const getAlertPopupSummary = createAsyncThunk('dashboard/getAlertPopupSummary', async () => {
  const response = await axiosRequest.get(dashboard.FRONT_ALERT_POPUP)
  console.log('-----------getAlertPopupSummary=---response----', response)
  return {
    status: response.data?.status ?? false,
    message: response.data?.message ?? '',
    data: response.data?.data ?? {}
  }
})

export const getUserAvergeStats = createAsyncThunk('dashboard/getUserAvergeStats', async () => {
  const response = await axiosRequest.get(dashboard.USER_AVERAGE_STATS)
  return { data: response?.data?.data ?? {} }
})

export const getSessionByDeviceStats = createAsyncThunk('dashboard/getSessionByDeviceStats', async () => {
  const response = await axiosRequest.get(dashboard.SESSION_BY_DEVICE)
  return { data: response?.data?.data ?? {} }
})


export const userSlice = createSlice({
  name: 'common',
  initialState: {
    isSubmitLoading: false,
    isSubmitLoadingDelete: false,
    isAlertPopupVisible: false,
    alertPopupData: {},
    userAverageLoading: false,
    userAverage: {},
    sessionByDeviceLoading: false,
    sessionByDevice: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setSubmitLoading.fulfilled, (state, { payload }) => {
        state.isSubmitLoading = payload?.status ?? false
      })
      .addCase(setSubmitLoadingDelete.fulfilled, (state, { payload }) => {
        state.isSubmitLoadingDelete = payload?.status ?? false
      })
      .addCase(setAlertPopup.fulfilled, (state, { payload }) => {
        state.isAlertPopupVisible = payload?.status
      })
      .addCase(getAlertPopupSummary.fulfilled, (state, { payload }) => {
        state.alertPopupData = payload?.data
      })

      .addCase(getUserAvergeStats.fulfilled, (state, { payload }) => {
        state.userAverage = payload?.data ?? []
        state.userAverageLoading = false
      })
      .addCase(getUserAvergeStats.pending, (state) => {
        state.userAverageLoading = true
      })
      .addCase(getUserAvergeStats.rejected, (state) => {
        state.userAverageLoading = false
      })

      .addCase(getSessionByDeviceStats.fulfilled, (state, { payload }) => {
        state.sessionByDevice = payload?.data ?? []
        state.sessionByDeviceLoading = false
      })
      .addCase(getSessionByDeviceStats.pending, (state) => {
        state.sessionByDeviceLoading = true
      })
      .addCase(getSessionByDeviceStats.rejected, (state) => {
        state.sessionByDeviceLoading = false
      })
  }
})

export default userSlice.reducer
