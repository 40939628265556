import { API_HOST } from './config'
export default {
  // CLINIC ROUTES
  CLINIC_ASSIGNMENTS: `${API_HOST}clinics/assignments/get_assignments`,
  CLINIC_ASSIGNMENTS_STATS: `${API_HOST}clinics/assignments/get_assignment_stat`,
  CLINIC_ASSIGNMENT_CHILD_TASK: `${API_HOST}clinics/assignments/child_task`,
  CLINIC_ASSIGNMENT_TASK_DONE: `${API_HOST}clinics/assignments/task_done`,
  CLINIC_DELETE_ASSIGNMENT: `${API_HOST}clinics/assignments/delete_assignment`,
  CLINIC_DELETE_CHILD_ASSIGNMENT: `${API_HOST}clinics/assignments/delete_child_assignment`,
  CLINIC_ASSIGN_TASK_TO_USER: `${API_HOST}clinics/assignments/task_assign_to_user_bulk`,

  // FRONT ROUTES
  FRONT_ASSIGNMENTS: `${API_HOST}front/assignments/get_assignments`,
  FRONT_ASSIGNMENT_CHILD_TASK: `${API_HOST}front/assignments/child_task`,
  FRONT_ASSIGNMENT_TASK_DONE: `${API_HOST}front/assignments/task_done`,
  FRONT_ASSIGNMENT_STATS: `${API_HOST}front/assignments/get_assignment_stat`,
  MARK_ALL_COMPLETE_FRONT_ASSIGNMENT: `${API_HOST}front/assignments/mark_all_complete`
}