import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_GROUPS: `${API_HOST}admin/groups/all_groups`,
  ADMIN_ADD_GROUP: `${API_HOST}admin/groups/create_group`,
  ADMIN_EDIT_GROUP: `${API_HOST}admin/groups/edit_group`,
  ADMIN_DELETE_GROUP: `${API_HOST}admin/groups/delete_group`,
  ADMIN_GROUP_POSTS: `${API_HOST}admin/groups/group_posts`,

  ADMIN_GROUP_CREATE_POST: `${API_HOST}admin/groups/create_post`,
  ADMIN_GROUP_EDIT_POST: `${API_HOST}admin/groups/edit_post`,
  ADMIN_GROUP_DELETE_POST: `${API_HOST}admin/groups/delete_post`,
  ADMIN_GROUP_POST_COMMENTS: `${API_HOST}admin/groups/post_comments`,
  ADMIN_GROUP_POST_DELETE_COMMENT: `${API_HOST}admin/groups/delete_comment`,
  ADMIN_USERS:`${API_HOST}admin/users/users`,

  // CLINIC ROUTES
  CLINIC_GROUPS: `${API_HOST}clinics/groups/all_groups`,
  CLINIC_ADD_GROUP: `${API_HOST}clinics/groups/create_group`,
  CLINIC_EDIT_GROUP: `${API_HOST}clinics/groups/edit_group`,
  CLINIC_DELETE_GROUP: `${API_HOST}clinics/groups/delete_group`,
  CLINIC_GROUP_POSTS: `${API_HOST}clinics/groups/group_posts`,

  CLINIC_GROUP_CREATE_POST: `${API_HOST}clinics/groups/create_post`,
  CLINIC_GROUP_EDIT_POST: `${API_HOST}clinics/groups/edit_post`,
  CLINIC_GROUP_DELETE_POST: `${API_HOST}clinics/groups/delete_post`,
  CLINIC_GROUP_POST_COMMENTS: `${API_HOST}clinics/groups/post_comments`,
  CLINIC_GROUP_POST_DELETE_COMMENT: `${API_HOST}clinics/groups/delete_comment`,
  CLINIC_GROUP_INVITE: `${API_HOST}clinics/groups/invite`,
  CLINIC_PATIENTS: `${API_HOST}clinics/patients/get_pateints`,

  // FRONT ROUTES
  UPDATE_LOGS_GROUPS: `${API_HOST}front/dashboard/update_logs`,
  FRONT_GROUPS: `${API_HOST}front/groups/get_groups`,
  FRONT_GROUP_DETAIL: `${API_HOST}front/groups/group_detail`,
  FRONT_GROUP_UNFAVOURITE: `${API_HOST}front/groups/make_unfavourite`,
  FRONT_GROUP_FAVOURITE: `${API_HOST}front/groups/make_favourite`,
  FRONT_GROUP_LEAVE: `${API_HOST}front/groups/leave`,
  FRONT_GROUP_JOIN: `${API_HOST}front/groups/join`,
  FRONT_CREATE_GROUP_POST: `${API_HOST}front/groups/create_post`,
  FRONT_POST_DETAILS: `${API_HOST}front/groups/post_by_id`,
  FRONT_ADD_POST_COMMENTS: `${API_HOST}front/groups/add_comment`,
  FRONT_RECENT_POSTS: `${API_HOST}front/groups/get_recent_posts`,
  FRONT_POSTS_LIKE: `${API_HOST}front/groups/like_post`,
  FRONT_POSTS_UNLIKE: `${API_HOST}front/groups/unlike_post`,
  FRONT_DELETE_COMMENT: `${API_HOST}front/groups/delete_comment`
}