import { API_HOST } from './config'
export default {
  UPDATE_USER_LOGS: `${API_HOST}front/dashboard/update_logs`,
  GET_PATIENT_FEATURES: `${API_HOST}clinics/patients/patient_features`,
  UPDATE_PATIENT_FEATURES: `${API_HOST}clinics/patients/update_patients_features`,
  GET_USER_FEATURES: `${API_HOST}admin/users/user_features`,
  UPDATE_USER_FEATURES: `${API_HOST}admin/users/update_user_features`,
  URGENT_ALERTS: `${API_HOST}front/authentication/urgent_alerts`,
  GET_All_BlOGPOSTS: `${API_HOST}front/blog/get_all_post`
}
