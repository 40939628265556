import { API_HOST } from "./config"
export default {
  // CLINIC SECTIONS

  // CATEGORIES
  CREATE_CLASS_CATEGORY: `${API_HOST}clinics/discovery/create_category`,
  GET_CLASS_CATEGORY: `${API_HOST}clinics/discovery/get_categories_by_clinic_and_type_all`,
  GET_LESSON_MEDITATION: `${API_HOST}clinics/discovery/get_lesson_meditations`,
  GET_CATEGORY_BY_TYPE: `${API_HOST}clinics/discovery/get_categories_by_clinic_and_type`,
  CHANGE_CLASS_CATEGORY: `${API_HOST}clinics/discovery/update_class_category`,

  // CLASSES
  GET_CLASS_BY_TYPE: `${API_HOST}clinics/discovery/get_classes_by_clinic_and_type`,
  CREATE_CLASSES: `${API_HOST}clinics/discovery/create_class`,
  GET_COMPLETE_CLASSES: `${API_HOST}clinics/discovery/get_complete_class`,
  UPDATE_CLASSES: `${API_HOST}clinics/discovery/update_class`,
  GET_ALL_CLASSES: `${API_HOST}clinics/discovery/get_classes_by_clinic_and_type_all`,
  GET_ALL_SHARED_CLASSES: `${API_HOST}clinics/discovery/get_shared_classes`,
  GET_ALL_SHARED_MEDITATION: `${API_HOST}clinics/discovery/get_shared_meditations`,
  UPDATE_ALL_SHARED_CLASSES: `${API_HOST}clinics/discovery/update_shared_classes`,
  ADD_FAVOURIT_CLASSES: `${API_HOST}clinics/discovery/add_fav_class`,
  REMOVE_FAVOURIT_CLASSES: `${API_HOST}clinics/discovery/remove_fav_class`,
  DELETE_CLASSES: `${API_HOST}clinics/discovery/delete_class`,
  ADD_TO_FEATURE: `${API_HOST}clinics/discovery/add_featured_class`,
  DELETE_CATAGORIES: `${API_HOST}clinics/discovery/delete_class_med_category`,
  DELETE_MEDITATION: `${API_HOST}clinics/discovery/delete_meditation`,
  GET_AUTHORS_LIST: `${API_HOST}clinics/discovery/get_authors`,

  // RECOMMENDED DATA
  CREATE_RECOMMENDED_DATA: `${API_HOST}clinics/discovery/add_recommended_classes`,
  CREATE_POPULAR_DATA: `${API_HOST}clinics/discovery/add_popular_classes`,
  GET_CLASS_BY_CATEGORY: `${API_HOST}clinics/discovery/get_classes_by_category`,
  CREATE_RECOMMENDED_MEDITATION: `${API_HOST}clinics/discovery/add_recommended_meditations`,
  CREATE_POPULAR_MEDITATION: `${API_HOST}clinics/discovery/add_popular_meditations`,

  //MEDITATION
  GET_MEDITATION_BY_TYPE: `${API_HOST}clinics/discovery/get_meditation_by_clinic_and_type`,
  GET_Feed_BACK_DATA: `${API_HOST}front/new_discovery/get_meditation_feedback`,
  CREATE_MEDITATION: `${API_HOST}clinics/discovery/create_meditation`,
  GET_COMPLETE_MEDITAIION: `${API_HOST}clinics/discovery/get_complete_meditation`,
  UPDATE_MEDITATION: `${API_HOST}clinics/discovery/update_meditation`,
  UPDATE_CLASS_CATEGORY: `${API_HOST}clinics/discovery/update_category`,
  GET_MORE_MEDITATION_AUDIOS: `${API_HOST}clinics/discovery/get_meditation_audios`,
  CREATE_MORE_MEDITATION_AUDIOS: `${API_HOST}clinics/discovery/add_meditation_audio`,
  UPDATE_MORE_MEDITATION_AUDIOS: `${API_HOST}clinics/discovery/update_meditation_audio`,
  DELETE_MORE_MEDITATION_AUDIOS: `${API_HOST}clinics/discovery/delete_meditation_audio`,
  GET_MEDITATION_BY_CATEGORY: `${API_HOST}clinics/discovery/get_meditation_by_category`,
  CREATE_MEDITATION_TASKS: `${API_HOST}clinics/discovery/add_meditation_tasks`,
  CHANGE_MEDITATION_CATEGORY: `${API_HOST}clinics/discovery/update_med_category`,
  GET_MEDITATION_TASKS: `${API_HOST}clinics/discovery/get_meditation_tasks`,
  ADD_FAVOURIT_MEDITATION: `${API_HOST}clinics/discovery/add_fav_med`,
  REMOVE_FAVOURIT_MEDITATION: `${API_HOST}clinics/discovery/remove_fav_med`,
  SEND_NOTIFICATIONS: `${API_HOST}clinics/discovery/new_meditation_notification`,
  CUSTOM_MEDITATION_REVIEW: `${API_HOST}clinics/AI_Meditations/custom_meditation_review`,


  // LESSONS
  CREATE_LESSONS: `${API_HOST}clinics/discovery/add_lesson`,
  GET_LESSONS_AND_CONTENTS: `${API_HOST}clinics/discovery/get_lessons_and_lesson_contents`,
  DELETE_LESSON: `${API_HOST}clinics/discovery/delete_lesson`,
  DELETE_CONTENT: `${API_HOST}clinics/discovery/delete_lesson_content`,
  UPDATE_LESSONS: `${API_HOST}clinics/discovery/update_lesson`,
  REORDER_LESSONS: `${API_HOST}clinics/discovery/update_lesson_order`,

  // LESSONS CONTENTS
  GET_LESSONS_COMPLETE_CONTENTS: `${API_HOST}clinics/discovery/get_lesson_content_complete`,
  CREATE_LESSONS_CONTENTS: `${API_HOST}clinics/discovery/add_lesson_content`,
  UPDATE_LESSONS_CONTENTS: `${API_HOST}clinics/discovery/update_lesson_content`,
  GET_LESSONS_TASKS: `${API_HOST}clinics/discovery/get_lesson_tasks`,
  CREATE_LESSONS_TASKS: `${API_HOST}clinics/discovery/add_lesson_tasks`,
  UPDATE_LESSONS_TASKS: `${API_HOST}clinics/discovery/update_task`,
  DELETE_LESSONS_TASKS: `${API_HOST}clinics/discovery/delete_task`,
  REORDER_CONTENTS: `${API_HOST}clinics/discovery/update_lesson_content_order`,

  CLINIC_SECTIONS: `${API_HOST}clinics/sections/all_sections`,
  CLINIC_ADD_SECTION: `${API_HOST}clinics/sections/create_section`,
  CLINIC_EDIT_SECTION: `${API_HOST}clinics/sections/edit_section`,
  CLINIC_DELETE_SECTION: `${API_HOST}clinics/sections/delete_section`,

  // RESOURCES
  GET_RESOURCES: `${API_HOST}clinics/discovery/get_resources`,
  CREATE_RESOURCES: `${API_HOST}clinics/discovery/add_resources`,
  DELETE_RESOURCES: `${API_HOST}clinics/discovery/delete_resources`,

  // ACCESS RULES
  UPDATE_ACCESS_RULES: `${API_HOST}clinics/discovery/update_access_rules`,
  UPDATE_ACCESS_RULES_MEDITATION: `${API_HOST}clinics/discovery/update_access_rules_med`,
  GET_ACCESS_RULES: `${API_HOST}clinics/discovery/get_access_rules`,
  GET_ACCESS_RULES_MEDITATION: `${API_HOST}clinics/discovery/get_access_rules_med`,
  DUBLICATE_CLASS: `${API_HOST}clinics/discovery/duplicate_class`,
  UPDATE_LOG: `${API_HOST}front/dashboard/update_logs`
}
