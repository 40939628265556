// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getFrontTermAndCondition = createAsyncThunk('termAndConditions/getFrontTermAndCondition', async () => {
  const response = await axiosRequest.get(meeting.TERM_AND_CONDITIONS)
  return {
    termAndCondition: response?.data?.data ?? []
  }
})


export const frontTermAndCondtionsSlice = createSlice({
  name: 'termAndConditions',
  initialState: {
    termAndCondition: [],
    termAndConditionLoading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontTermAndCondition.fulfilled, (state, { payload }) => {
        state.termAndCondition = payload?.termAndCondition ?? []
      })
      .addCase(getFrontTermAndCondition.pending, (state) => {
        console.log("LOADING...")
        state.termAndConditionLoading = true
      })
      .addCase(getFrontTermAndCondition.rejected, (state) => {
        console.log("REJECTED...")
        state.termAndConditionLoading = false
      })
  }
})

export default frontTermAndCondtionsSlice.reducer
