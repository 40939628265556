import mock from '../mock'
/* eslint-disable */
// ** Utils
import { paginateArray, sortCompare, randomDate, getRandomInt } from '../utils'

const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
const nextWeek = new Date(nextDay.getTime() + 7 * 24 * 60 * 60 * 1000)

const data = {
    products: [
        {
            id: 1,
            name: 'Group 1',
            description:
                'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge. Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
            members: 3,
            post_count: 4,
            image: 'https://healmb.com/uploads/testing.jpg',
            status: 1      // status '0' for not join, '1' for join
        },
        {
            id: 2,
            name: 'Group 2',
            description:
                'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
            members: 1,
            post_count: 2,
            image: 'https://healmb.com/uploads/testing.jpg',
            status: 0      // status '0' for not join, '1' for join
        },
        {
            id: 3,
            name: 'Group 3',
            description:
                'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
            members: 7,
            post_count: 12,
            image: 'https://healmb.com/uploads/testing.jpg',
            status: 1      // status '0' for not join, '1' for join
        }
    ],
    userWishlist: [
        { id: 1, productId: 3 }
    ],

    // detail
    groupDetail: {
        blog: {
            id: 3,
            img: require('@src/assets/images/banner/banner-12.jpg').default,
            title: 'Group 1',
            avatar: require('@src/assets/images/portrait/small/avatar-s-7.jpg').default,
            userFullName: 'Ghani Pradita',
            createdTime: 'Jan 10, 2020',
            content:
                '<p class="card-text mb-2">Before you get into the nitty-gritty of coming up with a perfect title, start with a rough draft: your working title. What is that, exactly? A lot of people confuse working titles with topics. Let\'s clear that Topics are very general and could yield several different blog posts. Think "raising healthy kids," or "kitchen storage." A writer might look at either of those topics and choose to take them in very, very different directions.A working title, on the other hand, is very specific and guides the creation of a single blog post. For example, from the topic "raising healthy kids," you could derive the following working title See how different and specific each of those is? That\'s what makes them working titles, instead of overarching topics.</p>',
            comments: 19100
        },
        comments: [
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
                userFullName: 'Chad Alexander',
                commentedAt: 'May 24, 2020',
                commentText:
                    'A variation on the question technique above, the multiple-choice question great way to engage your reader.'
            }
        ]
    },

    // sidebar
    posts: {
        recentPosts: [
            {
                img: require('@src/assets/images/banner/banner-22.jpg').default,
                title: 'Why Should Forget Facebook?',
                description: 'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge. Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge',
                id: 7,
                userFullName: 'Ghani Pradita',
                createdTime: 'Jan 14 2020',
                commentsCount: '3',
                likesCount: '5'
            },
            {
                img: require('@src/assets/images/banner/banner-27.jpg').default,
                title: 'Publish your passions, your way',
                description: 'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
                id: 8,
                userFullName: 'Noman Nazir',
                createdTime: 'Mar 04 2020',
                commentsCount: '3',
                likesCount: '5'
            },
            {
                img: require('@src/assets/images/banner/banner-39.jpg').default,
                title: 'The Best Ways to Retain More',
                description: 'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
                id: 9,
                userFullName: 'Ghani Pradita',
                createdTime: 'Feb 18 2020',
                commentsCount: '3',
                likesCount: '5'
            },
            {
                img: require('@src/assets/images/banner/banner-35.jpg').default,
                title: 'Share a Shocking Fact or Statistic',
                description: 'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge.',
                id: 10,
                userFullName: 'Wikram Das',
                createdTime: 'Oct 08 2020',
                commentsCount: '3',
                likesCount: '5'
            }
        ],
        participants: [
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
                title: 'Ryan Harrington'
            },
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
                title: 'Louisa Norton'
            },
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-1.jpg').default,
                title: 'Jayden Duncan'
            },
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-20.jpg').default,
                title: 'Cynthia Howell'
            },
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-16.jpg').default,
                title: 'Helena Payne'
            },
            {
                avatar: require('@src/assets/images/portrait/small/avatar-s-13.jpg').default,
                title: 'Troy Jensen'
            }
        ]
    }
}
/* eslint-enable */


mock.onGet('/group/detail').reply(() => [200, data])
mock.onGet('/group/posts').reply(() => [200, data.posts])


// ------------------------------------------------
// GET: Return products
// ------------------------------------------------
mock.onGet('/groups/products').reply(config => {
    // eslint-disable-next-line object-curly-newline
    const { q = '', sortBy = 'featured', perPage = 9, page = 1 } = config.params

    const queryLowered = q.toLowerCase()

    const filteredData = data.products.filter(product => product.name.toLowerCase().includes(queryLowered))

    let sortDesc = false
    const sortByKey = (() => {
        if (sortBy === 'price-desc') {
            sortDesc = true
            return 'price'
        }
        if (sortBy === 'price-asc') {
            return 'price'
        }
        sortDesc = true
        return 'id'
    })()

    const sortedData = filteredData.sort(sortCompare(sortByKey))
    if (sortDesc) sortedData.reverse()

    const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

    paginatedData.forEach(product => {
        /* eslint-disable no-param-reassign */
        product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
        //product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1
        /* eslint-enable */
    })

    return [
        200,
        {
            products: paginatedData,
            total: filteredData.length,
            userWishlist: data.userWishlist
            //userCart: data.userCart
        }
    ]
})

// ------------------------------------------------
// GET: Return Single Product
// ------------------------------------------------
mock.onGet(/\/apps\/ecommerce\/products\/\d+/).reply(config => {
    // Get product id from URL
    let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    productId = Number(productId)

    const productIndex = data.products.findIndex(p => p.id === productId)
    const product = data.products[productIndex]

    if (product) {
        // Add data of wishlist and cart
        product.isInWishlist = data.userWishlist.findIndex(p => p.productId === product.id) > -1
        //product.isInCart = data.userCart.findIndex(p => p.productId === product.id) > -1

        // * Add Dummy data for details page
        product.colorOptions = ['primary', 'success', 'warning', 'danger', 'info']

        return [200, { product }]
    }
    return [404]
})

// ------------------------------------------------
// GET: Return Wishlist Products
// ------------------------------------------------
mock.onGet('/groups/wishlist').reply(() => {
    const products = data.userWishlist.map(wishlistProduct => {
        const product = data.products.find(p => p.id === wishlistProduct.productId)
        //product.isInCart = data.userCart.findIndex(p => p.productId === wishlistProduct.productId) > -1
        return product
    })

    return [200, { products }]
})

// ------------------------------------------------
// GET: Return Cart Products
// ------------------------------------------------
// mock.onGet('/apps/ecommerce/cart').reply(() => {
//     const products = data.userCart.map(cartProduct => {
//         const product = data.products.find(p => p.id === cartProduct.productId)

//         // Other data
//         product.isInWishlist = data.userWishlist.findIndex(p => p.productId === cartProduct.productId) > -1
//         product.qty = cartProduct.qty
//         product.shippingDate = randomDate(nextDay, nextWeek)
//         product.offers = getRandomInt(1, 4)
//         product.discountPercentage = getRandomInt(3, 20)

//         return product
//     })

//     return [200, { products }]
// })

// ------------------------------------------------
// POST: Add Item in user Cart
// ------------------------------------------------
// mock.onPost('/apps/ecommerce/cart').reply(config => {
//     // Get product from post data
//     const { productId } = JSON.parse(config.data)

//     const { length } = data.userCart
//     let lastId = 0
//     if (length) lastId = data.userCart[length - 1].i

//     data.userCart.push({
//         id: lastId + 1,
//         productId,
//         qty: 1
//     })

//     return [201]
// })

// ------------------------------------------------
// DELETE: Remove Item from user Cart
// ------------------------------------------------
// mock.onDelete(/\/apps\/ecommerce\/cart\/\d+/).reply(config => {
//     // Get product id from URL
//     let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

//     // Convert Id to number
//     productId = Number(productId)

//     const productIndex = data.userCart.findIndex(i => i.productId === productId)
//     if (productIndex > -1) data.userCart.splice(productIndex, 1)

//     return [200]
// })

// ------------------------------------------------
// POST: Add Item in user Wishlist
// ------------------------------------------------
mock.onPost('/groups/wishlist').reply(config => {
    // Get product from post data
    const { productId } = JSON.parse(config.data)

    const { length } = data.userWishlist
    let lastId = 0
    if (length) lastId = data.userWishlist[length - 1].i

    data.userWishlist.push({
        id: lastId + 1,
        productId: Number(productId)
    })

    return [201]
})

// ------------------------------------------------
// DELETE: Remove Item from user Wishlist
// ------------------------------------------------
mock.onDelete(/\/groups\/wishlist\/\d+/).reply(config => {
    // Get product id from URL
    let productId = config.url.substring(config.url.lastIndexOf('/') + 1)

    // Convert Id to number
    productId = Number(productId)

    const productIndex = data.userWishlist.findIndex(i => i.productId === productId)
    if (productIndex > -1) data.userWishlist.splice(productIndex, 1)

    return [200]
})
