export const textTypeOptions = ['Text', 'text']
export const fileTypeOptions = ['file', 'File']
export const documentTypeOptions = ['Document', 'document']
export const classTypeOptions = ['Class', 'class']
export const simpleTypeOptions = ['SimpleText']
export const lessonTypeOptions = ['Lesson', 'lesson']
export const videoTypeOptions = ['Vimeo', 'vimeo', 'Sound Cloud', 'sound_cloud', 'Video', 'video', 'Youtube', 'youtube', 'Youtube Link']
export const contentFileType = ['content']
export const audioFileType = ['Audio', 'audio']
export const imageFileType = ['Image', 'image']

export const USER_ROUTES = {
  admin: 'administration',
  front: 'frontuser',
  patient: 'patient',
  clinic: 'clinic',
  employee: 'employee'
}
export default videoTypeOptions