// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { tasks } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
//
//logs
export const updateLogs = createAsyncThunk('newDiscovery/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(tasks?.UPDATE_LOGS_TASK, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
// GET STATEGRAPH 
export const getFrontStateGraph = createAsyncThunk('tasks/getFrontStateGraph', async () => {
  const response = await axiosRequest.get(tasks.FRONT_STATE_GHRAPH)
  return { stateGraph: response?.data?.data ?? [] }
})
export const getFrontAllTasks = createAsyncThunk('tasks/getFrontAllTasks', async (params) => {
  let url = tasks.FRONT_ALL_TASKS
  if (params?.filter === 'recent') {
    url = tasks.FRONT_RECENT_TASKS
  } else if (params?.filter === 'completed') {
    url = tasks.FRONT_COMPLETED_TASKS
  }
  const response = await axiosRequest.get(url)
  let finalData = response.data?.data
  if (!isArrayEmpty(response.data?.data) && params?.filter !== 'completed') {
    finalData = response.data?.data.map((item, index) => {
      return {
        ...item,
        background: getTaskColors()[index]
      }
    })
  }
  return { params, data: finalData }
})

export const setFrontTasks = createAsyncThunk('tasks/setFrontTasks', async (data) => {
  return { data }
})

// ** Get Categories Items List 
export const getCategoryItems = createAsyncThunk('tasks/getCategoryItems', async (cat_id) => {
  const response = await axiosRequest.post(tasks.FRONT_CATEGORY_TASKS, { cat_id: parseInt(cat_id) })
  let finalData = []
  if (!isArrayEmpty(response.data?.data)) {
    finalData = response.data?.data.map((item, index) => {
      return {
        ...item,
        background: getTaskColors()[index]
      }
    })
  }
  return { categoryItems: finalData, status: response.data?.status }
})

export const unMountFrontAllTasks = createAsyncThunk('tasks/unMountFrontAllTasks', async () => {
  return {}
})

export const getCategories = createAsyncThunk('tasks/getCategories', async () => {
  const response = await axiosRequest.get(tasks.FRONT_GET_CATEGORIES)
  return {
    categories: response?.data?.data ?? []
  }
})

export const onCompleteSignleTask = createAsyncThunk('tasks/onCompleteSignleTask', async (data) => {
  const response = await axiosRequest.post(tasks.FRONT_SINGLE_TASK_COMPLETE, data)
  return {
    message: response?.data?.message ?? {},
    ask_final_feelings: response?.data?.ask_final_feelings ?? false,
    status: response?.data?.status ?? false
  }
})
export const createNewTask = createAsyncThunk('tasks/createNewTask', async (data) => {
  const response = await axiosRequest.post(tasks.FRONT_CREATE_NEW_TASKS, data)
  return { message: response?.data?.message ?? {}, status: response.data?.status }
})

export const updateTaskFinalFeelings = createAsyncThunk('tasks/updateTaskFinalFeelings', async (data) => {
  const response = await axiosRequest.post(tasks.UPDATE_TASK_FINAL_FEELINGS, data)
  return { message: response?.data?.msg ?? {}, status: response.data?.status }
})

export const updateTasksOrder = createAsyncThunk('tasks/updateTasksOrder', async (params) => {
  const data = params.map((item, index) => {
    return {
      id: item.id,
      task_order: index + 1
    }
  })
  console.log("==data==", data)
  const response = await axiosRequest.post(tasks.UPDATE_TASKS_ORDER, { tasks: data })
  console.log("==response==", response.data)
  return { message: response?.data?.msg ?? {}, status: response.data?.status }
})

export const setTasksLoader = createAsyncThunk('appTodo/setTasksLoader', async (status) => {
  return { status }
})

import axios from 'axios'
import { displayErrorToast, displaySuccessToast } from '../../../../utility/helper'
import { getTaskColors, isArrayEmpty } from '../../../../utility/Utils'

export const updateTaskList = createAsyncThunk('tasks/updateTaskList', async (taskList, { dispatch, getState }) => {
  await dispatch(getTasks(getState().todo.params))
  return taskList
})

export const addTask = createAsyncThunk('appTodo/addTask', async (task, { dispatch, getState }) => {
  const response = await axios.post('/apps/todo/add-tasks', { task })
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async (task, { dispatch, getState }) => {
  const response = await axios.post('/apps/todo/update-task', { task })
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (taskId, { dispatch, getState }) => {
  const response = await axios.delete('/apps/todo/delete-task', { taskId })
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    data: [],
    stateGraph: [],
    categories: [],
    selectedTask: {},
    taskCreated: '',
    params: {
      filter: '',
      q: '',
      sort: '',
      tag: ''
    },
    completeTask: '',
    categoryItems: [],
    tasksLoading: false,
    ask_final_feelings: false,
    addTaskLoader: false,
    finalFeelingLoader: false
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.data = action.payload
    },
    reorderCategoryTasks: (state, action) => {
      state.categoryItems = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setTasksLoader.fulfilled, (state, { payload }) => {
        state.tasksLoading = payload?.status ?? false
      })
      .addCase(getFrontAllTasks.pending, (state) => {
        state.tasksLoading = true
      })
      .addCase(getFrontAllTasks.rejected, (state) => {
        state.tasksLoading = false
      })
      .addCase(getFrontAllTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.params = payload?.params
        state.tasksLoading = false
      })
      .addCase(setFrontTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data
      })
      .addCase(getFrontStateGraph.fulfilled, (state, { payload }) => {
        state.stateGraph = payload?.stateGraph
      })
      .addCase(onCompleteSignleTask.fulfilled, (state, { payload }) => {
        displaySuccessToast('Task has been Completed Successfully')
        state.completeTask = payload?.message
        state.ask_final_feelings = payload?.ask_final_feelings
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.categories
      })
      .addCase(createNewTask.pending, (state) => {
        state.addTaskLoader = true
      })
      .addCase(createNewTask.rejected, (state) => {
        state.addTaskLoader = false
      })
      .addCase(createNewTask.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast(payload?.message)
        } else {
          displayErrorToast(payload?.message)
        }
        state.taskCreated = payload?.message
        state.addTaskLoader = false
      })
      .addCase(getCategoryItems.fulfilled, (state, { payload }) => {
        state.categoryItems = payload?.categoryItems
      })
      .addCase(unMountFrontAllTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      //
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })

      .addCase(updateTaskFinalFeelings.pending, (state) => {
        state.finalFeelingLoader = true
      })
      .addCase(updateTaskFinalFeelings.rejected, (state) => {
        state.finalFeelingLoader = false
      })
      .addCase(updateTaskFinalFeelings.fulfilled, (state) => {
        state.finalFeelingLoader = false
      })
  }
})

export const { reOrderTasks, reorderCategoryTasks, selectTask } = tasksSlice.actions

export default tasksSlice.reducer
