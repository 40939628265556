import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUserData, isStringEmpty } from '../utility/Utils'
import { chats } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  return getUserData()
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (user) => {
  let chatUrl = chats.CHAT_CONTACTS
  let contactsUrl = chats.CHAT_ALL_CONTACTS
  if (['admin', 'masterAdmin'].includes(user)) {
    chatUrl = chats.ADMIN_CHAT_CONTACTS
    contactsUrl = chats.ADMIN_CONTACTS
  }
  const response = await axiosRequest.get(chatUrl)
  const allContacts = await axiosRequest.get(contactsUrl)
  return {
    data: response?.data?.data ?? [],
    status: response?.data?.status,
    contacts: allContacts?.data?.data ?? []
  }
})
export const setChatContactsData = createAsyncThunk('appChat/setChatContactsData', async (data) => {
  return { data }
})

export const getRecentChat = createAsyncThunk('appChat/getRecentChat', async () => {
  const response = await axiosRequest.get(chats?.GET_RECENT_CHAT)
  console.log('-----', response?.data)
  return { data: response?.data?.data ?? [] }
})

export const getChatConnectionRequests = createAsyncThunk('appChat/getChatConnectionRequests', async () => {
  const response = await axiosRequest.post(chats.ALL_CONNECTION_REQUEST)
  return {
    data: response?.data?.data ?? [],
    status: response?.data?.status
  }
})

export const setChatRequestsData = createAsyncThunk('appChat/setChatRequestsData', async (data) => {
  return { data }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (item) => {
  const response = await axiosRequest.post(chats.CHAT_HISTORY, { chat_with_userid: item?.to })
  return { data: response?.data?.data ?? [], contact: item }
})

export const onSendMessage = createAsyncThunk('appChat/onSendMessage', async ({ selectedUser, params, chatItem, dispatch }) => {
  const chatData = JSON.parse(JSON.stringify(selectedUser?.chat))
  chatData.push(chatItem)
  await dispatch(appendChatMessage({ selectedUser, chat: chatData }))
  const response = await axiosRequest.post(chats.SEND_MESSAGE, params)
  return { status: response?.data?.status }
})

export const appendChatMessage = createAsyncThunk('appChat/appendChatMessage', async ({ selectedUser, chat }) => {
  return { data: chat, contact: selectedUser?.contact }
})

export const getChatAdminId = createAsyncThunk('appChat/getChatAdminId', async () => {
  const response = await axiosRequest.get(chats.GET_ADMIN_ID)
  return {
    adminId: response?.data?.admin_id ?? null,
    name: response?.data?.name ?? null,
    image: response?.data?.image ?? null,
    status: response?.data?.status ?? false
  }
})

export const setChatAdminId = createAsyncThunk('appChat/setChatAdminId', async (id) => {
  return { adminId: id ?? null }
})

export const handleConnectionRequest = createAsyncThunk('appChat/handleConnectionRequest', async ({ params, type }) => {
  if (isStringEmpty(type)) { return }
  const requestType = {
    accept: chats.ACCEPT_CONNECTION_REQUEST,
    send: chats.SEND_CONNECTION_REQUEST,
    delete: chats.DELETE_CONNECTION_REQUEST
  }
  const response = await axiosRequest.post(requestType[type], params)
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.msg ?? null
  }
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    loading: false,
    chatContacts: [],
    contacts: [],
    userProfile: {},
    selectedUser: {},
    historyLoading: false,
    adminId: null,
    recentChatLoading: false,
    requestLoading: false,
    chatRequests: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      // GET CHAT CONTACTS
      .addCase(getChatContacts.pending, (state) => {
        state.loading = true
      })
      .addCase(getChatContacts.fulfilled, (state, { payload }) => {
        state.loading = false
        state.chatContacts = payload?.data ?? []
        state.contacts = payload?.contacts ?? []
      })
      .addCase(setChatContactsData.fulfilled, (state, { payload }) => {
        state.contacts = payload?.data ?? []
      })
      .addCase(getChatContacts.rejected, (state) => {
        state.loading = false
      })
      .addCase(getChatConnectionRequests.pending, (state) => {
        state.requestLoading = true
      })
      .addCase(getChatConnectionRequests.rejected, (state) => {
        state.requestLoading = false
      })
      .addCase(getChatConnectionRequests.fulfilled, (state, { payload }) => {
        state.requestLoading = false
        state.chatRequests = payload?.data ?? []
      })
      //
      .addCase(getRecentChat.pending, (state) => {
        state.recentChatLoading = true
      })
      .addCase(getRecentChat.rejected, (state) => {
        state.recentChatLoading = false
      })
      .addCase(getRecentChat.fulfilled, (state, { payload }) => {
        state.recentChatLoading = false
        state.recentChatsData = payload?.data ?? []
      })
      //
      .addCase(setChatRequestsData.fulfilled, (state, { payload }) => {
        state.chatRequests = payload?.data ?? []
      })
      // SELECT SINGLE CHAT
      .addCase(selectChat.pending, (state) => {
        state.historyLoading = true
      })
      .addCase(selectChat.fulfilled, (state, { payload }) => {
        state.historyLoading = false
        state.selectedUser.contact = payload?.contact
        state.selectedUser.chat = payload?.data
      })
      .addCase(selectChat.rejected, (state) => {
        state.historyLoading = false
      })

      .addCase(getChatAdminId.fulfilled, (state, { payload }) => {
        state.adminId = payload?.adminId ?? null
      })
      .addCase(setChatAdminId.fulfilled, (state, { payload }) => {
        state.adminId = payload?.adminId ?? null
      })

      .addCase(appendChatMessage.fulfilled, (state, { payload }) => {
        state.selectedUser.contact = payload?.contact
        state.selectedUser.chat = payload?.data
      })
  }
})

export default appChatSlice.reducer
