import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { email_config } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displayErrorToast, displaySuccessToast } from '../../../../utility/helper'
export const getAdminConfigEmails = createAsyncThunk('adminEmailConfigs/getAdminConfigEmails', async () => {
  const response = await axiosRequest.get(email_config.ADMIN_EMAIL_CONFIG_LIST)
  return { data: response?.data?.data }
})

export const setAdminConfigEmails = createAsyncThunk('adminEmailConfigs/setAdminConfigEmails', async (data) => {
  return { data }
})

export const addAdminEmail = createAsyncThunk('adminEmailConfigs/addAdminEmail', async (data, { dispatch }) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_ADD_EMAIL, data)
  dispatch(getAdminConfigEmails())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const updateAdminEmail = createAsyncThunk('adminEmailConfigs/updateAdminEmail', async (data) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_EDIT_EMAIL, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const deleteAdminEmail = createAsyncThunk('adminEmailConfigs/deleteAdminEmail', async (id) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_DELETE_EMAIL, { id })
  return { id, status: response.data?.status }
})

export const deleteAdminEmailTemplate = createAsyncThunk('adminEmailConfigs/deleteAdminEmailTemplate', async (id) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_DELETE_EMAIL_TEMPLATE, { id })
  return { id, status: response.data?.status }
})

// EMAIL TEMPLATES
export const getAdminConfigEmailTemplates = createAsyncThunk('adminEmailConfigs/getAdminConfigEmailTemplates', async () => {
  const response = await axiosRequest.get(email_config.ADMIN_EMAIL_CONFIG_EMAIL_TEMPLATES)
  return { data: response?.data?.data }
})

export const setAdminConfigEmailTemplates = createAsyncThunk('adminEmailConfigs/setAdminConfigEmailTemplates', async (data) => {
  return { data }
})

export const addAdminEmailTemplate = createAsyncThunk('adminEmailConfigs/addAdminEmailTemplate', async (data, { dispatch }) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_ADD_EMAIL_TEMPLATE, data)
  dispatch(getAdminConfigEmailTemplates())
  return { status: response?.data?.status }
})

export const updateAdminEmailTemplate = createAsyncThunk('adminEmailConfigs/updateAdminEmailTemplate', async (data) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_EDIT_EMAIL_TEMPLATE, data)
  return { status: response?.data?.status }
})

// EMAIL LOGS
export const getAdminConfigEmailLogs = createAsyncThunk('adminEmailConfigs/getAdminConfigEmailLogs', async (page) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_EMAIL_LOGS, page)
  return { data: response?.data?.data, totalPages: response?.data?.total_pages}
})

export const setAdminConfigEmailLogs = createAsyncThunk('adminEmailConfigs/setAdminConfigEmailLogs', async (data) => {
  return { data }
})
// EMAIL LOGS - SEND EMAIL
export const adminConfigSendEmail = createAsyncThunk('adminEmailConfigs/adminConfigSendEmail', async (data) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_SEND_EMAIL, data)
  return { data: response?.data?.data }
})
export const adminConfigSendBulkEmail = createAsyncThunk('adminEmailConfigs/adminConfigSendBulkEmail', async (data) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_SEND_BULK_EMAIL, data)
  return { data: response?.data?.data }
})

export const getAdminConfigAllClinics = createAsyncThunk('adminEmailConfigs/getAdminConfigAllClinics', async () => {
  const response = await axiosRequest.get(email_config.ADMIN_EMAIL_CONFIG_GET_ALL_CLINICS)
  return { data: response?.data?.data }
})
export const getAdminConfigClinicUsers = createAsyncThunk('adminEmailConfigs/getAdminConfigClinicUsers', async (clinic_id) => {
  const response = await axiosRequest.post(email_config.ADMIN_EMAIL_CONFIG_GET_CLINIC_USERS, { clinic_id })
  return { data: response?.data?.data }
})

export const adminEmailConfigsSlice = createSlice({
  name: 'adminEmailConfigs',
  initialState: {
    loading: true,
    data: [],
    templatesLoading: true,
    emailTemplates: [],
    logsLoading: true,
    emailLogs: [],
    clinicsList: [],
    clinicUsers: [],
    emailSent: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminConfigEmails.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminConfigEmails.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminConfigEmails.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminConfigEmails.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteAdminEmail.fulfilled, (state, { payload }) => {
        console.log(`DELETED EMAIL ID ${payload?.id}`)
      })
      .addCase(deleteAdminEmailTemplate.fulfilled, (state, { payload }) => {
        console.log(`DELETED TEMPLATE ID ${payload?.id}`)
      })
      .addCase(deleteAdminEmailTemplate.pending, (state) => {
        console.log("TEMPLATES LOADING...")
        state.templatesLoading = true
      })
      .addCase(deleteAdminEmailTemplate.rejected, (state) => {
        console.log("TEMPLATES REJECTED...")
        state.templatesLoading = false
      })
      .addCase(addAdminEmail.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast(payload?.message)
        } else {
          displayErrorToast(payload?.message)
        }
        console.log(`ADDED EMAIL ${payload?.status}`)
      })
      .addCase(updateAdminEmail.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast(payload?.message)
        } else {
          displayErrorToast(payload?.message)
        }
        console.log(`ADDED EMAIL ${payload?.status}`)
      })
      // EMAIL TEMPLATES
      .addCase(getAdminConfigEmailTemplates.fulfilled, (state, { payload }) => {
        console.log("TEMPLATES FULFILLED...")
        state.emailTemplates = payload?.data ?? []
        state.templatesLoading = false
      })
      .addCase(setAdminConfigEmailTemplates.fulfilled, (state, { payload }) => {
        state.emailTemplates = payload?.data ?? []
      })
      .addCase(getAdminConfigEmailTemplates.pending, (state) => {
        console.log("TEMPLATES LOADING...")
        state.templatesLoading = true
      })
      .addCase(getAdminConfigEmailTemplates.rejected, (state) => {
        console.log("TEMPLATES REJECTED...")
        state.templatesLoading = false
      })
      .addCase(addAdminEmailTemplate.fulfilled, (state, { payload }) => {
        displaySuccessToast('Email Template has been Added Successfully')
        console.log(`ADDED TEMPLATE ${payload?.status}`)
      })
      .addCase(updateAdminEmailTemplate.fulfilled, (state, { payload }) => {
        displaySuccessToast('Email Template has been Updeted Successfully')
        console.log(`ADDED TEMPLATE ${payload?.status}`)
      })
      // EMAIL LOGS
      .addCase(getAdminConfigEmailLogs.fulfilled, (state, { payload }) => {
        console.log("EMAIL LOGS FULFILLED...")
        state.emailLogs = payload?.data ?? []
        state.totalPagesData = payload?.totalPages
        state.logsLoading = false
      })
      .addCase(setAdminConfigEmailLogs.fulfilled, (state, { payload }) => {
        state.emailLogs = payload?.data ?? []
      })
      .addCase(getAdminConfigEmailLogs.pending, (state) => {
        console.log("EMAIL LOGS LOADING...")
        state.logsLoading = true
      })
      .addCase(getAdminConfigEmailLogs.rejected, (state) => {
        console.log("EMAIL LOGS REJECTED...")
        state.logsLoading = false
      })
      .addCase(adminConfigSendEmail.fulfilled, (state) => {
        console.log("EMAIL SENT...")
        state.emailSent = true
        // displaySuccessToast('Email has been sent')
      })
      .addCase(adminConfigSendBulkEmail.fulfilled, () => {
        console.log("BULK EMAIL SENT...")
      })
      // CLINICS
      .addCase(getAdminConfigAllClinics.fulfilled, (state, { payload }) => {
        console.log("EMAIL CONFIG ALL CLINICS FULFILLED...")
        state.clinicsList = payload?.data ?? []
      })
      .addCase(getAdminConfigClinicUsers.fulfilled, (state, { payload }) => {
        console.log("EMAIL CONFIG CLINIC USERS FULFILLED...")
        state.clinicUsers = payload?.data ?? []
      })
  }
})

export default adminEmailConfigsSlice.reducer
