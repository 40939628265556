// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { assignments } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getAssignments = createAsyncThunk('frontAssignments/getAssignments', async () => {
  const response = await axiosRequest.get(assignments.FRONT_ASSIGNMENTS)
  return { data: response?.data?.data }
})

export const setAssignments = createAsyncThunk('frontAssignments/setAssignments', async (data) => {
  return { data }
})

export const getAssignmentChildTask = createAsyncThunk('frontAssignments/getAssignmentChildTask', async (data) => {
  console.log('-------getAssignmentChildTask-- data---', data)
  const response = await axiosRequest.post(assignments.FRONT_ASSIGNMENT_CHILD_TASK, data)
  return { data: response?.data?.data }
})

export const setAssignmentChildTask = createAsyncThunk('frontAssignments/setAssignmentChildTask', async (data) => {
  return { data }
})

export const assignmentTaskDone = createAsyncThunk('frontAssignments/assignmentTaskDone', async (id) => {
  const response = await axiosRequest.post(assignments.FRONT_ASSIGNMENT_TASK_DONE, { id })
  return { id, status: response.data?.status ?? false }
})

export const getAssignmentsStats = createAsyncThunk('frontAssignments/getAssignmentsStats', async () => {
  const response = await axiosRequest.get(assignments.FRONT_ASSIGNMENT_STATS)
  return { data: response?.data?.stats ?? {} }
})

export const deleteChildAssignment = createAsyncThunk('frontAssignments/deleteChildAssignment', async (id) => {
  const response = await axiosRequest.post(assignments.CLINIC_DELETE_CHILD_ASSIGNMENT, { id })
  return { id, status: response.data?.status }
})

export const markAllTasksComplete = createAsyncThunk('frontAssignments/markAllTasksComplete', async (assignment_id) => {
  const response = await axiosRequest.post(assignments.MARK_ALL_COMPLETE_FRONT_ASSIGNMENT, { assignment_id })
  return { status: response.data?.status }
})


export const assignmentsSlice = createSlice({
  name: 'frontAssignments',
  initialState: {
    data: [],
    loading: true,
    assignmentChildTasks: [],
    assignmentChildTasksLoading: false,
    assignmentStats: {},
    assignmentStatsLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAssignments.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAssignments.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAssignments.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAssignments.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAssignmentChildTask.fulfilled, (state, { payload }) => {
        console.log(`ASSIGNMENT CHILD TASKS ${payload?.data}`)
        state.assignmentChildTasks = payload?.data ?? []
        state.assignmentChildTasksLoading = false
      })
      .addCase(getAssignmentChildTask.pending, (state) => {
        state.assignmentChildTasksLoading = true
      })
      .addCase(getAssignmentChildTask.rejected, (state) => {
        state.assignmentChildTasksLoading = false
      })
      .addCase(setAssignmentChildTask.fulfilled, (state, { payload }) => {
        state.assignmentChildTasks = payload?.data ?? []
      })
      .addCase(assignmentTaskDone.fulfilled, (state, { payload }) => {
        console.log(`TASK DONE ${payload.id}`)
      })
      .addCase(deleteChildAssignment.fulfilled, (state, { payload }) => {
        console.log(`CHILD ASSIGNMENT DELETED ${payload?.id}`)
      })
      .addCase(getAssignmentsStats.fulfilled, (state, { payload }) => {
        state.assignmentStats = payload?.data ?? {}
        state.assignmentStatsLoading = false
      })
      .addCase(getAssignmentsStats.pending, (state) => {
        state.assignmentStatsLoading = true
      })
      .addCase(getAssignmentsStats.rejected, (state) => {
        state.assignmentStatsLoading = false
      })
  }
})

export default assignmentsSlice.reducer
