// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { tests } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminTests = createAsyncThunk('adminTests/getAdminTests', async () => {
  const response = await axiosRequest.get(tests.ADMIN_TESTS)
  return { data: response?.data?.data }
})

export const getTestsStats = createAsyncThunk('adminTests/getTestsStats', async () => {
  const response = await axiosRequest.get(tests.TEST_STATS)
  return { data: response?.data }
})

export const setAdminTests = createAsyncThunk('adminTests/setAdminTests', async (data) => {
  return { data }
})

export const createAdminTest = createAsyncThunk('adminTests/createAdminTest', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_ADD_TEST, data)
  dispatch(getAdminTests())
  return { status: response?.data?.status ?? false }
})


export const assignTest = createAsyncThunk('adminTests/assignTest', async (data) => {
  const response = await axiosRequest.post(tests.ASSIGN_TEST, data)
  return { data: response?.data?.data ?? [] }
})

export const updateAdminTest = createAsyncThunk('adminTests/updateAdminTest', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_EDIT_TEST, data)
  console.log("test updated", response.data)
  dispatch(getAdminTests())
  return { status: response?.data?.status }
})

export const deleteAdminTest = createAsyncThunk('adminTests/deleteAdminTest', async (id) => {
  const response = await axiosRequest.post(tests.ADMIN_DELETE_TEST, { id })
  return { id, status: response.data?.status }
})
// TEST QUESTIONS
export const getTestQuestions = createAsyncThunk('adminTests/getTestQuestions', async (test_id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_QUESTIONS, { test_id })
  return { data: response?.data?.data ?? [] }
})

export const setTestQuestions = createAsyncThunk('adminTests/setTestQuestions', async (data) => {
  return { data }
})

export const addTestQuestion = createAsyncThunk('adminTests/addTestQuestion', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ADD_QUESTION, data)
  dispatch(getTestQuestions(data?.test_id))
  return { data: response?.data?.data ?? {}, status: response.data?.status }
})

export const updateTestQuestion = createAsyncThunk('adminTests/updateTestQuestion', async (data) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_EDIT_QUESTION, data)
  return { data, status: response.data?.status }
})

export const deleteTestQuestion = createAsyncThunk('adminTests/deleteTestQuestion', async (id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_DELETE_QUESTION, { id })
  return { id, status: response?.data?.status }
})

// TEST QUESTION OPTIONS
export const getTestQuestionOptions = createAsyncThunk('adminTests/getTestQuestionOptions', async ({ test_id, question_no }) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_QUESTION_OPTIONS, { test_id, question_no })
  return { data: response?.data?.data ?? [] }
})

export const setTestQuestionOptions = createAsyncThunk('adminTests/setTestQuestionOptions', async (data) => {
  return { data }
})

export const addTestQuestionOption = createAsyncThunk('adminTests/addTestQuestionOption', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_QUESTION_ADD_OPTION, data)
  dispatch(getTestQuestionOptions({ test_id: data?.test_id, question_no: data?.question_no }))
  return { data: response?.data?.data ?? {}, status: response?.data?.status }
})

export const updateTestQuestionOption = createAsyncThunk('adminTests/updateTestQuestionOption', async (data) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_QUESTION_EDIT_OPTION, data)
  return { data, status: response.data?.status }
})

export const deleteTestQuestionOption = createAsyncThunk('adminTests/deleteTestQuestionOption', async (id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_QUESTION_DELETE_OPTION, { id })
  return { id, status: response?.data?.status }
})


// TEST ANSWERS
export const getTestAnswers = createAsyncThunk('adminTests/getTestAnswers', async (test_id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ANSWERS, { test_id })
  return { data: response?.data?.data ?? [] }
})

export const setTestAnswers = createAsyncThunk('adminTests/setTestAnswers', async (data) => {
  return { data }
})

export const addTestAnswer = createAsyncThunk('adminTests/addTestAnswer', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ADD_ANSWER, data)
  dispatch(getTestAnswers(data?.test_id))
  return { data: response?.data?.data ?? {}, status: response.data?.status }
})

export const updateTestAnswer = createAsyncThunk('adminTests/updateTestAnswer', async (data) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_EDIT_ANSWER, data)
  return { data, status: response.data?.status }
})

export const deleteTestAnswer = createAsyncThunk('adminTests/deleteTestAnswer', async (id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_DELETE_ANSWER, { id })
  return { id, status: response?.data?.status }
})

// TEST QUESTION OPTIONS
export const getTestAnswerContents = createAsyncThunk('adminTests/getTestAnswerContents', async (result_id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ANSWER_CONTENTS, { result_id })
  return { data: response?.data?.data ?? [] }
})

export const setTestAnswerContents = createAsyncThunk('adminTests/setTestAnswerContents', async (data) => {
  return { data }
})

export const addTestAnswerContent = createAsyncThunk('adminTests/addTestAnswerContent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ANSWER_ADD_CONTENT, data)
  dispatch(getTestAnswerContents(data?.result_id))
  return { data: response?.data?.data ?? {}, status: response?.data?.status }
})

export const updateTestAnswerContent = createAsyncThunk('adminTests/updateTestAnswerContent', async (data) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ANSWER_EDIT_CONTENT, data)
  return { data, status: response.data?.status }
})

export const deleteTestAnswerContent = createAsyncThunk('adminTests/deleteTestAnswerContent', async (id) => {
  const response = await axiosRequest.post(tests.ADMIN_TEST_ANSWER_DELETE_CONTENT, { id })
  return { id, status: response?.data?.status }
})


export const adminTestsSlice = createSlice({
  name: 'adminTests',
  initialState: {
    data: [],
    testStatsData: [],
    loading: true,
    testQuestions: [],
    assignTestData: [],
    testQuestionOptions: [],
    testAnswers: [],
    testAnswerContents: [],
    answersLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminTests.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAdminTests.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminTests.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      //
      .addCase(getTestsStats.fulfilled, (state, { payload }) => {
        state.testStatsData = payload?.data ?? []
        state.loading = false
      })
      .addCase(getTestsStats.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getTestsStats.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminTests.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(createAdminTest.fulfilled, (state, { payload }) => {
        console.log(`CREATED TEST ${payload?.status}`)
        displaySuccessToast('Test has been Added Successfully')
        state.status = payload?.status ?? []
      })
      .addCase(updateAdminTest.fulfilled, (state, { payload }) => {
        console.log(`UPDATED TEST ${payload?.status}`)
        displaySuccessToast('Test has been Updated Successfully')
      })
      .addCase(deleteAdminTest.fulfilled, (state, { payload }) => {
        console.log(`DELETED TEST ID ${payload?.id}`)
      })

      // TEST QUESTIONS
      .addCase(getTestQuestions.fulfilled, (state, { payload }) => {
        state.testQuestions = payload?.data ?? []
        state.questionLoading = false
      })
      .addCase(getTestQuestions.pending, (state) => {
        console.log("LOADING...")
        state.questionLoading = true
      })
      .addCase(getTestQuestions.rejected, (state) => {
        console.log("REJECTED...")
        state.questionLoading = false
      })

      //
      .addCase(assignTest.fulfilled, (state, { payload }) => {
        state.assignTestData = payload?.data ?? []
        state.assignLoading = false
      })
      .addCase(assignTest.pending, (state) => {
        console.log("LOADING...")
        state.assignLoading = true
      })
      .addCase(assignTest.rejected, (state) => {
        console.log("REJECTED...")
        state.assignLoading = false
      })

      .addCase(setTestQuestions.fulfilled, (state, { payload }) => {
        state.testQuestions = payload?.data ?? []
      })
      .addCase(addTestQuestion.fulfilled, (state, { payload }) => {
        console.log(`ADDED TEST - QUESTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Question has been Added Successfully')
      })
      .addCase(deleteTestQuestion.fulfilled, (state, { payload }) => {
        console.log(`DELETED  TEST - QUESTION ID ${payload?.id}`)
        displaySuccessToast('Test Question has been Deleted Successfully')
      })
      .addCase(updateTestQuestion.fulfilled, (state, { payload }) => {
        console.log(`UPDATED TEST - QUESTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Question has been Updated Successfully')
      })

      // TEST QUESTION OPTIONS
      .addCase(getTestQuestionOptions.fulfilled, (state, { payload }) => {
        state.testQuestionOptions = payload?.data ?? []
      })
      .addCase(setTestQuestionOptions.fulfilled, (state, { payload }) => {
        state.testQuestionOptions = payload?.data ?? []
      })
      .addCase(addTestQuestionOption.fulfilled, (state, { payload }) => {
        console.log(`ADDED TEST - QUESTION OPTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Question Options has been Added Successfully')
      })
      .addCase(deleteTestQuestionOption.fulfilled, (state, { payload }) => {
        console.log(`DELETED  TEST - QUESTION OPTIONS ID ${payload?.id}`)
        displaySuccessToast('Test Question Options has been Deleted Successfully')
      })
      .addCase(updateTestQuestionOption.fulfilled, (state, { payload }) => {
        console.log(`UPDATED TEST - QUESTION OPTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Question Options has been Updated Successfully')
      })

      // TEST ANSWERS
      .addCase(getTestAnswers.fulfilled, (state, { payload }) => {
        state.testAnswers = payload?.data ?? []
        state.answersLoading = false
      })
      .addCase(getTestAnswers.pending, (state) => {
        console.log("LOADING...")
        state.answersLoading = true
      })
      .addCase(getTestAnswers.rejected, (state) => {
        console.log("REJECTED...")
        state.answersLoading = false
      })

      .addCase(setTestAnswers.fulfilled, (state, { payload }) => {
        state.testAnswers = payload?.data ?? []
      })
      .addCase(addTestAnswer.fulfilled, (state, { payload }) => {
        console.log(`ADDED TEST - QUESTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Answers has been Added Successfully')
      })
      .addCase(deleteTestAnswer.fulfilled, (state, { payload }) => {
        console.log(`DELETED  TEST - QUESTION ID ${payload?.id}`)
        // displaySuccessToast('Test Answers has been Deleted Successfully')
      })
      .addCase(updateTestAnswer.fulfilled, (state, { payload }) => {
        console.log(`UPDATED TEST - QUESTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Answers has been Updated Successfully')
      })

      // TEST ANSWERS CONTENTS
      .addCase(getTestAnswerContents.fulfilled, (state, { payload }) => {
        state.testAnswerContents = payload?.data ?? []
      })
      .addCase(setTestAnswerContents.fulfilled, (state, { payload }) => {
        state.testAnswerContents = payload?.data ?? []
      })
      .addCase(addTestAnswerContent.fulfilled, (state, { payload }) => {
        console.log(`ADDED TEST - QUESTION OPTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Answers Content has been Added Successfully')
      })
      .addCase(deleteTestAnswerContent.fulfilled, (state, { payload }) => {
        console.log(`DELETED  TEST - QUESTION OPTIONS ID ${payload?.id}`)
        displaySuccessToast('Test Answers Content has been Deleted Successfully')
      })
      .addCase(updateTestAnswerContent.fulfilled, (state, { payload }) => {
        console.log(`UPDATED TEST - QUESTION OPTION ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Test Answers Content has been Updated Successfully')
      })
  }
})

export default adminTestsSlice.reducer
