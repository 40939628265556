import { Fragment } from "react"
import { Slide, toast } from "react-toastify"
import { isStringEmpty } from "./Utils"

export const displayErrorToast = (title, message) => {
  return (
    toast.error(
      <Fragment>
        {!isStringEmpty(title) &&
          <div className='toastify-header'>
            <div className='title-wrapper'>
              <h6 className='toast-title fw-bold'>{title}</h6>
            </div>
          </div>
        }
        {!isStringEmpty(message) &&
          <div className='toastify-body'>
            <span>{message}</span>
          </div>
        }
      </Fragment>,
      { transition: Slide, autoClose: 2000 }
    )
  )
}
export const displaySuccessToast = (title, message) => {
  return (
    toast.success(
      <Fragment>
        {!isStringEmpty(title) &&
          <div className='toastify-header'>
            <div className='title-wrapper'>
              <h6 className='toast-title fw-bold'>{title}</h6>
            </div>
          </div>
        }
        {!isStringEmpty(message) &&
          <div className='toastify-body'>
            <span>{message}</span>
          </div>
        }
      </Fragment>,
      { transition: Slide, autoClose: 2000 }
    )
  )
}