// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { navSearch, tasks } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'


// ** Axios Imports
import axios from 'axios'
import { displaySuccessToast } from '../utility/helper'

export const getBookmarks = createAsyncThunk('layout/getBookmarks', async () => {
  const response = await axios.get('/api/bookmarks/data')
  return {
    data: response.data.suggestions,
    bookmarks: response.data.bookmarks
  }
})

export const updateBookmarked = createAsyncThunk('layout/updateBookmarked', async id => {
  await axios.post('/api/bookmarks/update', { id })
  return id
})

export const getSearchData = createAsyncThunk('layout/getSearchData', async () => {
  const response = await axios.get('/api/main-search/data')
  return {
    data: response?.data?.searchArr ?? []
  }
})
export const getNavSearchData = createAsyncThunk('layout/getNavSearchData', async (query) => {
  console.log('-----getNavSearchData----data---', { query })
  const response = await axiosRequest.post(navSearch.CLIENT_SEARCH_RESULTS, { query })
  console.log('-----getNavSearchData----response---', response?.data?.data)
  return {
    data: response?.data?.data ?? ''
  }
})

// NEED SUPPORT
export const postNeedSupport = createAsyncThunk('frontDashboard/postNeedSupport', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tasks.EVERY_USER_SUPPORT, data)
  if (response?.data?.status) {
    dispatch(getSupportsList())
  }
  return { status: response?.data?.status ?? {} }
})
// ** GET CATEGORIES SUPPORT
export const getSupportCategories = createAsyncThunk('frontDashboard/getSupportCategories', async () => {
  const response = await axiosRequest.get(tasks.CATEGORIES_USER_SUPPORT)
  return { data: response?.data?.data ?? {} }
})

// ** GET SUPPORT LIST
export const getSupportsList = createAsyncThunk('frontDashboard/getSupportsList', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_USER_SUPPORT_LIST)
  return { data: response?.data?.data ?? {} }
})
// GET SUPPORT REPLYS
export const getSupportReplys = createAsyncThunk('frontDashboard/getSupportReplys', async (id) => {
  console.log('------getSupportReplys-------', id)
  const response = await axiosRequest.post(tasks.GET_SUPPORT_REPLYS, id)
  return { data: response?.data?.data ?? {} }
})
//DeLETE TICKET
export const DeleteTicket = createAsyncThunk(
  'adminDashboard/deleteSupportTicket',
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await axiosRequest.post(tasks.ADMIN_TICKET_DELETE, { id: ticketId })
      return { data: response?.data?.data ?? {} }
    } catch (error) {
      return rejectWithValue(error.response?.data ?? 'An error occurred')
    }
  }
)

// POST SUPPORT REPLYS
export const supportsReplys = createAsyncThunk('frontDashboard/supportsReplys', async (data) => {
  console.log('----supportsReplys---data, id----', data)
  const response = await axiosRequest.post(tasks.SUPPORTS_REPLYS, data?.formdata)
  if (response?.data?.status) {
    data?.dispatch(getSupportReplys({ id: data?.id }))
  }
  return { status: response?.data?.status ?? {} }
})

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: {
    query: '',
    bookmarks: [],
    suggestions: [],
    searchData: [],
    navSearcData: '',
    supportCategories: [],
    supportList: [],
    supportLoading: true,
    supportReplys: []
  },
  reducers: {
    handleSearchQuery: (state, action) => {
      state.query = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(DeleteTicket.pending, () => {
        console.log('Delete ticket pending')
      })
      .addCase(DeleteTicket.fulfilled, () => {
        console.log('Delete ticket fulfilled')
      })
      .addCase(DeleteTicket.rejected, () => {
        console.log('Delete ticket rejected')
      })
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(getSearchData.fulfilled, (state, action) => {
        state.searchData = action.payload.data ?? []
      })
      .addCase(getNavSearchData.fulfilled, (state, { payload }) => {
        state.navSearcData = payload.data ?? ''
      })
      // NEED SUPPORT
      .addCase(postNeedSupport.fulfilled, (state, { payload }) => {
        // state.completeTask = payload?.status
        console.log('---------On postNeedSupport fulfilled-------', payload.status)
      })
      // GET CATEGORIES SUPPORT
      .addCase(getSupportCategories.fulfilled, (state, { payload }) => {
        state.supportCategories = payload?.data ?? []
        // console.log('------Notification Data are Get Successfully', state.tasksGraphData)
      })
      // GET SUPPORT LIST
      .addCase(getSupportsList.fulfilled, (state, { payload }) => {
        state.supportList = payload?.data ?? []
        state.supportLoading = false
      })
      .addCase(getSupportsList.pending, (state) => {
        state.supportLoading = true
      })
      .addCase(getSupportsList.rejected, (state) => {
        state.supportLoading = false
      })
      // GET SUPPORT REPLYS
      .addCase(getSupportReplys.fulfilled, (state, { payload }) => {
        state.supportReplys = payload?.data ?? []
        // console.log('------Notification Data are Get Successfully', state.tasksGraphData)
      })
      // POST SUPPORT REPLYS
      .addCase(supportsReplys.fulfilled, (state, { payload }) => {
        if (payload?.status) {
          displaySuccessToast('Reply Forwared Successfully')
        }
        // console.log('------Notification Data are Get Successfully', state.tasksGraphData)
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find(item => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.payload)

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handleSearchQuery } = layoutSlice.actions

export default layoutSlice.reducer
