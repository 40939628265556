// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { favorite } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
// ** Axios Imports
import axios from 'axios'
import { displaySuccessToast } from '../../../../utility/helper'

// const favorites = [
//   {
//     id: 1,
//     name: 'Inspiration Meditation',
//     slug: 'inspiration-meditation',
//     description:
//       'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge. This is the start of your new life!',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 4,
//     isFavorite: true
//   },
//   {
//     id: 2,
//     name: 'The Art of Gratitude',
//     slug: 'the-art-of-gratitude',
//     description:
//       'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge. This is the start of your new life!',
//     // image: 'https://url0.me/static/media/marketing.ea1e3c4f.svg'
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 2,
//     isFavorite: true
//   },
//   {
//     id: 3,
//     name: 'Focus Sustaining Attention',
//     slug: 'focus-sustaining-attention',
//     description:
//       'Your new path starts with your first step. Reach 30 days without drugs or alcohol to complete this challenge. This is the start of your new life!',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 5,
//     isFavorite: true
//   },
//   {
//     id: 4,
//     name: "Depression Cure",
//     slug: 'depression-cure',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 1,
//     isFavorite: true
//   },
//   {
//     id: 5,
//     name: "5 Minute Reset for Desk Jobs",
//     slug: '5-minute-reset-for-desk-jobs',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 3,
//     isFavorite: true
//   },
//   {
//     id: 6,
//     name: "Focus On You",
//     slug: 'focus-on-you',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 0,
//     isFavorite: true
//   },
//   {
//     id: 7,
//     name: "Mental Health For Teens",
//     slug: 'mental-health-for-teens',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 0,
//     isFavorite: true
//   }
// ]

// const fake_data = [
//   {
//     id: 7,
//     name: "Mental Health For Teens",
//     slug: 'mental-health-for-teens',
//     image: 'https://healmb.com/uploads/section_img/Section_41_snap2.jpg',
//     rating: 0,
//     isFavorite: false
//   }
// ]
export const getRecommended = createAsyncThunk('favorites/getRecommended', async () => {
  const response = await axiosRequest.get(favorite.FRONT_RECOMMENDED)
  return {
    recommended: response?.data?.recommended ?? [],
    recommended_contents: response?.data?.recommended_content ?? []
  }
})

export const getFavoriteList = createAsyncThunk('favorites/getFavoriteList', async params => {
  const response = await axiosRequest.post(favorite.FRONT_FAVORITE, { type: params.type })
  return { params, data: response?.data?.data ?? [] }
})

export const deleteFavoritelistItem = createAsyncThunk('favorites/deleteFavoritelistItem', async (data) => {
  const response = await axiosRequest.post(favorite.FRONT_FAVORITE_UNFAVORITE, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const addToFavoritelist = createAsyncThunk('favorites/addToFavoritelist', async id => {
  await axios.post('/favorites/addToFavoritelist', { productId: id })
  return id
})


export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async () => {
  const response = await axios.get('/apps/ecommerce/cart')
  return response.data
})
//logs
export const updateLogs = createAsyncThunk('newDiscovery/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(favorite.UPDATE_LOGS_FAVORITE, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
//logs
export const updateLogsInvite = createAsyncThunk('newDiscovery/updateLogsInvite', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(favorite.INVITE_PEOPLE_LOGS, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
export const getProduct = createAsyncThunk('appEcommerce/getProduct', async slug => {
  const response = await axios.get(`/favorites/detail/${slug}`)
  return response.data
})

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (id, { dispatch, getState }) => {
  const response = await axios.post('/apps/ecommerce/cart', { productId: id })
  await dispatch(getFavoriteList(getState().ecommerce.params))
  return response.data
})

export const getWishlistItems = createAsyncThunk('appEcommerce/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist')
  return response.data
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (id, { dispatch }) => {
  await axios.delete(`/apps/ecommerce/cart/${id}`)
  dispatch(getCartItems())
  return id
})

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    recommended: [],
    recommended_contents: [],
    params: {},
    data: [],
    favoriteListLoading: false,
    wishlist: [],
    totalLength: 0,
    productDetail: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFavoriteList.fulfilled, (state, action) => {
        state.params = action.payload?.params ?? {}
        state.data = action.payload?.data
        state.favoriteListLoading = false
        state.totalLength = action.payload.data?.length ?? 0
      })
      .addCase(getFavoriteList.pending, (state) => {
        state.favoriteListLoading = true
      })
      .addCase(getFavoriteList.rejected, (state) => {
        state.favoriteListLoading = false
      })
      .addCase(deleteFavoritelistItem.fulfilled, () => {
        displaySuccessToast('Delete from favorite')
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.data
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.data
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product
      })
      //
      //
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      //
      .addCase(updateLogsInvite.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      //
      .addCase(getRecommended.fulfilled, (state, { payload }) => {
        state.recommended = payload?.recommended ?? []
        state.recommended_contents = payload?.recommended_contents ?? []
        state.recommendedLoading = false
      })
      .addCase(getRecommended.pending, (state) => {
        state.recommendedLoading = true
      })
      .addCase(getRecommended.rejected, (state) => {
        state.recommendedLoading = false
      })
  }
})

export default favoritesSlice.reducer
