import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_DASHBOARD: `${API_HOST}admin/dashboard/statistics`,
  ADMIN_DASHBOARD_USER_ACTIVITY: `${API_HOST}admin/dashboard/user_activity`,
  ADMIN_DASHBOARD_USER_ACTIVITY_BY_ROLE: `${API_HOST}admin/dashboard/user_activity_rolewise`,
  ADMIN_DASHBOARD_ROLEWISE_STATISTICS: `${API_HOST}admin/dashboard/rolewise_statistics`,
  ADMIN_DASHBOARD_CLINICWISE_STATISTICS: `${API_HOST}admin/dashboard/clinicwise_statistics`,


  //FRONT ROUTES
  FRONT_DASHBOARD: `${API_HOST}front/dashboard/whats_new`,
  FRONT_ASSIGNMENT_GRAPH: `${API_HOST}front/dashboard/assignment_stat`,
  FRONT_TASKS_GRAPH: `${API_HOST}front/dashboard/task_stat`,
  FRONT_ALERT_POPUP: `${API_HOST}front/dashboard/alert_popup`,

  // CLINIC ROUTES
  CLINIC_DASHBOARD: `${API_HOST}front/dashboard/whats_new`,
  CLINIC_ASSIGNMENT_GRAPH: `${API_HOST}front/dashboard/assignment_stat`,
  CLINIC_TASKS_GRAPH: `${API_HOST}front/dashboard/task_stat`,

  SESSION_BY_DEVICE: `${API_HOST}front/dashboard/session_by_device`,
  USER_AVERAGE_STATS: `${API_HOST}front/dashboard/user_average`,
  RECENT_DASHBOARD_CLASSES: `${API_HOST}front/new_discovery/get_recent_classes`,
  FEATURE_CLASS: `${API_HOST}front/new_discovery/get_featured_classes`
}