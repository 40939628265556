import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_EVENTS: `${API_HOST}admin/events/all_events`,
  ADMIN_ADD_EVENT: `${API_HOST}admin/events/add_event`,
  ADMIN_EDIT_EVENT: `${API_HOST}admin/events/edit_event`,
  ADMIN_DELETE_EVENT: `${API_HOST}admin/events/delete_event`,
  // ADMIN PARTICIPANTS
  ADMIN_EVENT_PARTICIPANTS: `${API_HOST}admin/events/all_participants`,
  ADMIN_EVENT_ADD_PARTICIPANT: `${API_HOST}admin/events/add_participant`,
  ADMIN_EVENT_EDIT_PARTICIPANT: `${API_HOST}admin/events/edit_participant`,
  ADMIN_EVENT_DELETE_PARTICIPANT: `${API_HOST}admin/events/delete_participant`
}
