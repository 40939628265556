// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { zoomCredentails } from '../../../APIRoutes'
import axiosRequest from '../../../APIRoutes/axiosCall'

export const setZoomCred = createAsyncThunk('zoomCredential/setZoomCred', (data) => {
  return { data }
})

export const getZoomCred = createAsyncThunk('zoomCredential/getZoomCred', async () => {
  const response = await axiosRequest.get(zoomCredentails.ZOOM_CRED)
  return { data: response?.data?.data }
})


export const zoomSlice = createSlice({
  name: 'zoomCredential',
  initialState: {
    data: [],
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setZoomCred.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getZoomCred.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getZoomCred.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getZoomCred.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
  }
})

export default zoomSlice.reducer
