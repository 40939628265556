// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminAppointments = createAsyncThunk('adminMeetings/getAdminAppointments', async () => {
  const response = await axiosRequest.get(meeting.ADMIN_APPOINTMENTS)
  return { data: response?.data?.data }
})

export const setAdminAppointments = createAsyncThunk('adminMeetings/setAdminAppointments', async (data) => {
  return { data }
})

export const createAdminAppointment = createAsyncThunk('adminMeetings/createAdminAppointment', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_ADD_APPOINTMENT, { ...data })
  if (response?.data?.status) {
    dispatch(getAdminAppointments())
  }
  return { data: response?.data?.data ?? {}, status: response?.data?.status }
})

export const updateAdminAppointment = createAsyncThunk('adminMeetings/updateAdminAppointment', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_EDIT_APPOINTMENT, data)
  // console.log("appoinment updated", response.data)
  // if (response?.data?.status) {
  //   dispatch(getAdminAppointments())
  // }
  return { status: response?.data?.status }
})

export const deleteAdminAppointment = createAsyncThunk('adminMeetings/deleteAdminAppointment', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_DELETE_APPOINTMENT, { id })
  return { id, status: response.data?.status }
})
// CLASSES
export const getAdminClasses = createAsyncThunk('adminMeetings/getAdminClasses', async () => {
  const response = await axiosRequest.get(meeting.ADMIN_MEETING_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAdminClasses = createAsyncThunk('adminMeetings/setAdminClasses', async (data) => {
  return { data }
})

export const addAdminClass = createAsyncThunk('adminMeetings/addAdminClass', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_ADD_CLASS, data)
  if (response?.data?.status) {
    dispatch(getAdminClasses())
  }
  return { data: response?.data?.data ?? {}, status: response.data?.status }
})

export const updateAdminClass = createAsyncThunk('adminMeetings/updateAdminClass', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_EDIT_CLASS, data)
  return { data, status: response.data?.status }
})

export const deleteAdminClass = createAsyncThunk('adminMeetings/deleteAdminClass', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_DELETE_CLASS, { id })
  return { id, status: response?.data?.status }
})

export const getAdminClassDetail = createAsyncThunk('adminMeetings/getAdminClassDetail', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_DETAIL, { id })
  return { data, status: response.data?.status }
})

export const sendAdminClassInvitation = createAsyncThunk('adminMeetings/sendAdminClassInvitation', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_SEND_INVITATION, data)
  return { data, status: response.data?.status }
})

// MEETINGS
export const getAdminMeetings = createAsyncThunk('adminMeetings/getAdminMeetings', async () => {
  const response = await axiosRequest.get(meeting.ADMIN_MEETINGS)
  return { data: response?.data?.data ?? [] }
})

export const setAdminMeetings = createAsyncThunk('adminMeetings/setAdminMeetings', async (data) => {
  return { data }
})

export const addAdminMeeting = createAsyncThunk('adminMeetings/addAdminMeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_ADD_MEETING, data)
  if (response?.data?.status) {
    dispatch(getAdminMeetings())
  }
  return { data: response?.data?.data ?? {}, status: response.data?.status, message: response.data?.message }
})

export const updateAdminMeeting = createAsyncThunk('adminMeetings/updateAdminMeeting', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_EDIT_MEETING, data)
  return { data, status: response.data?.status }
})

export const deleteAdminMeeting = createAsyncThunk('adminMeetings/deleteAdminMeeting', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_DELETE_MEETING, { id })
  return { id, status: response?.data?.status }
})


export const adminMeetingsSlice = createSlice({
  name: 'adminMeetings',
  initialState: {
    appointments: [],
    appointmentLoading: false,
    meetingClasses: [],
    classesLoader: false,
    classDetail: {},
    meetings: [],
    meetingsLoader: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminAppointments.fulfilled, (state, { payload }) => {
        state.appointments = payload?.data ?? []
        state.appointmentLoading = false
      })
      
      .addCase(setAdminAppointments.fulfilled, (state, { payload }) => {
        state.appointments = payload?.data ?? []
      })

      .addCase(getAdminAppointments.pending, (state) => {
        console.log("LOADING...")
        state.appointmentLoading = true
      })
      .addCase(getAdminAppointments.rejected, (state) => {
        console.log("REJECTED...")
        state.appointmentLoading = false
      })
      .addCase(createAdminAppointment.fulfilled, (state, { payload }) => {
        displaySuccessToast('Appointment has been Created Successfully')
        console.log(`ADDED APPOINTMENT DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(updateAdminAppointment.fulfilled, (state, { payload }) => {
        displaySuccessToast('Appointment has been Created Successfully')
        console.log('Updated APPOINTMENT DATA', payload?.status1234)
      })
      .addCase(deleteAdminAppointment.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.id}`)
      })
      // CLASSES
      .addCase(getAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
        state.classesLoader = false
      })
      .addCase(getAdminClasses.pending, (state) => {
        state.classesLoader = true
      })
      .addCase(getAdminClasses.rejected, (state) => {
        state.classesLoader = false
      })
      .addCase(setAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(addAdminClass.fulfilled, (state, { payload }) => {
        displaySuccessToast('Class has been Added Successfully')
        console.log(`ADDED CLASS DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminClass.fulfilled, (state, { payload }) => {
        console.log(`DELETED CLASS ID ${payload?.id}`)
      })
      .addCase(updateAdminClass.fulfilled, (state, { payload }) => {
        displaySuccessToast('Class has been Updated Successfully')
        console.log(`UPDATED CLASS ${JSON.stringify(payload?.data)}`)
      })
      .addCase(getAdminClassDetail.fulfilled, (state, { payload }) => {
        state.classDetail = payload?.data ?? []
      })
      .addCase(sendAdminClassInvitation.fulfilled, (state, { payload }) => {
        console.log(`CLASS INVITATION SENT ${payload?.status}`)
      })
      // MEETINGS
      .addCase(getAdminMeetings.fulfilled, (state, { payload }) => {
        state.meetings = payload?.data ?? []
        state.meetingsLoader = false
      })
      .addCase(getAdminMeetings.pending, (state) => {
        state.meetingsLoader = true
      })
      .addCase(getAdminMeetings.rejected, (state) => {
        state.meetingsLoader = false
      })
      .addCase(setAdminMeetings.fulfilled, (state, { payload }) => {
        state.meetings = payload?.data ?? []
      })
      .addCase(addAdminMeeting.fulfilled, (state, { payload }) => {
        // displaySuccessToast('Live Meeting has been Created Successfully')
        console.log(`ADDED MEETING DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`DELETED MEETING ID ${payload?.id}`)
      })
      .addCase(updateAdminMeeting.fulfilled, (state, { payload }) => {
        displaySuccessToast('Live Meeting has been Updated Successfully')
        console.log(`UPDATED MEETING ${JSON.stringify(payload?.data)}`)
      })
  }
})

export default adminMeetingsSlice.reducer
