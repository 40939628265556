import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_NOTIFICATION_SETTING: `${API_HOST}admin/notification/notification_setting`,
  ADMIN_NOTIFICATION_UPDATE_SETTING: `${API_HOST}admin/notification/notification_update`,
  ADMIN_NOTIFICATION_SMS_TEMPLATES: `${API_HOST}admin/sms_setting/sms_templates`,
  ADMIN_NOTIFICATION_EMAIL_TEMPLATES: `${API_HOST}admin/email_setting/email_templates`,

  // FRONT Notifications
  FORNT_ALL_NOTIFICATION: `${API_HOST}front/Notification/all_notifications/all`,
  FORNT_Latest_NOTIFICATION: `${API_HOST}front/Notification/all_notifications/0/4`,
  FORNT_ALL_READ: `${API_HOST}front/Notification/read_all`,
  FORNT_NOTIFICATION_TOKEN: `${API_HOST}front/Notification/firebase_token`,
  FORNT_READ_ALL_CHAT_NOTIFICATION: `${API_HOST}front/notification/read_chat_notification`,
  FORNT_READ_SINGLE_NOTIFICATION: `${API_HOST}front/notification/read_notification`,
  FORNT_DELETE_SINGLE_NOTIFICATION: `${API_HOST}front/notification/delete`,
  FORNT_DELETE_ALL_NOTIFICATION: `${API_HOST}front/notification/delete_all`,
  FORNT_UPDATE_ALL_NOTIFICATION: `${API_HOST}front/notification/update_all_notification_setting`
}
