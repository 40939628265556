// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { recommendation } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displayErrorToast, displaySuccessToast } from '../../../../utility/helper'

export const getRecommendations = createAsyncThunk('recommendations/getRecommendations', async () => {
  const response = await axiosRequest.get(recommendation.ALL_RECOMMENDATIONS)
  return { data: response?.data?.data ?? [] }
})
export const updateRecommendations = createAsyncThunk('recommendations/updateRecommendations', async (data, { dispatch }) => {
  const response = await axiosRequest.post(recommendation.UPDATE_RECOMMENDATIONS, data)
  if (response?.data?.status) {
    dispatch(getRecommendations())
  }
  return { status: response?.data?.status }
})

export const getRecommendedSearchData = createAsyncThunk('recommendations/getRecommendedSearchData', async (data, { dispatch }) => {
  const response = await axiosRequest.post(recommendation.SEARCH_RECOMMENDATIONS, data)
  if (data.query === '') {
    dispatch(getRecommendations())
  }
  return { data: response?.data?.data ?? [] }
})

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState: {
    recommendationClasses: [],
    recommendationContent: [],
    recLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRecommendations.fulfilled, (state, { payload }) => {
        state.recommendationClasses = payload?.data?.classes
        state.recommendationContent = payload?.data?.contents
        state.recLoading = false
      })
      .addCase(getRecommendations.pending, (state) => {
        state.recLoading = true
      })
      .addCase(getRecommendations.rejected, (state) => {
        state.recLoading = false
      })
      .addCase(updateRecommendations.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASSES DATA ${payload?.status}`)
        if (payload?.status) {
          displaySuccessToast('Recommendation has been updated Successfully')
        } else {
          displayErrorToast('Sorry Recommendation has been not updated')
        }
      })
      .addCase(getRecommendedSearchData.fulfilled, (state, { payload }) => {
        state.recommendationClasses = payload?.data?.classes
        state.recommendationContent = payload?.data?.contents
      })
  }
})

export default recommendationSlice.reducer
