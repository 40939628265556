import { API_HOST } from "./config"
export default {
  CHAT_CONTACTS: `${API_HOST}front/video/chat_users_connected`,
  CHAT_ALL_CONTACTS: `${API_HOST}front/video/chat_users_all`,
  ADMIN_CHAT_CONTACTS: `${API_HOST}admin/messages/get_chat`,
  ADMIN_CONTACTS: `${API_HOST}admin/messages/get_users`,
  CHAT_HISTORY: `${API_HOST}front/video/chat_history`,
  SEND_MESSAGE: `${API_HOST}front/video/send_chat`,
  GET_ADMIN_ID: `${API_HOST}front/video/get_admin_id`,
  GET_RECENT_CHAT: `${API_HOST}front/video/recent_chats`,

  ACCEPT_CONNECTION_REQUEST: `${API_HOST}front/video/accept_connection_req`,
  SEND_CONNECTION_REQUEST: `${API_HOST}front/video/send_connection_req`,
  DELETE_CONNECTION_REQUEST: `${API_HOST}front/video/delete_connection_req`,
  ALL_CONNECTION_REQUEST: `${API_HOST}front/video/all_connection_req`
}
