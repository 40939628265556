// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sections } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

export const getAdminSections = createAsyncThunk('adminSections/getAdminSections', async (params) => {
  const response = await axiosRequest.get(`${sections.ADMIN_SECTIONS}?pageNo=${params?.page}&perPage=${params?.perPage}&searchBy=${params?.searchBy}`)
  return { data: response?.data?.data, params }
})

export const setAdminSections = createAsyncThunk('adminSections/setAdminSections', async (data) => {
  return { data }
})

export const createAdminSection = createAsyncThunk('adminSections/createAdminSection', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_ADD_SECTION, data)
  if (response?.data?.status) {
    dispatch(getAdminSections())
  }
  return { status: response?.data?.status }
})

export const updateAdminSection = createAsyncThunk('adminSections/updateAdminSection', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_EDIT_SECTION, data)
  if (response?.data?.status) {
    dispatch(getAdminSections())
  }
  return { status: response?.data?.status }
})

export const deleteAdminSection = createAsyncThunk('adminSections/deleteAdminSection', async (id) => {
  const response = await axiosRequest.post(sections.ADMIN_DELETE_SECTION, { id })
  return { id, status: response.data?.status }
})

// SECTION CONTENTS
export const getAdminSectionContents = createAsyncThunk('adminSections/getAdminSectionContents', async (section_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CONTENTS, { section_id })
  return { data: response?.data?.data }
})

export const setAdminSectionContent = createAsyncThunk('adminSections/setAdminSectionContent', async (data) => {
  return { data }
})

export const createAdminSectionContent = createAsyncThunk('adminSections/createAdminSectionContent', async (data, { dispatch }) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionContents())
  }
  return { status: response?.data?.status }
})

export const updateAdminSectionContent = createAsyncThunk('adminSections/updateAdminSectionContent', async ({ data, section_id }, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionContents(section_id))
  }
  return { status: response?.data?.status }
})

export const deleteAdminSectionContent = createAsyncThunk('adminSections/deleteAdminSectionContent', async (id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_DELETE_CONTENT, { id })
  return { id, status: response.data?.status }
})

// SECTION CATEGORIES
export const getAdminSectionCategories = createAsyncThunk('adminSections/getAdminSectionCategories', async (section_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CATEGORIES, { section_id })
  return { data: response?.data?.data }
})

export const getAdminSectionAllCategories = createAsyncThunk('adminSections/getAdminSectionAllCategories', async (section_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CATEGORIES, { section_id })
  return { data: response?.data?.data }
})

export const setAdminSectionCategories = createAsyncThunk('adminSections/setAdminSectionCategories', async (data) => {
  return { data }
})
export const setAdminSectionAllCategories = createAsyncThunk('adminSections/setAdminSectionAllCategories', async (data) => {
  return { data }
})

export const createAdminSectionCategory = createAsyncThunk('adminSections/createAdminSectionCategory', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CATEGORY, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionCategories())
  }
  return { status: response?.data?.status }
})

export const updateAdminSectionCategory = createAsyncThunk('adminSections/updateAdminSectionCategory', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CATEGORY, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionCategories())
  }
  return { status: response?.data?.status }
})

export const deleteAdminSectionCategory = createAsyncThunk('adminSections/deleteAdminSectionCategory', async (id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_DELETE_CATEGORY, { id })
  return { id, status: response.data?.status }
})

// SECTION CATEGORY CONTENTS
export const getAdminSectionCategoryContent = createAsyncThunk('adminSections/getAdminSectionCategoryContent', async (cat_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CATEGORY_CONTENTS, { cat_id })
  return { data: response?.data?.data }
})
export const setAdminSectionCategoryContent = createAsyncThunk('adminSections/setAdminSectionCategoryContent', async (data) => {
  return { data }
})

export const createAdminSectionCategoryContent = createAsyncThunk('adminSections/createAdminSectionCategoryContent', async ({ data, cat_id }, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CATEGORY_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionCategoryContent(cat_id))
  }
  return { status: response?.data?.status }
})

export const updateAdminSectionCategoryContent = createAsyncThunk('adminSections/updateAdminSectionCategoryContent', async ({ data, cat_id }, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CATEGORY_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionCategoryContent(cat_id))
  }
  return { status: response?.data?.status }
})

// SECTION CLASSES
export const getAdminSectionClasses = createAsyncThunk('adminSections/getAdminSectionClasses', async (section_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CLASSES, { section_id })
  return { data: response?.data?.data }
})

export const setAdminSectionClasses = createAsyncThunk('adminSections/setAdminSectionClasses', async (data) => {
  return { data }
})

export const createAdminSectionClass = createAsyncThunk('adminSections/createAdminSectionClass', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CLASS, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionClasses())
  }
  return { status: response?.data?.status }
})

export const updateAdminSectionClass = createAsyncThunk('adminSections/updateAdminSectionClass', async ({ data, section_id }, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CLASS, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionClasses(section_id))
  }
  return { status: response?.data?.status }
})

export const deleteAdminSectionClass = createAsyncThunk('adminSections/deleteAdminSectionClass', async (id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_DELETE_CLASS, { id })
  return { id, status: response.data?.status }
})

// SECTION CATEGORY CONTENTS
export const getAdminSectionClassContent = createAsyncThunk('adminSections/getAdminSectionClassContent', async (class_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CLASS_CONTENTS, { class_id })
  return { data: response?.data?.data }
})
export const setAdminSectionClassContent = createAsyncThunk('adminSections/setAdminSectionClassContent', async (data) => {
  return { data }
})

export const createAdminSectionClassContent = createAsyncThunk('adminSections/createAdminSectionClassContent', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CLASS_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionCategoryContent())
  }
  return { status: response?.data?.status }
})

export const updateAdminSectionClassContent = createAsyncThunk('adminSections/updateAdminSectionClassContent', async ({ data, class_id }, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CLASS_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminSectionClassContent(class_id))
  }
  return { status: response?.data?.status }
})
// CLASS LESSONS
export const getAdminClassLessons = createAsyncThunk('adminSections/getAdminClassLessons', async (class_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CLASS_LESSONS, { class_id })
  return { data: response?.data?.data }
})

export const setAdminClassLessons = createAsyncThunk('adminSections/setAdminClassLessons', async (data) => {
  return { data }
})

export const createAdminClassLesson = createAsyncThunk('adminSections/createAdminClassLesson', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CLASS_LESSON, { course_id: data.course_id, title: data.title })
  if (response?.data?.status) {
    dispatch(getAdminClassLessons(data.course_id))
  }
  return { status: response?.data?.status }
})

export const updateAdminClassLesson = createAsyncThunk('adminSections/updateAdminClassLesson', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CLASS_LESSON, data)
  if (response?.data?.status) {
    dispatch(getAdminClassLessons())
  }
  return { status: response?.data?.status }
})

export const deleteAdminClassLesson = createAsyncThunk('adminSections/deleteAdminClassLesson', async (id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_DELETE_CLASS_LESSON, { id })
  return { id, status: response.data?.status }
})

// CLASS LESSON CONTENTS
export const getAdminClassLessonContents = createAsyncThunk('adminSections/getAdminClassLessonContents', async (lesson_id) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CLASS_LESSON_CONTENTS, { lesson_id })
  return { data: response?.data?.data }
})
export const setAdminClassLessonContent = createAsyncThunk('adminSections/setAdminClassLessonContent', async (data) => {
  return { data }
})

export const createAdminClassLessonContent = createAsyncThunk('adminSections/createAdminClassLessonContent', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_ADD_CLASS_LESSON_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminClassLessonContents())
  }
  return { status: response?.data?.status }
})

export const updateAdminClassLessonContent = createAsyncThunk('adminSections/updateAdminClassLessonContent', async (data, { dispatch }) => {

  const response = await axiosRequest.post(sections.ADMIN_SECTION_EDIT_CLASS_LESSON_CONTENT, data)
  if (response?.data?.status) {
    dispatch(getAdminClassLessonContents())
  }
  return { status: response?.data?.status }
})

export const setSubmitLoading = createAsyncThunk('adminSections/setSubmitLoading', async (status) => {
  return { status }
})

export const updateAdminContentReOrder = createAsyncThunk('adminSections/updateAdminContentReOrder', async ({ content, data }, { dispatch }) => {
  const response = await axiosRequest.post(sections.ADMIN_SECTION_CONTENT_REORDER, { content })
  if (response?.data?.status) {
    if (data?.type === 'section') {
      dispatch(getAdminSectionContents(data?.id))
    } else if (data?.type === 'categories') {
      dispatch(getAdminSectionCategoryContent(data?.id))
    } else if (data?.type === 'classes') {
      dispatch(getAdminSectionClassContent(data?.id))
    } else if (data?.type === 'lessons') {
      dispatch(getAdminClassLessonContents(data?.id))
    }
  }
  return { status: response?.status }
})

export const adminSectionsSlice = createSlice({
  name: 'adminSections',
  initialState: {
    data: [],
    loading: true,
    contentsData: [],
    contentLoading: true,
    sectionCategories: [],
    sectionAllCategories: [],
    categoriesLoading: true,
    sectionCategoryContent: [],
    sectionClasses: [],
    classesLoading: true,
    sectionClassContent: [],
    classLessons: [],
    lessonsLoading: true,
    classLessonContent: [],
    taskDone: true,
    submitLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setSubmitLoading.fulfilled, (state, { payload }) => {
        state.submitLoading = payload?.status ?? false
      })
      .addCase(getAdminSections.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.data = payload?.data ?? []
        state.loading = false
        state.params = payload?.params
        state.totalSections = payload?.data?.length
      })
      .addCase(getAdminSections.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminSections.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminSections.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(createAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section has been Added Successfully')
        console.log(`CREATED STATUS ${payload?.status}`)
        state.status = payload?.status ?? []

      })
      .addCase(updateAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section has been Updated Successfully')
        console.log(`UPDATED DATA ${payload?.status}`)

      })
      .addCase(deleteAdminSection.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section has been Deleted Successfully')
        console.log(`DELETED DATA ID ${payload?.id}`)
      })

      // SECTION CONTENTS
      .addCase(getAdminSectionContents.fulfilled, (state, { payload }) => {
        console.log("SECTION CONTENT FULFILLED...")
        state.contentsData = payload?.data ?? []
        state.contentLoading = false
      })
      .addCase(getAdminSectionContents.pending, (state) => {
        console.log("SECTION CONTENT LOADING...")
        state.contentLoading = true
      })
      .addCase(getAdminSectionContents.rejected, (state) => {
        console.log("SECTION CONTENT REJECTED...")
        state.contentLoading = false
      })
      .addCase(setAdminSectionContent.fulfilled, (state, { payload }) => {
        state.contentsData = payload?.data ?? []
      })
      .addCase(createAdminSectionContent.fulfilled, (state, { payload }) => {
        state.contentLoading = false
        state.taskDone = false

        displaySuccessToast('Admin Section Content has been Added Successfully')
        console.log(`CREATED CONTENT STATUS ${payload?.status}`)
      })
      .addCase(updateAdminSectionContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Content has been Updated Successfully')
        console.log(`UPDATED CONTENT DATA ${payload?.status}`)

      })

      .addCase(deleteAdminSectionContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Content has been Deleted Successfully')
        console.log(`DELETED CONTENT DATA ID ${payload?.id}`)
      })

      // SECTION CATEGORIES
      .addCase(getAdminSectionCategories.fulfilled, (state, { payload }) => {
        console.log("SECTION CATEGORY FULFILLED...")
        state.sectionCategories = payload?.data ?? []
        state.categoriesLoading = false
      })
      .addCase(getAdminSectionAllCategories.fulfilled, (state, { payload }) => {
        state.sectionAllCategories = payload?.data ?? []
      })
      .addCase(getAdminSectionCategories.pending, (state) => {
        console.log("SECTION CATEGORY LOADING...")
        state.categoriesLoading = true
      })
      .addCase(getAdminSectionCategories.rejected, (state) => {
        console.log("SECTION CATEGORY REJECTED...")
        state.categoriesLoading = false
      })
      .addCase(setAdminSectionCategories.fulfilled, (state, { payload }) => {
        state.sectionCategories = payload?.data ?? []
      })
      .addCase(setAdminSectionAllCategories.fulfilled, (state, { payload }) => {
        state.sectionAllCategories = payload?.data ?? []
      })
      .addCase(createAdminSectionCategory.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Category has been Added Successfully')
        console.log(`CREATED CATEGORY STATUS ${payload?.status}`)

      })
      .addCase(updateAdminSectionCategory.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Category has been Updated Successfully')
        console.log(`UPDATED CATEGORY DATA ${payload?.status}`)

      })
      .addCase(deleteAdminSectionCategory.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Category has been Deleted Successfully')
        console.log(`DELETED CATEGORY DATA ID ${payload?.id}`)
      })
      // SECTION CATEGORY CONTENTS
      .addCase(getAdminSectionCategoryContent.fulfilled, (state, { payload }) => {
        state.sectionCategoryContent = payload?.data ?? []
      })
      .addCase(setAdminSectionCategoryContent.fulfilled, (state, { payload }) => {
        state.sectionCategories = payload?.data ?? []
      })
      .addCase(createAdminSectionCategoryContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Category Content has been Added Successfully')
        console.log(`CREATED CATEGORY CONTENT - STATUS ${payload?.status}`)

      })
      .addCase(updateAdminSectionCategoryContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Category Content has been Updated Successfully')
        console.log(`UPDATED CATEGORY CONTENT DATA ${payload?.status}`)

      })

      // SECTION CLASSES
      .addCase(getAdminSectionClasses.fulfilled, (state, { payload }) => {
        console.log("SECTION CLASSES FULFILLED...")
        state.sectionClasses = payload?.data ?? []
        state.classesLoading = false
      })
      .addCase(getAdminSectionClasses.pending, (state) => {
        console.log("SECTION CLASSES LOADING...")
        state.classesLoading = true
      })
      .addCase(getAdminSectionClasses.rejected, (state) => {
        console.log("SECTION CLASSES REJECTED...")
        state.classesLoading = false
      })
      .addCase(setAdminSectionClasses.fulfilled, (state, { payload }) => {
        state.sectionClasses = payload?.data ?? []
      })
      .addCase(createAdminSectionClass.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Class has been Added Successfully')
        state.taskDone = false
        console.log(`CREATED CLASSES STATUS ${payload?.status}`)

      })
      .addCase(updateAdminSectionClass.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Class has been Updated Successfully')
        console.log(`UPDATED CLASSES DATA ${payload?.status}`)

      })
      .addCase(deleteAdminSectionClass.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Class has been Deleted Successfully')
        console.log(`DELETED CLASSES DATA ID ${payload?.id}`)
      })
      // SECTION CATEGORY CONTENTS
      .addCase(getAdminSectionClassContent.fulfilled, (state, { payload }) => {
        state.sectionClassContent = payload?.data ?? []
      })
      .addCase(setAdminSectionClassContent.fulfilled, (state, { payload }) => {
        state.sectionClassContent = payload?.data ?? []
      })
      .addCase(createAdminSectionClassContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Class Content has been Added Successfully')
        console.log(`CREATED CLASSES CONTENT - STATUS ${payload?.status}`)

      })
      .addCase(updateAdminSectionClassContent.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Section Class Content has been Updated Successfully')
        console.log(`UPDATED CLASSES CONTENT DATA ${payload?.status}`)

      })
      // CLASS LESSONS
      .addCase(getAdminClassLessons.fulfilled, (state, { payload }) => {
        console.log("SECTION CLASSES FULFILLED...")
        state.classLessons = payload?.data ?? []
        state.lessonsLoading = false
      })
      .addCase(getAdminClassLessons.pending, (state) => {
        console.log("SECTION CLASSES LOADING...")
        state.lessonsLoading = true
      })
      .addCase(getAdminClassLessons.rejected, (state) => {
        console.log("SECTION CLASSES REJECTED...")
        state.lessonsLoading = false
      })
      .addCase(setAdminClassLessons.fulfilled, (state, { payload }) => {
        state.classLessons = payload?.data ?? []
      })
      .addCase(createAdminClassLesson.fulfilled, (state, { payload }) => {
        console.log(`CREATED CLASSES STATUS ${payload?.status}`)
        state.taskDone = false
        displaySuccessToast('Admin Section Class Lesson has been Added Successfully')

      })
      .addCase(updateAdminClassLesson.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASSES DATA ${payload?.status}`)
        displaySuccessToast('Admin Class Lesson has been Updated Successfully')

      })
      .addCase(deleteAdminClassLesson.fulfilled, (state, { payload }) => {
        displaySuccessToast('Admin Class Lesson has been Deleted Successfully')
        console.log(`DELETED CLASSES DATA ID ${payload?.id}`)
      })
      // CLASS LESSONS CONTENTS
      .addCase(getAdminClassLessonContents.fulfilled, (state, { payload }) => {
        state.classLessonContent = payload?.data ?? []
      })
      .addCase(setAdminClassLessonContent.fulfilled, (state, { payload }) => {
        state.classLessonContent = payload?.data ?? []
      })
      .addCase(createAdminClassLessonContent.fulfilled, (state, { payload }) => {
        console.log(`CREATED CLASSES CONTENT - STATUS ${payload?.status}`)

      })
      .addCase(updateAdminClassLessonContent.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASSES CONTENT DATA ${payload?.status}`)

      })
      // update Admin Content ReOrder
      .addCase(updateAdminContentReOrder.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASSES CONTENT DATA ${payload?.status}`)
        displaySuccessToast('Reorder Successfully')
      })
  }
})

export default adminSectionsSlice.reducer
