import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { sms_config } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

export const getAdminConfigSMSAuth = createAsyncThunk('adminSmsConfigs/getAdminConfigSMSAuth', async () => {
  const response = await axiosRequest.get(sms_config.ADMIN_SMS_CONFIG_SMS_AUTH)
  return { data: response?.data?.data }
})
export const updateAdminConfigSmsAuth = createAsyncThunk('adminSmsConfigs/updateAdminConfigSmsAuth', async (data) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_UPDATE_SMS_AUTH, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const getAdminConfigSMSNumbers = createAsyncThunk('adminSmsConfigs/getAdminConfigSMSNumbers', async () => {
  const response = await axiosRequest.get(sms_config.ADMIN_SMS_CONFIG_NUMBERS_LIST)
  return { data: response?.data?.data }
})

export const getSchedulednotificaion = createAsyncThunk('adminSmsConfigs/getSchedulednotificaion', async () => {
  const response = await axiosRequest.post(sms_config.ADMIN_SCHEDULED_NOTIFICATION)
  return { data: response?.data?.data }
})

export const deleteSchedulednotificaion = createAsyncThunk('adminSmsConfigs/deleteSchedulednotificaion', async (id) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SCHEDULED_NOTIFICATION_DELETE,  id)
  return { id, status: response.data?.status }
})

export const getPushNotifications = createAsyncThunk('adminSmsConfigs/getPushNotifications', async () => {
  const response = await axiosRequest.get(sms_config.GET_PUSH_NOTIFICATION)
  return { data: response?.data?.data }
})

export const getPushNotificationsData = createAsyncThunk('adminSmsConfigs/getPushNotificationsData', async () => {
  const response = await axiosRequest.get(sms_config.GET_PUSH_NOTIFICATION_DATA)
  return { data: response?.data?.data }
})

export const addPushNotification = createAsyncThunk('adminSmsConfigs/addPushNotification', async (data) => {
  const response = await axiosRequest.post(sms_config.ADD_PUSH_NOTIFICATION_DATA, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const deletePushNotification = createAsyncThunk('adminSmsConfigs/deletePushNotification', async (data) => {
  const response = await axiosRequest.post(sms_config.DELETE_PUSH_NOTIFICATION_DATA, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const updateHooks = createAsyncThunk('adminSmsConfigs/updateHooks', async (data) => {
  const response = await axiosRequest.post(sms_config.UPDATE_HOOKS, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const editPushNotification = createAsyncThunk('adminSmsConfigs/editPushNotification', async (data) => {
  const response = await axiosRequest.post(sms_config.EDIT_PUSH_NOTIFICATION_DATA, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

export const setAdminConfigSMSNumbers = createAsyncThunk('adminSmsConfigs/setAdminConfigSMSNumbers', async (data) => {
  return { data }
})

export const addAdminConfigSMSNumber = createAsyncThunk('adminSmsConfigs/addAdminConfigSMSNumber', async (data, { dispatch }) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_ADD_NUMBER, data)
  if (response?.data?.status) {
    dispatch(getAdminConfigSMSNumbers())
  }
  return { status: response?.data?.status, message: response?.data?.message }
})

export const updateAdminConfigSMSNumber = createAsyncThunk('adminSmsConfigs/updateAdminConfigSMSNumber', async (data) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_EDIT_NUMBER, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message }
})

export const deleteAdminConfigSMSNumber = createAsyncThunk('adminSmsConfigs/deleteAdminConfigSMSNumber', async (id) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_DELETE_NUMBER, { id })
  return { id, status: response.data?.status }
})

// SMS TEMPLATES
export const getAdminConfigSMSTemplates = createAsyncThunk('adminSmsConfigs/getAdminConfigSMSTemplates', async () => {
  const response = await axiosRequest.get(sms_config.ADMIN_SMS_CONFIG_SMS_TEMPLATES)
  return { data: response?.data?.data }
})

export const setAdminConfigSMSTemplates = createAsyncThunk('adminSmsConfigs/setAdminConfigSMSTemplates', async (data) => {
  return { data }
})

export const addAdminConfigSMSTemplate = createAsyncThunk('adminSmsConfigs/addAdminConfigSMSTemplate', async (data, { dispatch }) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_ADD_SMS_TEMPLATE, data)
  dispatch(getAdminConfigSMSTemplates())
  return { status: response?.data?.status }
})

export const updateAdminConfigSMSTemplate = createAsyncThunk('adminSmsConfigs/updateAdminConfigSMSTemplate', async (data) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_EDIT_SMS_TEMPLATE, data)
  return { status: response?.data?.status }
})

// SMS LOGS
export const getAdminConfigSMSLogs = createAsyncThunk('adminSmsConfigs/getAdminConfigSMSLogs', async (page) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_SMS_LOGS, page)
  console.log("==========================page========================", response?.data?.total_pages)
  return { data: response?.data?.data, totalPages: response?.data?.total_pages }
})
// PUSH LOGS
export const getAdminConfigPUSHLogs = createAsyncThunk('adminSmsConfigs/getAdminConfigPUSHLogs', async (page) => {
  const response = await axiosRequest.post(sms_config.ADMIN_PUSH_CONFIG_PUSH_LOGS, page)
  console.log("==========================pushlogs========================", response?.data?.total_pages)
  return { data: response?.data?.data, totalPages: response?.data?.total_pages }
})

export const setAdminConfigSMSLogs = createAsyncThunk('adminSmsConfigs/setAdminConfigSMSLogs', async (data) => {
  return { data }
})

// SEND SMS
export const adminConfigSendSMS = createAsyncThunk('adminSmsConfigs/adminConfigSendSMS', async (data) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_SEND_SMS, data)
  return { data: response?.data?.data, status: response?.data?.status }
})
export const adminConfigSendBulkSMS = createAsyncThunk('adminSmsConfigs/adminConfigSendBulkSMS', async (data) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_SEND_BULK_SMS, data)
  return { data: response?.data?.data, status: response?.data?.status }
})

export const getAdminConfigClinicUsers = createAsyncThunk('adminSmsConfigs/getAdminConfigClinicUsers', async (clinic_id) => {
  const response = await axiosRequest.post(sms_config.ADMIN_SMS_CONFIG_GET_CLINIC_USERS, { clinic_id })
  return { data: response?.data?.data }
})

export const adminSMSConfigsSlice = createSlice({
  name: 'adminSMSConfigs',
  initialState: {
    smsSidAuth: {},
    loading: true,
    data: [],
    templatesLoading: true,
    PushNotificationData: "",
    pushNotificationPayload: [],
    deletePushNotification: [],
    updateHooksData: "",
    addPushNotificationData: [],
    editPushNotificationData: [],
    smsTemplates: [],
    logsLoading: true,
    smsLogs: [],
    pushLogs:[],
    clinicUsers: [],
    smsSent: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminConfigSMSAuth.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.smsSidAuth = payload?.data ?? {}
      })
      .addCase(updateAdminConfigSmsAuth.fulfilled, (state, { payload }) => {
        // displaySuccessToast('Number has been Updated Successfully')
        console.log("UPDATED SMS AUTH CONFIG...", payload?.status)
      })
      .addCase(getAdminConfigSMSNumbers.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setAdminConfigSMSNumbers.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminConfigSMSNumbers.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminConfigSMSNumbers.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })

      .addCase(getSchedulednotificaion.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getSchedulednotificaion.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getSchedulednotificaion.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })

      .addCase(deleteSchedulednotificaion.fulfilled, (state, { payload }) => {
        console.log("FULFILLED...")
        state.deletePushNotification = payload?.data ?? []
        state.loading = false
      })
      
      .addCase(deleteSchedulednotificaion.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(deleteSchedulednotificaion.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })

    //
    .addCase(getPushNotifications.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.PushNotificationData = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(getPushNotifications.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(getPushNotifications.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })

    //
    .addCase(deletePushNotification.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.deletePushNotification = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(deletePushNotification.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(deletePushNotification.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })
    //
    .addCase(updateHooks.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.updateHooksData = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(updateHooks.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(updateHooks.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })
    //
    .addCase(getPushNotificationsData.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.pushNotificationPayload = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(getPushNotificationsData.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(getPushNotificationsData.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })
    //
    .addCase(addPushNotification.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.addPushNotificationData = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(addPushNotification.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(addPushNotification.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })//
    .addCase(editPushNotification.fulfilled, (state, { payload }) => {
      console.log("FULFILLED...")
      state.editPushNotificationData = payload?.data ?? []
      state.loading = false
    })
    
    .addCase(editPushNotification.pending, (state) => {
      console.log("LOADING...")
      state.loading = true
    })
    .addCase(editPushNotification.rejected, (state) => {
      console.log("REJECTED...")
      state.loading = false
    })
    //
      .addCase(deleteAdminConfigSMSNumber.fulfilled, (state, { payload }) => {
        console.log(`DELETED SMS NUMBER ID ${payload?.id}`)
      })
      .addCase(addAdminConfigSMSNumber.fulfilled, (state, { payload }) => {
        // displaySuccessToast('Number has been Added Successfully')
        console.log(`ADDED SMS NUMBER ${payload?.status}`)
      })
      .addCase(updateAdminConfigSMSNumber.fulfilled, (state, { payload }) => {
        // displaySuccessToast('Number has been Updated Successfully')
        console.log(`UPDATED SMS NUMBER ${payload?.status}`)
      })
      // EMAIL TEMPLATES
      .addCase(getAdminConfigSMSTemplates.fulfilled, (state, { payload }) => {
        console.log("TEMPLATES FULFILLED...")
        state.smsTemplates = payload?.data ?? []
        state.templatesLoading = false
      })
      .addCase(setAdminConfigSMSTemplates.fulfilled, (state, { payload }) => {
        state.smsTemplates = payload?.data ?? []
      })
      .addCase(getAdminConfigSMSTemplates.pending, (state) => {
        console.log("TEMPLATES LOADING...")
        state.templatesLoading = true
      })
      .addCase(getAdminConfigSMSTemplates.rejected, (state) => {
        console.log("TEMPLATES REJECTED...")
        state.templatesLoading = false
      })
      .addCase(addAdminConfigSMSTemplate.fulfilled, (state, { payload }) => {
        displaySuccessToast('SMS Template has been Added Successfully')
        console.log(`ADDED TEMPLATE ${payload?.status}`)
      })
      .addCase(updateAdminConfigSMSTemplate.fulfilled, (state, { payload }) => {
        displaySuccessToast('SMS Template has been Updated Successfully')
        console.log(`ADDED TEMPLATE ${payload?.status}`)
      })
      // EMAIL LOGS
      .addCase(getAdminConfigSMSLogs.fulfilled, (state, { payload }) => {
        console.log("EMAIL LOGS FULFILLED...")
        state.smsLogs = payload?.data ?? []
        state.totalPagesData = payload?.totalPages
        state.logsLoading = false
      })
      .addCase(setAdminConfigSMSLogs.fulfilled, (state, { payload }) => {
        state.smsLogs = payload?.data ?? []

      })
      .addCase(getAdminConfigSMSLogs.pending, (state) => {
        console.log("EMAIL LOGS LOADING...")
        state.logsLoading = true
      })
      .addCase(getAdminConfigSMSLogs.rejected, (state) => {
        console.log("EMAIL LOGS REJECTED...")
        state.logsLoading = false
      })
      // PUSH LOGS
      .addCase(getAdminConfigPUSHLogs.fulfilled, (state, { payload }) => {
        console.log("PUSH LOGS FULFILLED...")
        state.pushLogs = payload?.data ?? []
        state.totalPagesData = payload?.totalPages
        state.logsLoading = false
      })
      .addCase(getAdminConfigPUSHLogs.pending, (state) => {
        console.log("PUSH LOGS LOADING...")
        state.logsLoading = true
      })
      .addCase(getAdminConfigPUSHLogs.rejected, (state) => {
        console.log("PUSH LOGS REJECTED...")
        state.logsLoading = false
      })
      .addCase(adminConfigSendSMS.fulfilled, (state) => {
        console.log("SMS SENT...")
        state.smsSent = true
        displaySuccessToast('SMS has been sent')
      })
      .addCase(adminConfigSendBulkSMS.fulfilled, () => {
        console.log("BULK SMS SENT...")
        displaySuccessToast('SMS has been sent')
      })
      // CLINICS USERS
      .addCase(getAdminConfigClinicUsers.fulfilled, (state, { payload }) => {
        console.log("SMS CONFIG CLINIC USERS FULFILLED...")
        state.clinicUsers = payload?.data ?? []
      })
  }
})

export default adminSMSConfigsSlice.reducer
