import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { common } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const getIndividualFeatures = createAsyncThunk('individualFeatures/getIndividualFeatures', async (params) => {
  let url = common.GET_USER_FEATURES
  if (params?.type === 'clinic') {
    url = common.GET_PATIENT_FEATURES
  }
  const response = await axiosRequest.post(url, params)
  return { status: response.data?.status ?? false, data: response.data?.data ?? [] }
})

export const updateIndividualFeatures = createAsyncThunk('individualFeatures/updateIndividualFeatures', async ({ params, type }) => {
  let url = common.UPDATE_USER_FEATURES
  if (type === 'clinic') {
    url = common.UPDATE_PATIENT_FEATURES
  }
  console.log("===type===", type)
  console.log("===url===", url)
  const response = await axiosRequest.post(url, params)
  return { status: response.data?.status ?? false, message: response.data?.message ?? [] }
})

export const userSlice = createSlice({
  name: 'individualFeatures',
  initialState: {
    features: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getIndividualFeatures.fulfilled, (state, { payload }) => {
        state.features = payload?.data ?? []
      })
  }
})

export default userSlice.reducer
