//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

// Get All Patients Details
export const getClinicPatients = createAsyncThunk('clinicPatients/getClinicPatients', async () => {
  const response = await axiosRequest.get(meeting.CLINIC_ALL_PATIENTS)
  return { data: response?.data?.data ?? [] }
})

// SET ALL PATIENTS
export const setClinicPatients = createAsyncThunk('clinicPatients/setClinicPatients', async (data) => {
  return { data }
})

// Update Patients Details

export const updateClinicPatients = createAsyncThunk('clinicPatients/updateClinicPatients', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_PATIENTS, data)
  if (response?.data?.status) {
    dispatch(getClinicPatients())
  }
  return { status: response?.data?.status }
})


export const createPatientUser = createAsyncThunk('clinicPatients/createPatientUser', async (data) => {
  console.log("------data api", data)
  const response = await axiosRequest.post(meeting.CREATE_PATIENT_USER, data)
  console.log('===add admin response===', response.data)
  return { status: response?.data?.status, message: response?.data?.message }
})

// Get All Packages Details
export const getAllPackages = createAsyncThunk('clinicPatients/getAllPackages', async () => {
  const response = await axiosRequest.get(meeting.GET_ALL_PACKAGES)
  //console.log("----get response----", response?.data?.data)
  return { data: response?.data?.data }
})

// Update Patients Settings

export const updateClinicPatientSetting = createAsyncThunk('clinicPatients/updateClinicPatientSetting', async (data) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_PATIENTS_SETTINGS, data)
  return { status: response?.data?.status }
})

//
export const inviteUser = createAsyncThunk('clinicPatients/inviteUser', async (data) => {
  console.log("=====INVITE_USER", data)
  const response = await axiosRequest.post(meeting.INVITE_USER, data)
  return { data: response }
})

// Approve By User Patients

export const approveByUserPatient = createAsyncThunk('clinicPatients/approveByUserPatient', async (data) => {
  const response = await axiosRequest.post(meeting.APPROVED_BY_USER_PATIENTS, data)
  return { status: response?.data?.status, message: response?.data?.message }
})

// Approve By Admin Patients

export const approveByAdminPatient = createAsyncThunk('clinicPatients/approveByAdminPatient', async (data) => {
  const response = await axiosRequest.post(meeting.APPROVED_BY_ADMIN_PATIENTS, data)

  return { status: response?.data?.status, message: response?.data?.message }
})

// Delete Patient
export const deleteClinicPatient = createAsyncThunk('clinicPatients/deleteClinicPatient', async (data) => {
  const response = await axiosRequest.post(meeting.CLINIC_DELETE_PATIENTS, data)
  return { status: response?.data?.status }
})
// Get Chart Detail for Patient

export const getClinicChartData = createAsyncThunk('clinicPatients/getClinicChartData', async (userid) => {
  const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_CHART_DATA, userid)
  return { data: response?.data?.data ?? [] }
})
//
export const userDetailsPatient = createAsyncThunk('clinicPatients/userDetailsPatient', async (userid) => {
  console.log(".......id", { userid })
  const response = await axiosRequest.post(meeting.USER_DETAIL, { userid })
  return { data: response?.data?.data ?? [] }
})

// Get Patient Statistics 

export const getpatientStatistics = createAsyncThunk('clinicPatients/getpatientStatistics', async (userid) => {
  const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_STATISTICS, userid)
  return { data: response?.data?.data ?? [] }
})

// Get Patient Activity 

export const getpatientActivity = createAsyncThunk('clinicPatients/getpatientActivity', async (userid) => {
  const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_ACTIVITY, userid)
  return { data: response?.data?.data ?? [] }
})

export const sendPasswordbyEmail = createAsyncThunk('clinicPatients/sendPasswordbyEmail', async (userid) => {
  const response = await axiosRequest.post(meeting.CLINIC_FORGET_PASSWORD, { userid })
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? 'Email Sending Field'
  }
})

export const clinicPatientsSlice = createSlice({
  name: 'clinicPatients',
  initialState: {
    clinicAllPatients: null,
    patientLoading: false,
    getAllPackages: [],
    userDetails: [],
    patientuser: [],
    getPatientChartData: [],
    patientStatistics: [],
    ClientUserData: [],
    patientActivity: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getClinicPatients.pending, (state) => {
        state.patientLoading = true
      })
      .addCase(getClinicPatients.rejected, (state) => {
        state.patientLoading = false
      })
      .addCase(getClinicPatients.fulfilled, (state, { payload }) => {
        state.clinicAllPatients = payload?.data
        state.patientLoading = false
      })
      .addCase(setClinicPatients.fulfilled, (state, { payload }) => {
        console.log('---setClinicPatients----payload---', payload?.data)
        state.clinicAllPatients = payload?.data
      })
      .addCase(updateClinicPatients.fulfilled, () => {
        displaySuccessToast('Patient Updated Successfully')
      })
      // Get ALL Packages
      .addCase(getAllPackages.fulfilled, (state, { payload }) => {
        state.getAllPackages = payload?.data
      })
      // Update Patient Setting
      .addCase(updateClinicPatientSetting.fulfilled, (state, { payload }) => {
        console.log("====Update Patient Setting======", payload.status)
        displaySuccessToast('Patient Settings Updated Successfully')
      })
      .addCase(inviteUser.pending, (state) => {
        state.patientLoading = true
      })
      .addCase(inviteUser.rejected, (state) => {
        state.patientLoading = false
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        state.ClientUserData = payload?.data
        console.log("===========69======= payload?.data=========",  payload?.data)
        state.patientLoading = false
      })

      // Approve by User Patient 
      .addCase(approveByUserPatient.fulfilled, (state, { payload }) => {
        console.log("====Update Patient Setting======", payload.status)
      })
      
      // Approve by User Patient 
      .addCase(approveByAdminPatient.fulfilled, (state, { payload }) => {
        console.log("====Update Patient Setting======", payload.status)
      })
      // Delete Patient
      .addCase(deleteClinicPatient.fulfilled, (state, { payload }) => {
        console.log("====Update Patient Setting======", payload.status)
        displaySuccessToast('Patient Delete Updated Successfully')
      })
      .addCase(createPatientUser.fulfilled, (state, { payload }) => {
        state.patientuser = payload?.message
        console.log("----user", payload?.message)
        displaySuccessToast('Add New Patient Successfully')

      })
      // Upate Patients Chart Data
      .addCase(getClinicChartData.fulfilled, (state, { payload }) => {
        state.getPatientChartData = payload?.data
        console.log("==========getPatientChartData=========", state.getPatientChartData)
      })
      .addCase(userDetailsPatient.fulfilled, (state, { payload }) => {
        state.userDetails = payload?.data
        console.log("==========getPatientChartData=========", state.userDetails)
      })
      // Get Patient Statistics
      .addCase(getpatientStatistics.fulfilled, (state, { payload }) => {
        state.patientStatistics = payload?.data ?? ''
        console.log("====== Patients Statistics======", state?.patientStatistics)
      })
      // Get Patient Activity
      .addCase(getpatientActivity.fulfilled, (state, { payload }) => {
        state.patientActivity = payload?.data
        console.log("====== Patients Activity======", state?.patientActivity)
      })
      .addCase(sendPasswordbyEmail.fulfilled, (state, { payload }) => {
        displaySuccessToast(payload?.message)
      })
  }

})
export default clinicPatientsSlice.reducer