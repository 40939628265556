// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { tasks } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminTasks = createAsyncThunk('adminTasks/getAdminTasks', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_TASKS)
  return { data: response?.data?.data }
})

export const setTasks = createAsyncThunk('adminTasks/setTasks', async (data) => {
  return { data }
})

export const updateTask = createAsyncThunk('adminTasks/updateTask', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tasks.ADMIN_EDIT_TASK, data)
  console.log("task updated", response.data)
  dispatch(getAdminTasks())
  return { status: response?.data?.status }
})

export const deleteTask = createAsyncThunk('adminTasks/deleteTask', async (id) => {
  const response = await axiosRequest.post(tasks.ADMIN_DELETE_TASK, { id })
  return { id, status: response.data?.status }
})
// CATEGORIES
export const getTaskCategories = createAsyncThunk('adminTasks/getTaskCategories', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_TASKS_CATEGORIES)
  return { data: response?.data?.data ?? [] }
})

export const getParentCategories = createAsyncThunk('adminTasks/getParentCategories', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_TASKS_PARENT_CATEGORIES)
  return { data: response?.data?.data ?? [] }
})

export const setTaskCategories = createAsyncThunk('adminTasks/setTaskCategories', async (data) => {
  return { data }
})

export const addTaskCategory = createAsyncThunk('adminTasks/addTaskCategory', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tasks.ADMIN_TASKS_ADD_CATEGORY, { ...data })
  dispatch(getTaskCategories())
  return { data: response?.data?.data ?? {}, status: response.data?.status }
})

export const updateTaskCategory = createAsyncThunk('adminTasks/updateTaskCategory', async (data) => {
  const response = await axiosRequest.post(tasks.ADMIN_TASKS_EDIT_CATEGORY, { ...data })
  return { data, status: response.data?.status }
})

export const deleteTaskCategory = createAsyncThunk('adminTasks/deleteTaskCategory', async (id) => {
  const response = await axiosRequest.post(tasks.ADMIN_TASKS_DELETE_CATEGORY, { id })
  return { id, status: response?.data?.status }
})

export const getBetterSameIndividual = createAsyncThunk('adminTasks/getBetterSameIndividual', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_TASKS_BETTER_SAME_INDIVIDUAL)
  return {
    betterIndividual: response?.data?.Better_individual_task,
    sameIndividual: response?.data?.Same_individual_task
  }
})

export const getBetterSameCompletion = createAsyncThunk('adminTasks/getBetterSameCompletion', async () => {
  const response = await axiosRequest.get(tasks.ADMIN_TASKS_BETTER_SAME_COMPLETION)
  return {
    betterCompletion: response?.data?.Better_after_completion,
    sameCompletion: response?.data?.Same_after_completion
  }
})

export const adminTasksSlice = createSlice({
  name: 'adminTasks',
  initialState: {
    data: [],
    loading: true,
    categories: [],
    parentCategories: [],
    betterIndividual: '',
    sameIndividual: '',
    betterCompletion: '',
    sameCompletion: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminTasks.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminTasks.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        console.log(`DELETED TASK ID ${payload?.id}`)
      })
      .addCase(getTaskCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(getParentCategories.fulfilled, (state, { payload }) => {
        state.parentCategories = payload?.data ?? []
      })
      .addCase(setTaskCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(addTaskCategory.fulfilled, (state, { payload }) => {
        displaySuccessToast('Task has been Added Successfully')
        console.log(`ADDED CATEGORY DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteTaskCategory.fulfilled, (state, { payload }) => {
        console.log(`DELETED CATEGORY ID ${payload?.id}`)
      })
      .addCase(updateTaskCategory.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CATEGORY ${JSON.stringify(payload?.data)}`)
      })
      .addCase(getBetterSameIndividual.fulfilled, (state, { payload }) => {
        state.betterIndividual = payload?.betterIndividual
        state.sameIndividual = payload?.sameIndividual
      })
      .addCase(getBetterSameCompletion.fulfilled, (state, { payload }) => {
        state.betterCompletion = payload?.betterCompletion
        state.sameCompletion = payload?.sameCompletion
      })
      .addCase(updateTask.fulfilled, (state, { payload }) => {
        console.log('------updateTask------', payload?.status)
        displaySuccessToast('Task has been Updated Successfully')
      })
  }
})

export default adminTasksSlice.reducer
