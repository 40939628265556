// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { accounts } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

export const getFrontAccountsDetail = createAsyncThunk('frontAccounts/getFrontAccountsDetail', async () => {
  const response = await axiosRequest.get(accounts.FRONT_ACCONUTS_DETAILS)
  return { data: response?.data?.data }
})
//

export const updateLogs = createAsyncThunk('frontAccounts/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(accounts?.UPDATE_LOGS_Account, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})

// ** UPDATE ACCOUNTS DETAILS
export const updateFrontAccountDetails = createAsyncThunk('frontAccounts/updateFrontAccountDetails', async (data) => {
  const response = await axiosRequest.post(accounts.FRONT_ACCONUTS_UPDATE, data)
  return { status: response?.data?.status }
})
// ** UPDATE ACCOUNTS DETAILS
export const updateFrontUserImage = createAsyncThunk('frontAccounts/updateFrontUserImage', async (file, { dispatch }) => {
  const response = await axiosRequest.post(accounts.FRONT_ACCONUTS_IMAGE_UPDATE, file)
  if (response?.data?.status) {
    console.log('---------getFrontAccountsDetail----------')
    dispatch(getFrontAccountsDetail())
  }
  return { status: response?.data?.status }
})
// ** UPDATE PASSWORD
export const updateFrontUserPassword = createAsyncThunk('frontAccounts/updateFrontUserPassword', async (data) => {
  const response = await axiosRequest.post(accounts.FRONT_ACCONUTS_PASSWORD_UPDATE, data)
  return { status: response?.data }
})

// ** GET Notifications
export const getNotifications = createAsyncThunk('frontAccounts/getNotifications', async () => {
  const response = await axiosRequest.get(accounts.FRONT_ACCONUTS_NOTIFICATIONS)
  return { data: response?.data?.data }
})

export const getupdateallnotifications = createAsyncThunk('frontSections/getupdateallnotifications', async (user_notifications) => {
  const response = await axiosRequest.post(accounts.UPDATE_ALL_NOTIFICATIONS, user_notifications)
  return { data: response.data?.data ?? {} }
})

// ** UPDATE Notifications Settings
export const updateNotificationSettings = createAsyncThunk('frontAccounts/updateNotificationSettings', async (data) => {
  const response = await axiosRequest.post(accounts.FRONT_ACCONUTS_NOTIFICATIONS_UPDATE, data)
  return { status: response?.data?.msg }
})

// GET CURRENT SUBSCRIPTION PLAN
export const getCurrentSubscription = createAsyncThunk('frontAccounts/getCurrentSubscription', async () => {
  const response = await axiosRequest.get(accounts.FRONT_ACCONUTS_SUBSCRIPTON_PLAN)
  return { data: response?.data?.data }
})

// GET PACKAGES
export const getPackages = createAsyncThunk('frontAccounts/getPackages', async (data) => {
  const response = await axiosRequest.post(accounts.FRONT_PACKAGE_PLAN, data)
  return { data: response?.data?.data ?? {} }
})

export const upgraderPackagePlan = createAsyncThunk('frontAccounts/upgraderPackagePlan', async (data) => {
  const response = await axiosRequest.post(accounts.UPGRADE_PACKAGE_PLAN, data)
  return { status: response?.data?.status, message: response?.data?.message ?? '' }
})
export const cancelPackageSubscription = createAsyncThunk('frontAccounts/cancelPackageSubscription', async () => {
  const response = await axiosRequest.get(accounts.CANCEL_PACKAGE_SUBSRIPTION)
  return { status: response?.data?.status, message: response?.data?.message ?? '' }
})

export const accountsSlice = createSlice({
  name: 'frontAccounts',
  initialState: {
    data: [],
    loading: true,
    updateData: '',
    imageUpload: '',
    passUpdate: [],
    notificationData: [],
    subScription: {},
    packagesDetail: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFrontAccountsDetail.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getFrontAccountsDetail.pending, (state) => {
        console.log('LOADING...')
        state.loading = true
      })
      .addCase(getFrontAccountsDetail.rejected, (state) => {
        console.log('REJECTED...')
        state.loading = false
      })
      .addCase(updateFrontAccountDetails.fulfilled, (state, { payload }) => {
        state.updateData = payload?.status
        displaySuccessToast('Account Details has been Uploaded Successfully')
      })
      .addCase(updateFrontUserImage.fulfilled, (state, { payload }) => {
        displaySuccessToast('Image has been Uploaded Successfully')
        state.imageUpload = payload?.status
      })
      .addCase(updateFrontUserPassword.fulfilled, (state, { payload }) => {
        state.passUpdate = payload?.status
        console.log("==================================test 123", state.passUpdate)
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notificationData = payload?.data ?? []
        console.log('------Notification Data are Get Successfully', state.notificationData)
        state.loading = false
      })
      .addCase(getNotifications.pending, (state) => {
        console.log('LOADING...')
        state.loading = true
      })
      .addCase(getNotifications.rejected, (state) => {
        console.log('REJECTED...')
        state.loading = false
      })
      .addCase(updateNotificationSettings.fulfilled, (state, { payload }) => {
        // state.passUpdate = payload?.status
        console.log('---------notification Updated success------', payload?.status)
        // displaySuccessToast('Notification Setting has been Uploaded Successfully')
      })
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      // get current Subscription plan
      .addCase(getCurrentSubscription.fulfilled, (state, { payload }) => {
        state.subScription = payload?.data ?? {}
      })
      .addCase(getPackages.fulfilled, (state, { payload }) => {
        state.packagesDetail = payload?.data ?? {}
        console.log('---------packages Data success------', payload?.data)
      })
      .addCase(getupdateallnotifications.pending, (state) => {
        // Handle pending state here
        state.loading = true
      })
      .addCase(getupdateallnotifications.fulfilled, (state, { payload }) => {
        state.updateallnotifications = payload?.data ?? [] // Update your state accordingly
        state.loading = false
      })
      .addCase(getupdateallnotifications.rejected, (state, action) => {
        // Handle rejected state here
        console.error('Error in getupdateallnotifications:', action.error)
        state.loading = false
      })
  }
})

export default accountsSlice.reducer
