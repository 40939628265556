import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_FEATURES: `${API_HOST}admin/features/all_features`,
  ADMIN_EDIT_FEATURE: `${API_HOST}admin/features/edit_feature`,

  // CLINIC ROUTES
  CLINIC_FEATURES: `${API_HOST}clinics/features/get_features`,
  CLINIC_EDIT_FEATURE: `${API_HOST}clinics/features/update`,

  PATIENT_FEATURES: `${API_HOST}clinics/features/get_features`
}
