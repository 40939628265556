import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ratings } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const getReviews = createAsyncThunk('ratings/getReviews', async (data) => {
  const response = await axiosRequest.post(ratings.GET_REVIEWS, data)
  console.log('get rating in data store', data)
  return { data: response.data?.data ?? [] }
})

export const getAverageRating = createAsyncThunk('ratings/getAverageRating', async (data) => {
  const response = await axiosRequest.post(ratings.AVERAGE_RATINGS, data)
  return { data: response.data?.average_rating ?? 0 }
})

export const addRating = createAsyncThunk('ratings/addRating', async (data) => {
  const response = await axiosRequest.post(ratings.ADD_RATING, data)
  return { status: response.data?.status ?? false, message: response.data?.message }
})

export const addReview = createAsyncThunk('ratings/addReview', async (data, {dispatch}) => {
  const response = await axiosRequest.post(ratings.ADD_REVIEW, data)
  if (response?.data?.status) {
    dispatch(getReviews({data_id: data?.data_id, data_type: data?.data_type}))
  }
  return { status: response.data?.status ?? false, message: response.data?.message }
})

export const getClassAverageRating = createAsyncThunk('ratings/getClassAverageRating', async (data_id) => {
  const response = await axiosRequest.post(ratings.AVERAGE_RATINGS, { data_id, data_type: 'classes' })
  return { data: response.data?.average_rating ?? 0 }
})
export const getClassReviews = createAsyncThunk('ratings/getClassReviews', async (data_id) => {
  const response = await axiosRequest.post(ratings.GET_REVIEWS, { data_id, data_type: 'classes' })
  return { data: response.data?.data ?? [] }
})

export const getLessonAverageRating = createAsyncThunk('ratings/getLessonAverageRating', async (id) => {
  const response = await axiosRequest.post(ratings.AVERAGE_RATINGS, { data_id: id, data_type: 'lessons' })
  return { data: response.data?.average_rating ?? 0 }
})
export const getLessonReviews = createAsyncThunk('ratings/getLessonReviews', async (data_id) => {
  const response = await axiosRequest.post(ratings.GET_REVIEWS, { data_id, data_type: 'lessons' })
  return { data: response.data?.data ?? [] }
})

export const ratingSlice = createSlice({
  name: 'ratings',
  initialState: {
    reviews: [],
    averageRating: 0,
    classAverageRating: 0,
    classReviews: [],
    lessonAverageRating: 0,
    lessonReviews: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getReviews.fulfilled, (state, { payload }) => {
        state.reviews = payload.data
      })
      .addCase(getAverageRating.fulfilled, (state, { payload }) => {
        state.averageRating = payload.data
      })
      .addCase(getClassAverageRating.fulfilled, (state, { payload }) => {
        state.classAverageRating = payload.data
      })
      .addCase(getClassReviews.fulfilled, (state, { payload }) => {
        state.classReviews = payload.data
      })
      .addCase(getLessonAverageRating.fulfilled, (state, { payload }) => {
        state.lessonAverageRating = payload.data
      })
      .addCase(getLessonReviews.fulfilled, (state, { payload }) => {
        state.lessonReviews = payload.data
      })

      .addCase(addRating.fulfilled, (state, { payload }) => {
        console.log('RATING ADDED', payload?.status)
      })
      .addCase(addReview.fulfilled, (state, { payload }) => {
        console.log('REVIEW ADDED', payload?.status)
      })
  }
})

export default ratingSlice.reducer
