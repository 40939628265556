import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { notifications } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getNotificationSetting = createAsyncThunk('adminNotifications/getNotificationSetting', async () => {
  const response = await axiosRequest.get(notifications.ADMIN_NOTIFICATION_SETTING)
  return { data: response?.data?.data }
})

export const setNotificationSetting = createAsyncThunk('adminNotifications/setNotificationSetting', async (data) => {
  return { data }
})

export const editNotificationSetting = createAsyncThunk('adminNotifications/editNotificationSetting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(notifications.ADMIN_NOTIFICATION_UPDATE_SETTING, data)
  dispatch(getNotificationSetting())
  return { status: response.data?.status }
})

export const getSMSTemplates = createAsyncThunk('adminNotifications/getSMSTemplates', async () => {
  const response = await axiosRequest.get(notifications.ADMIN_NOTIFICATION_SMS_TEMPLATES)
  return { data: response?.data?.data }
})

export const setSMSTemplates = createAsyncThunk('adminNotifications/setSMSTemplates', async (data) => {
  return { data }
})

export const getEmailTemplates = createAsyncThunk('adminNotifications/getEmailTemplates', async () => {
  const response = await axiosRequest.get(notifications.ADMIN_NOTIFICATION_EMAIL_TEMPLATES)
  return { data: response?.data?.data }
})

export const setEmailTemplates = createAsyncThunk('adminNotifications/setEmailTemplates', async (data) => {
  return { data }
})

export const adminNotificationsSlice = createSlice({
  name: 'adminNotifications',
  initialState: {
    data: [],
    loading: true,
    smsTemplates: [],
    emailTemplates: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getNotificationSetting.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getNotificationSetting.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getNotificationSetting.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setNotificationSetting.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(editNotificationSetting.fulfilled, (state, { payload }) => {
        console.log(`UPDATED DATA STATUS ${payload?.status}`)
      })
      .addCase(getSMSTemplates.fulfilled, (state, { payload }) => {
        state.smsTemplates = payload?.data ?? []
      })
      .addCase(setSMSTemplates.fulfilled, (state, { payload }) => {
        state.smsTemplates = payload?.data ?? []
      })
      .addCase(getEmailTemplates.fulfilled, (state, { payload }) => {
        state.emailTemplates = payload?.data ?? []
      })
      .addCase(setEmailTemplates.fulfilled, (state, { payload }) => {
        state.emailTemplates = payload?.data ?? []
      })
  }
})

export default adminNotificationsSlice.reducer
