// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { packages } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminPackages = createAsyncThunk('adminPackages/getAdminPackages', async () => {
  const response = await axiosRequest.get(packages.ADMIN_PACKAGES)
  console.log('------response getAdminPackages----', response?.data)
  return {
    data: response?.data?.data,
    all_Tabs: response?.data?.all_tabs
  }
})

export const setAdminPackages = createAsyncThunk('adminPackages/setAdminPackages', async (data) => {
  return { data }
})

export const createAdminPackage = createAsyncThunk('adminPackages/createAdminPackage', async (data, { dispatch }) => {
  const response = await axiosRequest.post(packages.ADMIN_ADD_PACKAGE, data)
  dispatch(getAdminPackages())
  return { status: response?.data?.status }
})

export const updatePackageStatus = createAsyncThunk('adminPackages/updatePackageStatus', async (data, { dispatch }) => {
  const response = await axiosRequest.post(packages.UPDATE_PACKAGES_STATUS, data)
  if (response?.data?.status) {
    dispatch(getAdminPackages())
  }
  return { status: response?.data?.status }
})

export const updateAdminPackage = createAsyncThunk('adminPackages/updateAdminPackage', async (data, { dispatch }) => {
  const response = await axiosRequest.post(packages.UPDATE_PACKAGES_STATUS, data)
  if (response?.data?.status) {
    dispatch(getAdminPackages())
  }
  return { status: response?.data?.status }
})

export const deleteAdminPackage = createAsyncThunk('adminPackages/deleteAdminPackage', async (id) => {
  const response = await axiosRequest.post(packages.ADMIN_DELETE_PACKAGE, { id })
  return { id, status: response.data?.status }
})
export const adminPackagesSlice = createSlice({
  name: 'adminPackages',
  initialState: {
    data: [],
    all_Tabs: [],
    loading: true,
    getPackageData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminPackages.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.all_Tabs = payload?.all_Tabs ?? []
        state.loading = false
      })
      .addCase(getAdminPackages.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminPackages.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setAdminPackages.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(createAdminPackage.fulfilled, (state, { payload }) => {
        displaySuccessToast('Package has been added Successfully')
        console.log(`CREATED STATUS ${payload?.status}`)
        state.status = payload?.status ?? []
      })

  }
})

export default adminPackagesSlice.reducer
