import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_LOGIN: `${API_HOST}admin/authentication/login`,
  FRONT_LOGIN: `${API_HOST}front/authentication/login`,
  FORGOT_PASSWORD: `${API_HOST}front/authentication/forgot_password`,
  CHECK_EMAIL_AVAILABILITY: `${API_HOST}front/authentication/check_email_availability`,
  AUTH_GET_ALL_CLINICS: `${API_HOST}front/authentication/all_clinics`,
  USER_REGISTER: `${API_HOST}front/authentication/register`,
  CLINIC_REGISTER: `${API_HOST}clinics/authentication/register`,
  RESEND_CONFIRMATION_AGAIN: `${API_HOST}front/authentication/send_confirmation_again`,
  // SOCIAL LOGINS
  GMAIL_LOGIN: `${API_HOST}front/authentication/social_login`

}
