import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_LOGIN: `${API_HOST}admin/authentication/login`,
  ADMIN_CHALLENGES: `${API_HOST}admin/challenges/all_challenges`,
  ADMIN_CHALLENGE_DETAIL: `${API_HOST}admin/challenges/challenge_detail`,
  ADMIN_ADD_CHALLENGE: `${API_HOST}admin/challenges/create_challenge`,
  ADMIN_EDIT_CHALLENGE: `${API_HOST}admin/challenges/edit_challenge`,
  ADMIN_DELETE_CHALLENGE: `${API_HOST}admin/challenges/delete_challenge`,

  ADMIN_CHALLENGE_CATEGORIES: `${API_HOST}admin/challenges/all_cats`,
  ADMIN_ADD_CHALLENGE_CATEGORY: `${API_HOST}admin/challenges/create_cat`,
  ADMIN_EDIT_CHALLENGE_CATEGORY: `${API_HOST}admin/challenges/edit_cat`,
  ADMIN_DELETE_CHALLENGE_CATEGORY: `${API_HOST}admin/challenges/delete_cat`,

  ADMIN_CHALLENGE_IMAGES: `${API_HOST}admin/challenges/challenge_image_gallery`,
  ADMIN_ADD_CHALLENGE_IMAGE: `${API_HOST}admin/challenges/add_gallery_image`,
  ADMIN_DELETE_CHALLENGE_IMAGE: `${API_HOST}admin/challenges/delete_gallery_image`,

  ADMIN_CHALLENGE_TROPHY_IMAGES: `${API_HOST}admin/challenges/challenge_trophy_gallery`,
  ADMIN_ADD_CHALLENGE_TROPHY_IMAGE: `${API_HOST}admin/challenges/add_trophy_image`,
  ADMIN_DELETE_CHALLENGE_TROPHY_IMAGE: `${API_HOST}admin/challenges/delete_trophy_image`,

  // CLINIC ROUTES
  CLINIC_CHALLENGES: `${API_HOST}clinics/challenges/all_challenges`,
  CLINIC_CHALLENGE_DETAIL: `${API_HOST}clinics/challenges/challenge_detail`,
  CLINIC_ADD_CHALLENGE: `${API_HOST}clinics/challenges/create_challenge`,
  CLINIC_EDIT_CHALLENGE: `${API_HOST}clinics/challenges/edit_challenge`,
  CLINIC_DELETE_CHALLENGE: `${API_HOST}clinics/challenges/delete_challenge`,
  
  // FRONT CHALLENGES
  FRONT_ALL_CHALLENGES: `${API_HOST}front/challenges/get_challenges`,
  UPDATE_LOGS: `${API_HOST}front/dashboard/update_logs`,
  FRONT_COMMENTS_DETAILS: `${API_HOST}front/challenges/view`,
  FRONT_SUBMIT_COMMENTS: `${API_HOST}front/challenges/add_comment`,
  FRONT_GET_CHALLENGE_GALLERY: `${API_HOST}front/challenges/challenge_images`,
  FRONT_GET_CHALLENGE_COMPLETE_GALLERY: `${API_HOST}front/challenges/completed_images`,
  FRONT_CHALLENGE_CATEGORIES: `${API_HOST}front/challenges/get_categories`,
  FRONT_CREATE_CHALLENGE: `${API_HOST}front/challenges/create`,
  FRONT_JOIN_CHALLENGE: `${API_HOST}front/challenges/join`,
  FRONT_LEAVE_CHALLENGE: `${API_HOST}front/challenges/leave`,
  FRONT_DID_IT_CHALLENGE: `${API_HOST}front/challenges/challenge_done`,
  FRONT_CHALLENGE_STATS: `${API_HOST}front/challenges/get_challenge_stat`,
  FRONT_DELETE_CHALLENGE: `${API_HOST}front/challenges/delete_challenge`,
  FRONT_UPDATE_CHALLENGE: `${API_HOST}front/challenges/edit_challenge`,
  FRONT_DELETE_COMMENT: `${API_HOST}clinics/challenges/delete_challenge`,
  FRONT_DELETE_POST_COMMENT: `${API_HOST}front/challenges/delete_challenge_comment`,
  FRONT_SUPPORT: `${API_HOST}front/tickets/mark_unmark_complete`
}