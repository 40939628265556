// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getClinicAppointments = createAsyncThunk('clinicMettings/getClinicAppointments', async () => {
  const response = await axiosRequest.get(meeting.CLIENT_APPOINTMENTS)
  return {
    appointments: response?.data?.data?.get_appointments ?? [],
    requested_appointments: response?.data?.data?.requested_appointments ?? []
  }
})

export const setClinicAppointments = createAsyncThunk('clinicMettings/setClinicAppointments', async (data) => {
  return { data }
})
export const setClinicRequestedAppointments = createAsyncThunk('clinicMettings/setClinicRequestedAppointments', async (data) => {
  return { data }
})
// ** Create Appointment Data 
export const createClinicAppointment = createAsyncThunk('clinicMettings/createClinicAppointment', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLIENIC_ADD_APPOINTMENT, { ...data })
  if (response?.data?.status) {
    dispatch(getClinicAppointments())
  }
  return { data: response?.data?.data ?? {}, status: response?.data.status, message: response?.data.message }
})

// Create Classes Data
export const createClinicClasses = createAsyncThunk('clinicMettings/createClinicClasses', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_ADD_CLASSES, data)
  if (response?.data?.status) {
    dispatch(getClinicMeetings())
  }
  return { data: response?.data?.data ?? {}, status: response?.data?.status, message: response?.data?.message }
})

export const setClinicClasses = createAsyncThunk('clinicMettings/setClinicClasses', async (data) => {
  return { data }
})

// ** Delete Classes Data

export const deleteClinicClasses = createAsyncThunk('clinicMettings/deleteClinicClasses', async (id) => {
  const response = await axiosRequest.post(meeting.CLINIC_DELETE_CLASSES, { id })
  return {
    id, status: response?.data?.status
  }
})

// ** Update Classes Data

export const updateClinicClasses = createAsyncThunk('clinicMettings/updateClinicClasses', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_CLASSES, data)
  console.log("Classes Update", response.data)
  if (response?.data.status) {
    dispatch(getClinicMeetings())
  }
  return { status: response?.data?.status, message: response?.data?.message }
})

// ** All Clinic live Mettings 

export const getliveMeetings = createAsyncThunk('clinicmettings/getliveMeetings', async () => {
  const response = await axiosRequest.get(meeting.CLINIC_LIVE_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const getUsersList = createAsyncThunk('clinicmettings/getUsersList', async () => {
  const response = await axiosRequest.get(meeting.CLINIC_USERS_LISTS)
  return { data: response?.data?.data ?? [] }
})

// ** Create Clinic Live Meeting Data 

export const createClinicLivemeeting = createAsyncThunk('clinicmettings/createClinicLivemeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_ADD_LIVE_MEETING, data)
  if (response?.data?.status) {
    dispatch(getliveMeetings())
  }
  return { data: response?.data?.data ?? {}, status: response?.data?.status, message: response?.data?.message }
})

// ** Update Clinic Live Meeting Data 

export const updateClinicLiveMeeting = createAsyncThunk('clinicmettings/updateClinicLiveMeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_LIVE_MEETING, data)
  if (response?.data?.status) {
    dispatch(getliveMeetings())
  }
  return { status: response?.data?.status, message: response?.data?.message }
})
// ** Delete Clinic Live Meeting Data 

export const deleteClinicLiveMeeting = createAsyncThunk('clinicmettings/deleteClinicLiveMeeting', async (id) => {
  const response = await axiosRequest.post(meeting.CLINIC_DELETE_LIVE_MEETING, { id })
  console.log("deleteClinicLiveMeeting ====>>>", response)
  return {
    id, status: response?.data?.status
  }
})

// ** Set Clinic Live Meeting Data 

export const setClinicLiveMeeting = createAsyncThunk('clinicmettings/setClinicLiveMeeting', (data) => {
  return { data }
})

export const updateClinicAppointment = createAsyncThunk('clinicMettings/updateClinicAppointment', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLIENIC_EDIT_APPOINTMENT, data)
  console.log("appoinment updated", response.data)
  if (response?.data?.status) {
    dispatch(getClinicAppointments())
  }
  return { status: response?.data?.status, message: response?.data.message }
})

export const deleteClinicAppointment = createAsyncThunk('clinicMettings/deleteClinicAppointment', async (id, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_DELETE_APPOINTMENT, { id })
  if (response?.data?.status) {
    dispatch(getClinicAppointments())
  }
  return { id, status: response.data?.status }
})
// CLASSES
export const getAdminClasses = createAsyncThunk('clinicMettings/getAdminClasses', async () => {
  const response = await axiosRequest.get(meeting.ADMIN_MEETING_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAdminClasses = createAsyncThunk('clinicMettings/setAdminClasses', async (data) => {
  return { data }
})

export const addAdminClass = createAsyncThunk('clinicMettings/addAdminClass', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_ADD_CLASS, data)
  if (response?.data?.status) {
    dispatch(getAdminClasses())
  }
  return { data: response?.data?.data ?? {} }
})

export const updateAdminClass = createAsyncThunk('clinicMettings/updateAdminClass', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_EDIT_CLASS, data)
  return { data, status: response.data?.status }
})

export const deleteAdminClass = createAsyncThunk('clinicMettings/deleteAdminClass', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_DELETE_CLASS, { id })
  return { id, status: response?.data?.status }
})

export const getAdminClassDetail = createAsyncThunk('clinicMettings/getAdminClassDetail', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_DETAIL, { id })
  return { data, status: response.data?.status }
})

export const sendAdminClassInvitation = createAsyncThunk('clinicMettings/sendAdminClassInvitation', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_SEND_INVITATION, data)
  return { data, status: response.data?.status }
})

// All Clinic Classes
export const getClinicMeetings = createAsyncThunk('clinicMettings/getClinicMeetings', async () => {
  const response = await axiosRequest.get(meeting.CLINIC_MEETING_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAdminMeetings = createAsyncThunk('clinicMettings/setAdminMeetings', async (data) => {
  return { data }
})

export const addAdminMeeting = createAsyncThunk('adminMeetings/addAdminMeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_ADD_MEETING, data)
  if (response?.data?.status) {
    dispatch(getClinicMeetings())
  }
  return { data: response?.data?.data ?? {} }
})

export const updateAdminMeeting = createAsyncThunk('adminMeetings/updateAdminMeeting', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_EDIT_MEETING, data)
  return { data, status: response.data?.status }
})

export const deleteAdminMeeting = createAsyncThunk('adminMeetings/deleteAdminMeeting', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_DELETE_MEETING, { id })
  return { id, status: response?.data?.status }
})

export const adminMeetingsSlice = createSlice({
  name: 'adminMeetings',
  initialState: {
    appointments: [],
    usersList: [],
    requestedAppointments: [],
    appointmentLoading: true,
    meetingClasses: [],
    meetingClassesLoading: false,
    classesLoading: false,
    classDetail: {},
    liveMeeting: [],
    liveMeetingLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getClinicAppointments.fulfilled, (state, { payload }) => {
        state.appointments = payload?.appointments ?? []
        state.requestedAppointments = payload?.requested_appointments ?? []
        state.appointmentLoading = false
      })
      .addCase(setClinicAppointments.fulfilled, (state, { payload }) => {
        state.appointments = payload?.data ?? []
      })
      .addCase(setClinicRequestedAppointments.fulfilled, (state, { payload }) => {
        state.requestedAppointments = payload?.data ?? []
      })
      .addCase(getClinicAppointments.pending, (state) => {
        console.log("LOADING...")
        state.appointmentLoading = true
      })
      .addCase(getClinicAppointments.rejected, (state) => {
        console.log("REJECTED...")
        state.appointmentLoading = false
      })
      // ** Appointment
      .addCase(createClinicAppointment.fulfilled, (state, { payload }) => {
        console.log(`ADDED APPOINTMENT DATA ${JSON.stringify(payload?.data)}`)
      })
      // ** Classes 
      .addCase(createClinicClasses.fulfilled, (state, { payload }) => {
        console.log(`ADDED APPOINTMENT DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(setClinicClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(deleteClinicAppointment.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.id}`)
      })
      .addCase(updateClinicClasses.fulfilled, (state, { payload }) => {
        console.log("UPDATED CLASS SUCCESSFULLY", payload?.status)
      })
      // CLASSES
      .addCase(getAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
        state.classesLoading = false
      })
      .addCase(getAdminClasses.pending, (state) => {
        state.classesLoading = true
      })
      .addCase(getAdminClasses.rejected, (state) => {
        state.classesLoading = false
      })
      // DELETE CLASSES 
      .addCase(deleteClinicClasses.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.id}`)
      })
      .addCase(setAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(addAdminClass.fulfilled, (state, { payload }) => {
        console.log(`ADDED CLASS DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminClass.fulfilled, (state, { payload }) => {
        console.log(`DELETED CLASS ID ${payload?.id}`)
      })
      .addCase(updateAdminClass.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASS ${JSON.stringify(payload?.data)}`)
      })
      .addCase(getAdminClassDetail.fulfilled, (state, { payload }) => {
        state.classDetail = payload?.data ?? []
      })
      .addCase(sendAdminClassInvitation.fulfilled, (state, { payload }) => {
        console.log(`CLASS INVITATION SENT ${payload?.status}`)
      })
      // CLASSES 
      .addCase(getClinicMeetings.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
        state.meetingClassesLoading = false
      })
      .addCase(getClinicMeetings.pending, (state) => {
        state.meetingClassesLoading = true
      })
      .addCase(getClinicMeetings.rejected, (state) => {
        state.meetingClassesLoading = false
      })
      .addCase(getUsersList.fulfilled, (state, { payload }) => {
        state.usersList = payload?.data ?? []
        console.log("get all USERS LIST Data", payload?.data)
      })
      // LIVE MEETINGS
      .addCase(getliveMeetings.fulfilled, (state, { payload }) => {
        state.liveMeeting = payload?.data ?? []
        state.liveMeetingLoading = false
        console.log("get all Live Meeting Data", payload?.data)
      })
      .addCase(getliveMeetings.pending, (state) => {
        state.liveMeetingLoading = true
      })
      .addCase(getliveMeetings.rejected, (state) => {
        state.liveMeetingLoading = false
      })
      .addCase(deleteClinicLiveMeeting.fulfilled, (state, { payload }) => {
        console.log("Live Meeting Data Deleted", payload?.id)
      })
      .addCase(setClinicLiveMeeting.fulfilled, (state, { payload }) => {
        state.liveMeeting = payload?.data ?? []
      })
      .addCase(createClinicLivemeeting.fulfilled, (state, { payload }) => {
        console.log("ADDED LIVE CLASSES DATA", payload?.data)
      })
      .addCase(updateClinicLiveMeeting.fulfilled, (state, { payload }) => {
        console.log("LIVE MEETING DATA UPDATED SUCCESSFULLY", payload?.status)
      })
      .addCase(setAdminMeetings.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(addAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`ADDED MEETING DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`DELETED MEETING ID ${payload?.id}`)
      })
      .addCase(updateAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`UPDATED MEETING ${JSON.stringify(payload?.data)}`)
      })
  }
})

export default adminMeetingsSlice.reducer
