import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { features } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getFeatures = createAsyncThunk('clinicFeatures/getFeatures', async () => {
  const response = await axiosRequest.get(features.CLINIC_FEATURES)
  return { data: response?.data?.data }
})

export const setFeatures = createAsyncThunk('clinicFeatures/setFeatures', async (data) => {
  return { data }
})

export const editFeatures = createAsyncThunk('clinicFeatures/editFeatures', async (data, { dispatch }) => {
  const response = await axiosRequest.post(features.CLINIC_EDIT_FEATURE, data)
  dispatch(getFeatures())
  return { status: response.data?.status, message: response.data?.message }
})

export const adminFeaturesSlice = createSlice({
  name: 'adminFeatures',
  initialState: {
    data: [],
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFeatures.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getFeatures.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getFeatures.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setFeatures.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(editFeatures.fulfilled, (state, { payload }) => {
        console.log(`UPDATED DATA STATUS ${payload?.status}`)
      })
  }
})

export default adminFeaturesSlice.reducer
