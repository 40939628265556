//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'


export const getInvoice = createAsyncThunk('clinicPatients/getInvoice', async (data) => {
  const response = await axiosRequest.post(meeting.GET_INVOICE, data)
  return { data: response?.data?.data, stats: response?.data?.stats }
})
export const getPersonalUserPayments = createAsyncThunk('clinicPatients/getPersonalUserPayments', async (data) => {
  const response = await axiosRequest.post(meeting.PERSONAL_USER_PAYMENTS, data)
  return { data: response?.data?.data, stats: response?.data?.stats }
})
export const getClientPaidServices = createAsyncThunk('clinicPatients/getClientPaidServices', async (data) => {
  const response = await axiosRequest.post(meeting.CLIENT_PAID_SERVICES, data)
  return { data: response?.data?.data, stats: response?.data?.stats }
})
export const newInvoice = createAsyncThunk('clinicPatients/newInvoice', async (data) => {
  const response = await axiosRequest.post(meeting.NEW_INVOICE, data)
  return { data: response?.data?.data }
})

export const getUpdateAdminPackage = createAsyncThunk('adminPackages/getUpdateAdminPackage', async (data) => {
  const response = await axiosRequest.post(meeting.GET_PACKAGES_STATUS, data)
  return { data: response?.data?.data }
})


export const addInvoice = createAsyncThunk('clinicPatients/addInvoice', async (data) => {
  const response = await axiosRequest.post(meeting.ADD_INVOICE, data)
  return { data: response?.data?.data }
})

export const clinicPatientsSlice = createSlice({
  name: 'adminPayment',
  initialState: {
    invoiceLoading: false,
    getInvoiceData: [],
    newInvoiceData: [],
    addInvoiceData: [],
    getClientpaidServicesData: [],
    getPersonalUserPaymentsData: [],
    getPersonalUserPaymentsStatsData: {},
    getClientpaidServicesStatsData: {},
    packageStatusLoading: false,
    getInvoiceStatsData: {},
    getPackageData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder

      //
      .addCase(getInvoice.pending, (state) => {
        state.invoiceLoading = true
      })
      .addCase(getInvoice.rejected, (state) => {
        state.invoiceLoading = false
      })
      .addCase(getInvoice.fulfilled, (state, { payload }) => {
        state.getInvoiceData = payload?.data
        state.getInvoiceStatsData = payload?.stats
        state.invoiceLoading = false
      })
      //
      //
      .addCase(getClientPaidServices.pending, (state) => {
        state.invoiceLoading = true
      })
      .addCase(getClientPaidServices.rejected, (state) => {
        state.invoiceLoading = false
      })
      .addCase(getClientPaidServices.fulfilled, (state, { payload }) => {
        state.getClientpaidServicesData = payload?.data
        state.getClientpaidServicesStatsData = payload?.stats
        state.invoiceLoading = false
      })
      //
      //
      .addCase(getPersonalUserPayments.pending, (state) => {
        state.invoiceLoading = true
      })
      .addCase(getPersonalUserPayments.rejected, (state) => {
        state.invoiceLoading = false
      })
      .addCase(getPersonalUserPayments.fulfilled, (state, { payload }) => {
        state.getPersonalUserPaymentsData = payload?.data
        state.getPersonalUserPaymentsStatsData = payload?.stats
        state.invoiceLoading = false
      })
      //
      .addCase(newInvoice.pending, (state) => {
        state.invoiceLoading = true
      })
      .addCase(newInvoice.rejected, (state) => {
        state.invoiceLoading = false
      })
      .addCase(newInvoice.fulfilled, (state, { payload }) => {
        state.newInvoiceData = payload?.data
        state.invoiceLoading = false
      })

      //
      .addCase(addInvoice.pending, (state) => {
        state.invoiceLoading = true
      })
      .addCase(addInvoice.rejected, (state) => {
        state.invoiceLoading = false
      })
      .addCase(addInvoice.fulfilled, (state, { payload }) => {
        state.addInvoiceData = payload?.data
        state.invoiceLoading = false
      })

      //
      //
      .addCase(getUpdateAdminPackage.pending, (state) => {
        state.packageStatusLoading = true
      })
      .addCase(getUpdateAdminPackage.rejected, (state) => {
        state.packageStatusLoading = false
      })
      .addCase(getUpdateAdminPackage.fulfilled, (state, { payload }) => {
        state.getPackageData = payload?.data ?? []
        state.packageStatusLoading = false
        console.log("=============4545========", state.getPackageData)
      })

  }

})
export default clinicPatientsSlice.reducer