import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { favorite } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const handleFavoriteUnFavorite = createAsyncThunk('favorites/handleFavoriteUnFavorite', async (data) => {
  const response = await axiosRequest.post(favorite.FRONT_FAVORITE_UNFAVORITE, data)
  console.log("==response==", response.data)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})

export const getAverageRating = createAsyncThunk('favorites/getAverageRating', async (data) => {
  const response = await axiosRequest.post(favorite.AVERAGE_RATINGS, data)
  return { data: response.data?.average_rating ?? 0 }
})

export const addRating = createAsyncThunk('favorites/addRating', async (data) => {
  const response = await axiosRequest.post(favorite.ADD_RATING, data)
  return { status: response.data?.status ?? false, message: response.data?.message }
})

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState: {
    reviews: [],
    averageRating: 0,
    classAverageRating: 0,
    classReviews: [],
    lessonAverageRating: 0,
    lessonReviews: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(handleFavoriteUnFavorite.fulfilled, () => {
        // state.reviews = payload.data
      })
      .addCase(getAverageRating.fulfilled, (state, { payload }) => {
        state.averageRating = payload.data
      })
      .addCase(addRating.fulfilled, (state, { payload }) => {
        console.log('RATING ADDED', payload?.status)
      })
  }
})

export default favoriteSlice.reducer
