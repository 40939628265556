// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { trainings } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getTrainings = createAsyncThunk('clinicTrainings/getTrainings', async () => {
  const response = await axiosRequest.get(trainings.CLINIC_TRAININGS)
  return { data: response?.data?.data }
})

export const setTrainings = createAsyncThunk('clinicTrainings/setTrainings', async (data) => {
  return { data }
})

export const addTraining = createAsyncThunk('clinicTrainings/addTraining', async (data, { dispatch }) => {
  const response = await axiosRequest.post(trainings.CLINIC_ADD_TRAINING, data)
  dispatch(getTrainings())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const updateTraining = createAsyncThunk('clinicTrainings/updateTraining', async (data, { dispatch }) => {
  const response = await axiosRequest.post(trainings.CLINIC_EDIT_TRAINING, data)
  dispatch(getTrainings())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const deleteTraining = createAsyncThunk('clinicTrainings/deleteTraining', async (id) => {
  const response = await axiosRequest.post(trainings.CLINIC_DELETE_TRAINING, { id })
  return { id, status: response.data?.status }
})

export const adminContentSlice = createSlice({
  name: 'adminContents',
  initialState: {
    data: [],
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTrainings.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setTrainings.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getTrainings.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getTrainings.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteTraining.fulfilled, (state, { payload }) => {
        console.log(`DELETED ID ${payload?.id}`)
      })
      .addCase(addTraining.fulfilled, (state, { payload }) => {
        console.log(`ADDED CONTENT ${payload?.status}`)
      })
  }
})

export default adminContentSlice.reducer
