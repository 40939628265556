import { API_HOST } from './config'
export default {
  // ADMIN ROUTES
  ADMIN_TASKS: `${API_HOST}admin/tasks/all_tasks`,
  ADMIN_EDIT_TASK: `${API_HOST}admin/tasks/edit_task`,
  ADMIN_DELETE_TASK: `${API_HOST}admin/tasks/delete_task`,
  ADMIN_TASKS_CATEGORIES: `${API_HOST}admin/tasks/all_task_cats`,
  ADMIN_TASKS_ADD_CATEGORY: `${API_HOST}admin/tasks/add_task_cat`,
  ADMIN_TASKS_EDIT_CATEGORY: `${API_HOST}admin/tasks/edit_task_cat`,
  ADMIN_TASKS_DELETE_CATEGORY: `${API_HOST}admin/tasks/delete_task_cat`,
  ADMIN_TASKS_PARENT_CATEGORIES: `${API_HOST}admin/tasks/all_parent_cats`,
  ADMIN_TASKS_BETTER_SAME_INDIVIDUAL: `${API_HOST}admin/tasks/better_same_individual`,
  ADMIN_TASKS_BETTER_SAME_COMPLETION: `${API_HOST}admin/tasks/better_same_completion`,
  ADMIN_USER_SUPPORT_LIST: `${API_HOST}admin/tickets/get_all_tickets`,
  ADMIN_TICKET_DELETE: `${API_HOST}admin/tickets/delete_ticket`,

  // CLINIC ROUTES
  CLINIC_TASKS: `${API_HOST}clinics/tasks/all_tasks`,
  CLINIC_EDIT_TASK: `${API_HOST}clinics/tasks/edit_task`,
  CLINIC_DELETE_TASK: `${API_HOST}clinics/tasks/delete_task`,
  CLINIC_TASKS_BETTER_SAME_INDIVIDUAL: `${API_HOST}clinics/tasks/better_same_individual`,
  CLINIC_TASKS_BETTER_SAME_COMPLETION: `${API_HOST}clinics/tasks/better_same_completion`,

  // FRONT ROUTES
  FRONT_STATE_GHRAPH: `${API_HOST}front/tasks/task_stats`,
  UPDATE_LOGS_TASK: `${API_HOST}front/dashboard/update_logs`,

  FRONT_ALL_TASKS: `${API_HOST}front/tasks/get_tasks`,
  FRONT_SINGLE_TASK_COMPLETE: `${API_HOST}front/tasks/task_done`,
  FRONT_GET_CATEGORIES: `${API_HOST}front/tasks/get_categories`,
  FRONT_CREATE_NEW_TASKS: `${API_HOST}front/tasks/tasks_add`,
  FRONT_RECENT_TASKS: `${API_HOST}front/tasks/task_completed_recently`,
  FRONT_COMPLETED_TASKS: `${API_HOST}front/tasks/completed_tasks`,
  FRONT_CATEGORY_TASKS: `${API_HOST}front/tasks/get_category_tasks`,
  UPDATE_TASK_FINAL_FEELINGS: `${API_HOST}front/tasks/update_final_feelings`,
  UPDATE_TASKS_ORDER: `${API_HOST}front/tasks/update_order`,
  FRONT_USER_AVERAGE: `${API_HOST}front/dashboard/user_average`,


  // SUPPORT ROUTES
  EVERY_USER_SUPPORT: `${API_HOST}front/tickets/generate_ticket`,
  CATEGORIES_USER_SUPPORT: `${API_HOST}front/tickets/get_ticket_categories`,
  // USER_SUPPORT_LIST : `${API_HOST}front/tickets/get_tickets`,
  GET_SUPPORT_REPLYS: `${API_HOST}front/tickets/get_ticket_by_id`,
  SUPPORTS_REPLYS: `${API_HOST}front/tickets/reply`

}