// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
import { getAdminAppointments } from '../../../admin/meeting/store'
export const getFrontAppointments = createAsyncThunk('frontMeeting/getFrontAppointments', async () => {
  const response = await axiosRequest.get(meeting.FRONT_SCHADULE_APPOINTMENTS)
  return {
    schadule_appointments: response?.data?.data ?? []
  }
})
export const unMountFrontAppointments = createAsyncThunk('frontMeeting/unMountFrontAppointments', async () => {
  return {}
})

// GET EMPLYEE LIST
export const getEmplyeeAppointments = createAsyncThunk('frontMeeting/getEmplyeeAppointments', async () => {
  const response = await axiosRequest.get(meeting.EMP_SCHEDULE_APPOINTMENTS)
  return {
    schadule_appointments: response?.data?.data ?? []
  }
})
export const setEmplyeeAppointments = createAsyncThunk('frontMeeting/setEmplyeeAppointments', async (data) => {
  return { data }
})
export const getEmplyeeRequestedAppointments = createAsyncThunk('frontMeeting/getEmplyeeRequestedAppointments', async () => {
  const response = await axiosRequest.get(meeting.EMP_REQUESTED_APPOINTMENTS)
  return {
    data: response?.data?.data ?? []
  }
})

export const getFrontRequestedAppointments = createAsyncThunk('frontMeeting/getFrontRequestedAppointments', async () => {
  const response = await axiosRequest.get(meeting.FRONT_REQUESTED_APPOINTMENTS)
  return {
    requested_appointments: response?.data?.data ?? []
  }
})
//** Create Employee Appointment */
export const sendEmpAppointmentRequest = createAsyncThunk('frontMeeting/sendEmpAppointmentRequest', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.EMP_SEND_APPOINTMENT, data)
  if (response?.data?.status) {
    dispatch(getEmplyeeAppointments())
  }
  return { data: response?.data?.data ?? {} }
})
//** Update Employee Appointment */
export const updateEmpAppointmentRequest = createAsyncThunk('frontMeeting/updateEmpAppointmentRequest', async (data, { dispatch }) => {

  const response = await axiosRequest.post(meeting.EMP_UPDATE_APPOINTMENT, data)
  if (data?.expire) {
    if (response?.data?.status) {
      dispatch(getAdminAppointments())
      dispatch(getEmplyeeRequestedAppointments())
    }
  } else if (response?.data?.status) {
    dispatch(getEmplyeeAppointments())
    dispatch(getEmplyeeRequestedAppointments())
  }
  return { data: response?.data?.data ?? {} }
})
// ** GET PATIENTS LIST 
export const getPatientsList = createAsyncThunk('frontMeeting/getPatientsList', async () => {
  const response = await axiosRequest.get(meeting.EMP_PATENTS_LIST)
  return {
    data: response?.data?.data ?? []
  }
})
// ** GET EMPLOYEES 
export const getEmployeesList = createAsyncThunk('frontMeeting/getEmployeesList', async () => {
  const response = await axiosRequest.get(meeting.FRONT_EMPLOYEES_LIST)
  return {
    employees: response?.data?.data ?? []
  }
})

// DELETE EMPLOYEE
export const deleteEmployeeScheduleAppointment = createAsyncThunk('frontMeeting/deleteEmployeeScheduleAppointment', async (id) => {
  const response = await axiosRequest.post(meeting.EMP_DELETE_SCHEDULE_APPOINTMENTS, { id })
  if (response?.data?.status) {
    dispatch(getEmplyeeAppointments())
  }
  return { status: response.data?.status ?? false }
})
export const setFrontAppointments = createAsyncThunk('frontMeeting/setFrontAppointments', async (data) => {
  return { data }
})
export const setFrontSchedualAppointments = createAsyncThunk('frontMeeting/setFrontSchedualAppointments', async (data) => {
  return { data }
})
// ** Create Appointment Data 
export const createFrontAppointment = createAsyncThunk('frontMeeting/createFrontAppointment', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLIENIC_ADD_APPOINTMENT, { ...data })
  if (response?.data?.status) {
    dispatch(getFrontAppointments())
  }
  return { data: response?.data?.data ?? {} }
})
//** Create Appointment Availability */
export const checkAvailabilityAppointment = createAsyncThunk('frontMeeting/checkAvailabilityAppointment', async (data) => {
  const response = await axiosRequest.post(meeting.FRONT_AVAILABILITY_APPOINTMENT, { ...data })
  return { availabilityAppointment: response?.data?.data ?? {} }
})

//** Create Appointment Availability */
export const sendAppointmentRequest = createAsyncThunk('frontMeeting/sendAppointmentRequest', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.FRONT_ADD_APPOINTMENT_REQUEST, { ...data })
  if (response?.data?.status) {
    dispatch(getFrontRequestedAppointments())
  }
  return { data: response?.data?.data ?? {} }
})
//-----------------------------------CLASSES DATA-------------------------------

export const getFrontClasses = createAsyncThunk('frontMeeting/getFrontClasses', async () => {
  const response = await axiosRequest.get(meeting.FRONT_SCHADULE_CLASSES)
  return {
    schadule_classes: response?.data?.data ?? []
  }
})
export const getAvailableClasses = createAsyncThunk('frontMeeting/getAvailableClasses', async () => {
  const response = await axiosRequest.get(meeting.FRONT_AVAILABLE_CLASSES)
  return {
    available_classes: response?.data?.data ?? []
  }
})
// ** Delete Classes Data
export const deleteFrontClasses = createAsyncThunk('frontMeeting/deleteFrontClasses', async (class_id) => {
  const response = await axiosRequest.post(meeting.FRONT_CLASSES_OUT, { class_id })
  return {
    status: response?.data?.status ?? false
  }
})

export const setFrontClasses = createAsyncThunk('frontMeeting/setFrontClasses', async (data) => {
  return { data }
})

// ** Delete Classes Data
export const joinFrontClasses = createAsyncThunk('frontMeeting/joinFrontClasses', async (class_id) => {
  const response = await axiosRequest.post(meeting.FRONT_CLASSES_JOIN, { class_id })
  console.log("joinFrontClasses ====>>>", response)
  return {
    status: response?.data?.status ?? false
  }
})
export const setFrontJoinClasses = createAsyncThunk('frontMeeting/setFrontJoinClasses', async (data) => {
  return { data }
})

//----------------------------------LIVE MEETINGS------------------------------
// ** All Front live
export const getliveMeetings = createAsyncThunk('frontMeeting/getliveMeetings', async () => {
  const response = await axiosRequest.get(meeting.FRONT_LIVE_SESSION)
  return { data: response?.data?.data ?? [] }
})

export const joinFrontSession = createAsyncThunk('frontMeeting/joinFrontSession', async (id) => {
  const response = await axiosRequest.post(meeting.FRONT_JOIN_SESSION, { id })
  console.log("==join session response==", response.data)
  return {
    data: response?.data ?? [],
    status: response.data?.status ?? false,
    message: response.data?.message ?? 'something is wrong'
  }
})
export const outFrontSession = createAsyncThunk('frontMeeting/outFrontSession', async (id) => {
  const response = await axiosRequest.post(meeting.FRONT_OUT_FROM_SESSION, { id })
  return {
    data: response?.data ?? [],
    status: response.data?.status ?? false,
    message: response.data?.message ?? 'something is wrong'
  }
})
// Create Classes Data
export const createClinicClasses = createAsyncThunk('frontMeeting/createClinicClasses', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_ADD_CLASSES, data)
  if (response?.data?.status) {
    dispatch(getClinicMeetings())
  }
  return { data: response?.data?.data ?? {} }
})

// ** Update Classes Data

export const updateClinicClasses = createAsyncThunk('frontMeeting/updateClinicClasses', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_CLASSES, data)
  console.log("Classes Update", response.data)
  if (response?.data.status) {
    dispatch(getClinicMeetings())
  }
  return { status: response?.data?.status ?? false }
})
// ** Create Clinic Live Meeting Data 

export const createClinicLivemeeting = createAsyncThunk('frontMeeting/createClinicLivemeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_ADD_LIVE_MEETING, data)
  if (response?.data?.status) {
    dispatch(getliveMeetings())
  }
  return { data: response?.data?.data ?? {} }
})

// ** Update Clinic Live Meeting Data 

export const updateClinicLiveMeeting = createAsyncThunk('frontMeeting/updateClinicLiveMeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_EDIT_LIVE_MEETING, data)
  if (response?.data?.status) {
    dispatch(getliveMeetings())
  }
  return { status: response?.data?.status ?? false }
})
// ** Delete Clinic Live Meeting Data 

export const deleteClinicLiveMeeting = createAsyncThunk('frontMeeting/deleteClinicLiveMeeting', async (id) => {
  const response = await axiosRequest.post(meeting.CLINIC_DELETE_LIVE_MEETING, { id })
  console.log("deleteClinicLiveMeeting ====>>>", response)
  return {
    id, status: response?.data?.status ?? false
  }
})

// ** Set Clinic Live Meeting Data 

export const setClinicLiveMeeting = createAsyncThunk('frontMeeting/setClinicLiveMeeting', (data) => {
  return { data }
})

export const updateClinicAppointment = createAsyncThunk('frontMeeting/updateClinicAppointment', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLIENIC_EDIT_APPOINTMENT, data)
  console.log("appoinment updated", response.data)
  if (response?.data?.status) {
    dispatch(getFrontAppointments())
  }
  return { status: response?.data?.status ?? false }
})

export const deleteFrontAppointment = createAsyncThunk('frontMeeting/deleteFrontAppointment', async (id) => {
  const response = await axiosRequest.post(meeting.FRONT_DELETE_APPOINTMENTS, { id })
  return { id, status: response.data?.status ?? false }
})
// CLASSES
export const getAdminClasses = createAsyncThunk('frontMeeting/getAdminClasses', async () => {
  const response = await axiosRequest.get(meeting.ADMIN_MEETING_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAdminClasses = createAsyncThunk('frontMeeting/setAdminClasses', async (data) => {
  return { data }
})

export const addAdminClass = createAsyncThunk('frontMeeting/addAdminClass', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_ADD_CLASS, data)
  if (response?.data?.status) {
    dispatch(getAdminClasses())
  }
  return { data: response?.data?.data ?? {} }
})

export const updateAdminClass = createAsyncThunk('frontMeeting/updateAdminClass', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_EDIT_CLASS, data)
  return { data, status: response.data?.status ?? false }
})

export const deleteAdminClass = createAsyncThunk('frontMeeting/deleteAdminClass', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_DELETE_CLASS, { id })
  return { id, status: response?.data?.status ?? false }
})

export const getAdminClassDetail = createAsyncThunk('frontMeeting/getAdminClassDetail', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_DETAIL, { id })
  return { data, status: response.data?.status ?? false }
})

export const sendAdminClassInvitation = createAsyncThunk('frontMeeting/sendAdminClassInvitation', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_MEETING_CLASS_SEND_INVITATION, data)
  return { data, status: response.data?.status ?? false }
})

// All Clinic Classes
export const getClinicMeetings = createAsyncThunk('frontMeeting/getClinicMeetings', async () => {
  const response = await axiosRequest.get(meeting.CLINIC_MEETING_CLASSES)
  return { data: response?.data?.data ?? [] }
})

export const setAdminMeetings = createAsyncThunk('frontMeeting/setAdminMeetings', async (data) => {
  return { data }
})

export const addAdminMeeting = createAsyncThunk('adminMeetings/addAdminMeeting', async (data, { dispatch }) => {
  const response = await axiosRequest.post(meeting.ADMIN_ADD_MEETING, data)
  if (response?.data?.status) {
    dispatch(getClinicMeetings())
  }
  return { data: response?.data?.data ?? {} }
})

export const updateAdminMeeting = createAsyncThunk('adminMeetings/updateAdminMeeting', async (data) => {
  const response = await axiosRequest.post(meeting.ADMIN_EDIT_MEETING, data)
  return { data, status: response.data?.status ?? false }
})

export const deleteAdminMeeting = createAsyncThunk('adminMeetings/deleteAdminMeeting', async (id) => {
  const response = await axiosRequest.post(meeting.ADMIN_DELETE_MEETING, { id })
  return { id, status: response?.data?.status ?? false }
})
// INVITE USERS
export const inviteUser = createAsyncThunk('adminMeetings/inviteUser', async (email) => {
  const response = await axiosRequest.post(meeting.INVITE_USERS, { email })
  console.log('---response inviteUser----', response?.data)
  return { message: response?.data?.message ?? '' }
})

export const denyEmpRequestedAppointment = createAsyncThunk('frontMeeting/denyEmpRequestedAppointment', async (id, { dispatch }) => {
  const response = await axiosRequest.post(meeting.CLINIC_DENY_EMPLOYEES, { id })
  if (response?.data?.status) {
    dispatch(getEmplyeeRequestedAppointments())
  }
  return { id, status: response.data?.status }
})

export const setDenyRequestedAppointments = createAsyncThunk('frontMeeting/setDenyRequestedAppointments', async (data) => {
  return { data }
})
export const frontMeetingsSlice = createSlice({
  name: 'frontMeeting',
  initialState: {
    schadule_appointments: [],
    requestedAppointments: [],
    appointmentLoading: true,
    employeeslist: [],
    patientslist: [],
    availabilityAppointment: {},
    schadule_classes: [],
    available_classes: [],
    classesLoading: true,
    classDetail: {},
    liveMeeting: [],
    liveMeetingLoading: false,
    joinSession: {},
    outSession: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontAppointments.fulfilled, (state, { payload }) => {
        state.schadule_appointments = payload?.schadule_appointments ?? []
        state.appointmentLoading = false
      })
      .addCase(setFrontAppointments.fulfilled, (state, { payload }) => {
        state.requestedAppointments = payload?.data ?? []
      })
      .addCase(setFrontSchedualAppointments.fulfilled, (state, { payload }) => {
        state.schadule_appointments = payload?.data ?? []
        // state.requestedAppointments = payload?.data ?? []

      })
      .addCase(getFrontAppointments.pending, (state) => {
        console.log("LOADING...")
        state.appointmentLoading = true
      })
      .addCase(getFrontAppointments.rejected, (state) => {
        console.log("REJECTED...")
        state.appointmentLoading = false
      })
      //GET PATIENTS LIST
      .addCase(getPatientsList.fulfilled, (state, { payload }) => {
        state.patientslist = payload?.data ?? []
      })
      // getEmployees list
      .addCase(getEmployeesList.fulfilled, (state, { payload }) => {
        state.employeeslist = payload?.employees ?? []
      })
      // Requested Appointment
      .addCase(getFrontRequestedAppointments.fulfilled, (state, { payload }) => {
        state.requestedAppointments = payload?.requested_appointments ?? []
      })
      // Employee Send Appointemnt
      .addCase(sendEmpAppointmentRequest.fulfilled, () => {
        displaySuccessToast('Employee Appointment has been Submited Successfully')
      })
      // Employee update Appointemnt
      .addCase(updateEmpAppointmentRequest.fulfilled, () => {
        displaySuccessToast('Appointment has been Updated Successfully')
      })
      // ** Check Availability Appointment
      .addCase(checkAvailabilityAppointment.fulfilled, (state, { payload }) => {
        state.availabilityAppointment = payload?.availabilityAppointment
        console.log('Check  APPOINTMENT Availablity Data', state.availabilityAppointment)
      })
      // ** Send Request For Availability Appointment
      .addCase(sendAppointmentRequest.fulfilled, (state, { payload }) => {
        console.log('Appointment Send Successfully', payload?.data)
        displaySuccessToast('Appointment Request Send Successfully!')
      })
      // ** Appointment
      .addCase(createFrontAppointment.fulfilled, (state, { payload }) => {
        console.log(`ADDED APPOINTMENT DATA ${JSON.stringify(payload?.data)}`)
      })
      //-----------------------CLASSES-----------------------------------
      .addCase(getFrontClasses.fulfilled, (state, { payload }) => {
        state.schadule_classes = payload?.schadule_classes ?? []
        console.log('-----------getSchadualClasses--------', state.schadule_classes)
        state.classesLoading = false
      })
      .addCase(getAvailableClasses.fulfilled, (state, { payload }) => {
        state.available_classes = payload?.available_classes ?? []
        state.classesLoading = false
      })
      // DELETE CLASSES 
      .addCase(deleteFrontClasses.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.status}`)
      })
      .addCase(setFrontClasses.fulfilled, (state, { payload }) => {
        state.schadule_classes = payload?.data ?? []
      })
      .addCase(joinFrontClasses.fulfilled, (state, { payload }) => {
        console.log(`Join the Class successfully ${payload?.status}`)
      })
      .addCase(setFrontJoinClasses.fulfilled, (state, { payload }) => {
        state.available_classes = payload?.data ?? []
      })
      // ---------------------------- LIVE MEETINGS -------------------------
      .addCase(getliveMeetings.fulfilled, (state, { payload }) => {
        state.liveMeeting = payload?.data ?? []
        state.liveMeetingLoading = false
        console.log("get all Live Meeting Data", payload?.data)
      })
      .addCase(getliveMeetings.pending, (state) => {
        state.liveMeetingLoading = true
      })
      .addCase(getliveMeetings.rejected, (state) => {
        state.liveMeetingLoading = false
      })
      .addCase(joinFrontSession.fulfilled, (state, { payload }) => {
        state.joinSession = payload?.data ?? []
        console.log("LIVE SESSION ARE CREATED", state.joinSession)
      })
      .addCase(outFrontSession.fulfilled, (state, { payload }) => {
        state.outSession = payload?.data ?? []
        console.log("LIVE SESSION ARE CREATED", state.outSession)
      })
      // ** Classes 
      .addCase(createClinicClasses.fulfilled, (state, { payload }) => {
        console.log(`ADDED APPOINTMENT DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteFrontAppointment.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.id}`)
      })
      .addCase(updateClinicClasses.fulfilled, (state, { payload }) => {
        console.log("UPDATED CLASS SUCCESSFULLY", payload?.status)
      })
      // CLASSES
      .addCase(getAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(setAdminClasses.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(addAdminClass.fulfilled, (state, { payload }) => {
        console.log(`ADDED CLASS DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminClass.fulfilled, (state, { payload }) => {
        console.log(`DELETED CLASS ID ${payload?.id}`)
      })
      .addCase(updateAdminClass.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CLASS ${JSON.stringify(payload?.data)}`)
      })
      .addCase(getAdminClassDetail.fulfilled, (state, { payload }) => {
        state.classDetail = payload?.data ?? []
      })
      .addCase(sendAdminClassInvitation.fulfilled, (state, { payload }) => {
        console.log(`CLASS INVITATION SENT ${payload?.status}`)
      })
      // CLASSES 
      .addCase(getClinicMeetings.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })

      .addCase(deleteClinicLiveMeeting.fulfilled, (state, { payload }) => {
        console.log("Live Meeting Data Deleted", payload?.id)
      })
      .addCase(setClinicLiveMeeting.fulfilled, (state, { payload }) => {
        state.liveMeeting = payload?.data ?? []
      })
      .addCase(createClinicLivemeeting.fulfilled, (state, { payload }) => {
        console.log("ADDED LIVE CLASSES DATA", payload?.data)
      })
      .addCase(updateClinicLiveMeeting.fulfilled, (state, { payload }) => {
        console.log("LIVE MEETING DATA UPDATED SUCCESSFULLY", payload?.status)
      })
      .addCase(setAdminMeetings.fulfilled, (state, { payload }) => {
        state.meetingClasses = payload?.data ?? []
      })
      .addCase(addAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`ADDED MEETING DATA ${JSON.stringify(payload?.data)}`)
      })
      .addCase(deleteAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`DELETED MEETING ID ${payload?.id}`)
      })
      .addCase(updateAdminMeeting.fulfilled, (state, { payload }) => {
        console.log(`UPDATED MEETING ${JSON.stringify(payload?.data)}`)
      })
      // EMPLOYEE DATA 
      .addCase(getEmplyeeAppointments.fulfilled, (state, { payload }) => {
        state.schadule_appointments = payload?.schadule_appointments ?? []
        state.appointmentLoading = false
      })
      .addCase(setEmplyeeAppointments.fulfilled, (state, { payload }) => {
        state.schadule_appointments = payload?.data ?? []
      })
      .addCase(getEmplyeeRequestedAppointments.fulfilled, (state, { payload }) => {
        state.requestedAppointments = payload?.data ?? []
      })
      .addCase(unMountFrontAppointments.fulfilled, (state, { payload }) => {
        state.schadule_appointments = payload?.data ?? []
        state.requestedAppointments = payload?.data ?? []
      })
      .addCase(getEmplyeeAppointments.pending, (state) => {
        console.log("LOADING...")
        state.appointmentLoading = true
      })
      .addCase(getEmplyeeAppointments.rejected, (state) => {
        console.log("REJECTED...")
        state.appointmentLoading = false
      })
      .addCase(deleteEmployeeScheduleAppointment.fulfilled, (state, { payload }) => {
        console.log(`DELETED APPOINTMENT ID ${payload?.status}`)
      })
      .addCase(inviteUser.fulfilled, (state, { payload }) => {
        displaySuccessToast(payload?.message)
      })
      .addCase(denyEmpRequestedAppointment.fulfilled, (state, { payload }) => {
        console.log(`--------DENYED SUCCESSFULLY APPOINTMENT ID-------- ${payload?.id}`)
      })
      .addCase(setDenyRequestedAppointments.fulfilled, (state, { payload }) => {
        state.requestedAppointments = payload?.data ?? []
      })
  }
})

export default frontMeetingsSlice.reducer
