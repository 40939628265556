// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { groups } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getAdminGroups = createAsyncThunk('clinicGroups/getAdminGroups', async () => {
  const response = await axiosRequest.get(groups.CLINIC_GROUPS)
  return { data: response?.data?.data }
})

export const setGroups = createAsyncThunk('clinicGroups/setGroups', async (data) => {
  return { data }
})

export const addGroup = createAsyncThunk('clinicGroups/addGroup', async (data, { dispatch }) => {
  const response = await axiosRequest.post(groups.CLINIC_ADD_GROUP, data)
  console.log('-------------GroupStore--response---', response)
  dispatch(getAdminGroups())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const updateGroup = createAsyncThunk('clinicGroups/updateGroup', async (data, { dispatch }) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  const response = await axiosRequest.post(groups.CLINIC_EDIT_GROUP, data, config)
  dispatch(getAdminGroups())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const deleteGroup = createAsyncThunk('clinicGroups/deleteGroup', async (id) => {
  const response = await axiosRequest.post(groups.CLINIC_DELETE_GROUP, { id })
  return { id, status: response.data?.status }
})
// POSTS
export const getGroupPosts = createAsyncThunk('clinicGroups/getGroupPosts', async (id) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_POSTS, { group_id: id })
  return { data: response?.data?.data }
})

export const setGroupPosts = createAsyncThunk('clinicGroups/setGroupPosts', async (data) => {
  return { data }
})

export const createGroupPost = createAsyncThunk('clinicGroups/createGroupPost', async ({ data, group_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_CREATE_POST, data)
  dispatch(getGroupPosts(group_id))
  return { status: response?.data?.status }
})

export const editGroupPost = createAsyncThunk('clinicGroups/editGroupPost', async ({ data, group_id }, { dispatch }) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_EDIT_POST, data)
  dispatch(getGroupPosts(group_id))
  return { status: response?.data?.status }
})

export const deleteGroupPost = createAsyncThunk('clinicGroups/deleteGroupPost', async (id) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_DELETE_POST, { id })
  return { id, status: response.data?.status }
})

export const getGroupPostComments = createAsyncThunk('clinicGroups/getGroupPostComments', async (id) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_POST_COMMENTS, { post_id: id })
  return { data: response?.data?.data }
})

export const setGroupPostComments = createAsyncThunk('clinicGroups/setGroupPostComments', async (data) => {
  return { data }
})

export const deleteGroupPostComment = createAsyncThunk('clinicGroups/deleteGroupPostComment', async (id) => {
  const response = await axiosRequest.post(groups.CLINIC_GROUP_POST_DELETE_COMMENT, { id })
  console.log("delete comments response", response.data)
  return { id, status: response?.data?.status }
})

export const adminGroupsSlice = createSlice({
  name: 'adminGroups',
  initialState: {
    data: [],
    loading: true,
    groupPosts: [],
    postComments: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminGroups.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getAdminGroups.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminGroups.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(setGroups.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(addGroup.fulfilled, (state, { payload }) => {
        console.log(`CREATED GROUP ${payload?.status}`)
        state.status = payload?.status ?? []
      })
      .addCase(updateGroup.fulfilled, (state, { payload }) => {
        console.log(`UPDATED GROUP ${payload?.status}`)
      })
      .addCase(deleteGroup.fulfilled, (state, { payload }) => {
        console.log(`DELETED GROUP ${payload.id}`)
      })

      .addCase(getGroupPosts.fulfilled, (state, { payload }) => {
        state.groupPosts = payload?.data ?? []
      })
      .addCase(setGroupPosts.fulfilled, (state, { payload }) => {
        state.groupPosts = payload?.data ?? []
      })
      .addCase(createGroupPost.fulfilled, (state, { payload }) => {
        displaySuccessToast('Post has been Created Successfully')
        console.log(`CREATED GROUP POST ${payload?.status}`)
        state.status = payload?.status ?? []
      })
      .addCase(editGroupPost.fulfilled, (state, { payload }) => {
        displaySuccessToast('Post has been Edited Successfully')
        console.log(`EDITED GROUP POST ${payload?.status}`)
        state.status = payload?.status ?? []
      })
      .addCase(deleteGroupPost.fulfilled, (state, { payload }) => {
        console.log(`DELETED GROUP POST ${payload?.id}`)
        state.status = payload?.status ?? []
      })
      .addCase(getGroupPostComments.fulfilled, (state, { payload }) => {
        state.postComments = payload?.data ?? []
      })
      .addCase(setGroupPostComments.fulfilled, (state, { payload }) => {
        state.postComments = payload?.data ?? []
      })
      .addCase(deleteGroupPostComment.fulfilled, (state, { payload }) => {
        console.log(`DELETED GROUP COMMENT ${payload?.id}`)
        state.status = payload?.status ?? []
      })
  }
})

export default adminGroupsSlice.reducer
