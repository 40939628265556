import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { assignments } from '../APIRoutes'
import axiosRequest from '../APIRoutes/axiosCall'

export const assignTaskToUser = createAsyncThunk('assignments/assignTaskToUser', async (data) => {
  console.log('---------assignTaskToUser---data--', data)
  const response = await axiosRequest.post(assignments.CLINIC_ASSIGN_TASK_TO_USER, data)
  console.log("==response==", response.data)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    reviews: [],
    averageRating: 0,
    classAverageRating: 0,
    classReviews: [],
    lessonAverageRating: 0,
    lessonReviews: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(assignTaskToUser.fulfilled, (state, { payload }) => {
        console.log('STATUS', payload?.status)
      })
  }
})

export default assignmentsSlice.reducer
