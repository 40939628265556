// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { common } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getUrgentAlerts = createAsyncThunk('homePageUrgentAlerts/getUrgentAlerts', async () => {
  const response = await axiosRequest.get(common.URGENT_ALERTS)
  console.log('----response--getUrgentAlerts----', response?.data)
  return { data: response?.data?.data }
})
export const getAllBlogPosts = createAsyncThunk('homePageUrgentAlerts/getAllBlogPosts', async () => {
  const response = await axiosRequest.post(common.GET_All_BlOGPOSTS)
  console.log('----response--getAllBlogPosts----', response?.data)
  return { data: response?.data?.data }
})

export const homePageUrgentALertsSlice = createSlice({
  name: 'homePageUrgentAlerts',
  initialState: {
    data: [],
    dataStat: {},
    loading: true,
    assignmentChildTasks: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUrgentAlerts.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getUrgentAlerts.pending, (state) => {
        console.log('LOADING...')
        state.loading = true
      })
      .addCase(getUrgentAlerts.rejected, (state) => {
        console.log('REJECTED...')
        state.loading = false
      })
      .addCase(getAllBlogPosts.pending, (state) => {
        state.loading = true
        state.error = null // Reset error state
      })
      .addCase(getAllBlogPosts.fulfilled, (state, { payload }) => {
        state.loading = false
        state.data = payload.data // Assuming the response data is stored in payload.data
      })
      .addCase(getAllBlogPosts.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload // Access the error message from the rejection payload
      })
  }
})

export default homePageUrgentALertsSlice.reducer
