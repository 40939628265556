import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { opentest } from '../../../../APIRoutes'

export const getOpenUserTestData = createAsyncThunk(' clinicOpenTest/getOpenUserTestData', async () => {
  const response = await axiosRequest.get(opentest.OPEN_USER_TEST_DATA)
  return { data: response?.data?.data }
})

export const getOpenTestData = createAsyncThunk(' clinicOpenTest/getOpenTestData', async () => {
  const response = await axiosRequest.get(opentest.OPEN_TEST_DATA)
  return { data: response?.data?.data }
})

export const getOpenTestscorewise = createAsyncThunk(' clinicOpenTest/getOpenTestscorewise', async () => {
  const response = await axiosRequest.get(opentest.OPEN_TEST_SCOREWISE)
  return { data: response?.data?.data }
})

export const deleteOpenTests = createAsyncThunk('adminContents/deleteOpenTests', async (id) => {
  const response = await axiosRequest.post(opentest.DELETE_OPEN_TEST, { id })
  return { id, status: response.data?.status }
})

// Create the slice
export const  clinicOpenTestSlice = createSlice({
  name: 'clinicOpenTest',
  initialState: {
    testData: [],
    loading: false,
    error: null,
    openTestData: [],
    openTestLoading: false,
    openTestError: null,
    openTestScoreData: [],
    openTestScoreLoading: false,
    openTestScoreError: null

  },
  reducers: {},
  extraReducers: builder => {
    builder
    // Handle pending state
    .addCase(getOpenUserTestData.pending, (state) => {
      state.loading = true
      state.error = null
    })

    // Handle fulfilled state
    .addCase(getOpenUserTestData.fulfilled, (state, { payload }) => {
      state.loading = false
      state.testData = payload?.data // Update the test data
    })

    // Handle rejected state
    .addCase(getOpenUserTestData.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload?.error.message // Capture the error message
    })
    // Handle pending state
    .addCase(getOpenTestData.pending, (state) => {
      state.openTestLoading = true
      state.openTestError = null
    })

    // Handle fulfilled state
    .addCase(getOpenTestData.fulfilled, (state, { payload }) => {
      state.openTestLoading = false
      state.openTestData = payload?.data
    })

    // Handle rejected state
    .addCase(getOpenTestData.rejected, (state, { payload }) => {
      state.openTestLoading = false
      state.openTestError = payload?.error.message
    })

    // Handle pending state
    .addCase(getOpenTestscorewise.pending, (state) => {
      state.openTestScoreLoading = true
      state.openTestScoreError = null 
    })

    // Handle fulfilled state
    .addCase(getOpenTestscorewise.fulfilled, (state, { payload }) => {
      state.openTestScoreLoading = false
      state.openTestScoreData = payload?.data
    })

    // Handle rejected state
    .addCase(getOpenTestscorewise.rejected, (state, { payload }) => {
      state.openTestScoreLoading = false
      state.openTestScoreError = payload?.error.message
    })
    .addCase(deleteOpenTests.pending, (state) => {
      state.loading = true // Set loading to true when the delete action is pending
      state.error = null   // Clear any previous errors
    })
    .addCase(deleteOpenTests.fulfilled, (state) => {
      state.loading = false // Set loading to false when the action is fulfilled
    })
    .addCase(deleteOpenTests.rejected, (state, action) => {
      state.loading = false // Set loading to false when the action is rejected
      state.error = action.error.message // Capture the error message
    })
  }
})

// Export the reducer
export default  clinicOpenTestSlice.reducer
