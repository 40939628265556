//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { challenges } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

export const getFrontChallenges = createAsyncThunk('frontChallenges/getFrontChallenges', async () => {
  const response = await axiosRequest.post(challenges.FRONT_ALL_CHALLENGES)
  console.log('---------getFrontChallenges-----response--', response?.data)

  return { data: response?.data?.data ?? [], params: { page: 1 } }
})

export const getCommentsDetails = createAsyncThunk('frontChallenges/getCommentsDetails', async (id) => {
  const response = await axiosRequest.post(challenges.FRONT_COMMENTS_DETAILS, { id })
  return {
    data: response?.data?.data ?? {},
    comments: response?.data?.comments ?? [],
    participants: response?.data?.participants ?? []
  }
})
//logs
export const updateLogs = createAsyncThunk('newDiscovery/updateLogs', async (params) => {
  for (const pair of params.entries()) {
    console.log('----pair----', pair)
  }
  const response = await axiosRequest.post(challenges.UPDATE_LOGS, params)
  console.log('----updateLogs----response---', response)
  return { status: response.data?.status ?? false, message: response.data?.message ?? '' }
})
// Delete the Comments
export const deleteComment = createAsyncThunk('frontChallenges/deleteComment', async ({ comment_id, post_id }) => {
  console.log('------deleteComment---', comment_id, post_id)
  const response = await axiosRequest.post(challenges.FRONT_DELETE_POST_COMMENT, {id: comment_id})
  console.log('---------response-------', response?.data)
  if (response?.data?.status) {
    dispatch(getCommentsDetails(post_id))
  }
  return {
    status: response?.data?.status ?? ''
  }
})
// Delete the Challanges
export const deleteChallenge = createAsyncThunk('frontChallenges/deleteChallenge', async (id, { dispatch }) => {
  console.log('------deleteComment---', id)
  const response = await axiosRequest.post(challenges.FRONT_DELETE_CHALLENGE, { id })
  console.log('---------response-------', response?.data)
  if (response?.data?.status) {
    dispatch(getFrontChallenges())
  }
  return {
    status: response?.data?.status ?? ''
  }
})
// Submit The Comments of Challanges
export const submitChallaneComment = createAsyncThunk('frontChallenges/submitChallaneComment', async (data) => {
  const response = await axiosRequest.post(challenges.FRONT_SUBMIT_COMMENTS, data)
  console.log("----Submit Comment Response----", response?.data?.message)
  return { message: response?.data?.message }
})
// Get Challenge Gallery

export const getChallengeGallery = createAsyncThunk('frontChallenges/getChallengeGallery', async () => {
  const response = await axiosRequest.get(challenges.FRONT_GET_CHALLENGE_GALLERY)
  console.log("-------Gallery Images--------", response)
  return { data: response?.data?.data ?? [] }
})
// Get Challenge Complete Gallery

export const getChallengeCompleteGallery = createAsyncThunk('frontChallenges/getChallengeCompleteGallery', async () => {
  const response = await axiosRequest.get(challenges.FRONT_GET_CHALLENGE_COMPLETE_GALLERY)
  console.log("-------Challenge Complete Gallery Images--------", response)
  return { data: response?.data?.data ?? [] }
})
// CHALLENGE CATEGORIES
export const getCategories = createAsyncThunk('frontChallenges/getCategories', async () => {
  const response = await axiosRequest.get(challenges.FRONT_CHALLENGE_CATEGORIES)
  return { data: response?.data?.data ?? [] }
})

export const setCategories = createAsyncThunk('frontChallenges/setCategories', async (data) => {
  return { data }
})
// Create New Challanges
export const createNewChallenge = createAsyncThunk('frontChallenges/createNewChallenge', async (data, { dispatch }) => {
  const response = await axiosRequest.post(challenges.FRONT_CREATE_CHALLENGE, data)
  if (response?.data?.status) {
    dispatch(getFrontChallenges())
  }
  return { message: response?.data?.message, status: response?.data?.status }
})
//Update Challenes
export const updateNewChallenge = createAsyncThunk('frontChallenges/updateNewChallenge', async (data, { dispatch }) => {
  const response = await axiosRequest.post(challenges.FRONT_UPDATE_CHALLENGE, data)
  if (response?.data?.status) {
    dispatch(getFrontChallenges())
  }
  return { message: response?.data?.message, status: response?.data?.status }
})

// I DID IT 

export const challengeComplete = createAsyncThunk('frontChallenges/challengeComplete', async (id) => {
  const response = await axiosRequest.post(challenges.FRONT_DID_IT_CHALLENGE, { id })
  console.log('-------challengeComplete-------', response)
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? ''
  }
})
// Join Challange
export const challengeJoin = createAsyncThunk('frontChallenges/challengeJoin', async (id) => {
  const response = await axiosRequest.post(challenges.FRONT_JOIN_CHALLENGE, { id })
  console.log('-------challengeJoin-------', response)
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? ''
  }
})
// Leave Challange
export const challengeLeave = createAsyncThunk('frontChallenges/challengeLeave', async (id) => {
  const response = await axiosRequest.post(challenges.FRONT_LEAVE_CHALLENGE, { id })
  console.log('-------challengeLeave-------', response)
  return {
    status: response?.data?.status ?? false,
    message: response?.data?.message ?? ''
  }
})
export const setChallenges = createAsyncThunk('frontChallenges/setChallenges', async (data) => {
  return { data }
})

// SET CHALLENGES STATUS
export const setChallengesStatus = createAsyncThunk('frontChallenges/setChallengesStatus', async (data) => {
  return { data }
})

// CHALLENGE STATE
export const getChallengesState = createAsyncThunk('frontChallenges/getChallengesState', async () => {
  const response = await axiosRequest.get(challenges.FRONT_CHALLENGE_STATS)
  console.log('---getChallengesState---response---', response?.data)
  return {
    Challengestats: response?.data?.stats ?? {}
  }
})
// // Get Patient Statistics 

// export const getpatientStatistics = createAsyncThunk('frontChallenges/getpatientStatistics', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_STATISTICS, userid)
//     console.log("======== Statistics Response=========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })

// // Get Patient Activity 

// export const getpatientActivity = createAsyncThunk('frontChallenges/getpatientActivity', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_ACTIVITY, userid)
//     console.log("======== Activity Response =========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })
export const frontChallengeslice = createSlice({
  name: 'frontChallenges',
  initialState: {
    frontAllChallenges: [],
    totalChallenges: 0,
    params: {},
    challengesLoading: true,
    challengeGallery: [],
    challangeCompleteGallery: [],
    categories: [],
    // patientStatistics: [],
    // patientActivity:[],
    data: {},
    comments: [],
    participants: [],
    Challengestats: {},
    challengeStatsLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontChallenges.fulfilled, (state, { payload }) => {
        state.frontAllChallenges = payload?.data ?? []
        state.totalChallenges = payload?.data.length
        state.params = payload?.params
        state.challengesLoading = false
      })
      .addCase(getFrontChallenges.pending, (state) => {
        state.challengesLoading = true
      })
      .addCase(getFrontChallenges.rejected, (state) => {
        state.challengesLoading = false
      })
      .addCase(getCommentsDetails.fulfilled, (state, { payload }) => {
        state.data = payload?.data
        state.comments = payload?.comments
        state.participants = payload?.participants
      })

      .addCase(setChallenges.fulfilled, (state, { payload }) => {
        state.frontAllChallenges = payload?.data ?? []
      })

      .addCase(setChallengesStatus.fulfilled, (state, { payload }) => {
        state.Challengestats = payload?.data ?? []
      })
      //logs
      .addCase(updateLogs.fulfilled, ({ payload }) => {
        console.log(payload?.status)
      })
      // SUBMIT the Challanges Comments
      .addCase(submitChallaneComment.fulfilled, () => {
        displaySuccessToast('Comment has been Added Successfully')
      })
      // Create New Challenge
      .addCase(createNewChallenge.fulfilled, () => {
        displaySuccessToast('Challange has been Created Successfully')
      })
      .addCase(updateNewChallenge.fulfilled, () => {
        displaySuccessToast('Challange has been Updated Successfully')
      })
      // // Update Patient Setting
      // .addCase(updateClinicEmployeeSetting.fulfilled, (state, { payload }) => {
      //     console.log("====Update Patient Setting======", payload.status)
      // })
      // // Upate Patients Chart Data
      // .addCase(getClinicChartData.fulfilled, (state, { payload }) => {
      //     state.getPatientChartData = payload?.data
      //     console.log("==========getPatientChartData=========", state.getPatientChartData)
      // })
      // // Get Challenge Gallery
      .addCase(getChallengeGallery.fulfilled, (state, { payload }) => {
        state.challengeGallery = payload?.data ?? ''
      })
      // // Get Challenge Complete Gallery
      .addCase(getChallengeCompleteGallery.fulfilled, (state, { payload }) => {
        state.challangeCompleteGallery = payload?.data ?? ''
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(setCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(getChallengesState.fulfilled, (state, { payload }) => {
        state.Challengestats = payload?.Challengestats ?? {}
        state.challengeStatsLoading = false
      })
      .addCase(getChallengesState.pending, (state) => {
        state.challengeStatsLoading = true
      })
      .addCase(getChallengesState.rejected, (state) => {
        state.challengeStatsLoading = false
      })
      .addCase(deleteComment.fulfilled, () => {
        displaySuccessToast('Comment has Been Deleted Successfully')
      })
      .addCase(deleteChallenge.fulfilled, () => {
        displaySuccessToast('Challange has Been Deleted Successfully')
      })
    //   // Get Patient Activity
    //   .addCase(getpatientActivity.fulfilled, (state, {payload}) => {
    //     state.patientActivity = payload?.data
    //     console.log("====== Patients Activity======", state?.patientActivity)
    // })
  }

})
export default frontChallengeslice.reducer