//** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { meeting } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

// Get All Emplyees Detail
export const getClinicEmplyees = createAsyncThunk('clinicEmployee/getClinicEmplyees', async () => {
    const response = await axiosRequest.get(meeting.CLINIC_ALL_EMPLOYEES)
    return { data: response?.data?.data }
})


// Assign Patients to Emplyees
export const assignPatientsToEmployee = createAsyncThunk('clinicEmployee/assignPatientsToEmployee', async (data) => {
    const response = await axiosRequest.post(meeting.ASSIGN_PATIENTS_TO_EMPLOYEES, data)
    console.log('----assignPatientsToEmployee---response--', response?.data)
    return { status: response?.data?.status }
})
// Update Employee Details

export const updateClinicEmployee = createAsyncThunk('clinicEmployee/updateClinicEmployee', async (data, { dispatch }) => {
    const response = await axiosRequest.post(meeting.CLINIC_EDIT_EMPLOYEES, data)
    if (response?.data?.status) {
        dispatch(getClinicEmplyees())
    }
    return { status: response?.data?.status }
})

export const addNewEmployee = createAsyncThunk('clinicEmployee/addNewEmployee', async (data) => {
    const response = await axiosRequest.post(meeting.CLINIC_ADD_EMPLOYEES, data)
    return { status: response?.data?.status ?? false, message: response?.data?.message ?? "Something is wrong" }
})

// // Get All Packages Details
export const getAllPackages = createAsyncThunk('clinicEmployee/getAllPackages', async () => {
    const response = await axiosRequest.get(meeting.GET_ALL_PACKAGES)
    //console.log("----get response----", response?.data?.data)
    return { data: response?.data?.data }
})

// // Update Employee Settings

export const updateClinicEmployeeSetting = createAsyncThunk('clinicEmployee/updateClinicEmployeeSetting', async (data) => {
    const response = await axiosRequest.post(meeting.CLINIC_EDIT_EMPLOYEES_SETTINGS, data)
    return { status: response?.data?.status }
})

// // Get Chart Detail for Patient

// export const getClinicChartData = createAsyncThunk('clinicPatients/getClinicChartData', async (userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_CHART_DATA, userid)
//     return { data: response?.data?.data ?? [] }
// })

// // Get Patient Statistics 

// export const getpatientStatistics = createAsyncThunk('clinicPatients/getpatientStatistics', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_STATISTICS, userid)
//     console.log("======== Statistics Response=========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })

// // Get Patient Activity 

// export const getpatientActivity = createAsyncThunk('clinicPatients/getpatientActivity', async(userid) => {
//     const response = await axiosRequest.post(meeting.CLINIC_PATIENTS_ACTIVITY, userid)
//     console.log("======== Activity Response =========", response?.data?.status)
//     return {data : response?.data?.data ?? []}
// })
export const clinicEmployeeSlice = createSlice({
    name: 'clinicEmployee',
    initialState: {
        clinicAllEmployees: null,
        employeeLoading: true,
        AllPackages: []
        // getPatientChartData: [],
        // patientStatistics: [],
        // patientActivity:[]
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getClinicEmplyees.fulfilled, (state, { payload }) => {
                state.clinicAllEmployees = payload?.data ?? []
                state.employeeLoading = false
            })
            .addCase(getClinicEmplyees.pending, (state) => {
                state.employeeLoading = true
            })
            .addCase(getClinicEmplyees.rejected, (state) => {
                state.employeeLoading = false
            })
            // Assign Patients to Emplyees
            .addCase(assignPatientsToEmployee.fulfilled, (state, { payload }) => {
               if (payload?.status) {
                displaySuccessToast('Patients Assign to Employee Successfully')
               }
            })
            .addCase(updateClinicEmployee.fulfilled, (state, { payload }) => {
                console.log("-----Employee Updated Successfully-----", payload.status)
                displaySuccessToast('Employee Updated Successfully')
            })
            // // Get ALL Packages
            .addCase(getAllPackages.fulfilled, (state, { payload }) => {
                state.AllPackages = payload?.data
                console.log("====Get All Paitents======", state.AllPackages)
            })
            // // Update Patient Setting
            .addCase(updateClinicEmployeeSetting.fulfilled, (state, { payload }) => {
                console.log("====Update Patient Setting======", payload.status)
                displaySuccessToast('Emplyee Setting Updated Successfully')
            })
            // // Upate Patients Chart Data
            // .addCase(getClinicChartData.fulfilled, (state, { payload }) => {
            //     state.getPatientChartData = payload?.data
            //     console.log("==========getPatientChartData=========", state.getPatientChartData)
            // })
            // // Get Patient Statistics
            // .addCase(getpatientStatistics.fulfilled, (state, {payload}) => {
            //     state.patientStatistics = payload?.data ?? ''
            //     console.log("====== Patients Statistics======", state?.patientStatistics)
            // })
            //   // Get Patient Activity
            //   .addCase(getpatientActivity.fulfilled, (state, {payload}) => {
            //     state.patientActivity = payload?.data
            //     console.log("====== Patients Activity======", state?.patientActivity)
            // })
            .addCase(addNewEmployee.fulfilled, (state, { payload }) => {
                console.log(`ADDED DATA ${JSON.stringify(payload?.status)}`)
            })
    }

})
export default clinicEmployeeSlice.reducer