import { API_HOST } from "./config"
export default {
  // FAVORITES ROUTES
  FRONT_RECOMMENDED: `${API_HOST}front/favorites/recommended`,
  ADMIN_ADD_APPOINTMENT: `${API_HOST}admin/video/create_appointment`,
  FRONT_FAVORITE_UNFAVORITE: `${API_HOST}front/favorites/favourite_unfavourite`,
  UPDATE_LOGS_FAVORITE: `${API_HOST}front/dashboard/update_logs`,
  FRONT_FAVORITE: `${API_HOST}front/favorites/get_favorities`,
  INVITE_PEOPLE_LOGS: `${API_HOST}front/dashboard/update_logs`
}
