import { useSkin } from '@hooks/useSkin'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import CustomLoader from '../reuseable/CustomLoader'

const Loader = () => {
  const { skin } = useSkin()

  return (
    <div className={`${skin === 'dark' ? 'dark' : 'light'}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden'}}>
      <div style={{marginTop:'200px'}}>
        <LazyLoadImage
          src={skin === 'dark' ? 'https://legacy.healmb.com/newui/images/logdarkmode.webp' : 'https://legacy.healmb.com/newui/images/loglightpmode.webp'}
          alt='logo'
          width='170'
          height='170'
          fetchpriority='high'
        />
      </div>
      <div style={{marginTop:"-200px"}}>
        <CustomLoader />
      </div>
    </div>
  )
}

export default Loader
