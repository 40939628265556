import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_SMS_CONFIG_SMS_AUTH: `${API_HOST}admin/sms_setting/sms_sid_auth`,
  ADMIN_SMS_CONFIG_UPDATE_SMS_AUTH: `${API_HOST}admin/sms_setting/update_sms_sid_auth`,
  ADMIN_SMS_CONFIG_NUMBERS_LIST: `${API_HOST}admin/sms_setting/sms_numbers`,
  GET_PUSH_NOTIFICATION: `${API_HOST}admin/push_setting/notification_hooks`,
  GET_PUSH_NOTIFICATION_DATA: `${API_HOST}admin/push_setting/push_templates`,
  ADD_PUSH_NOTIFICATION_DATA: `${API_HOST}admin/push_setting/add_push_template`,
  DELETE_PUSH_NOTIFICATION_DATA: `${API_HOST}admin/push_setting/delete_push`,
  UPDATE_HOOKS: `${API_HOST}admin/push_setting/update_notification_hook`,
  EDIT_PUSH_NOTIFICATION_DATA: `${API_HOST}admin/push_setting/edit_push_template`,
  ADMIN_SMS_CONFIG_ADD_NUMBER: `${API_HOST}admin/sms_setting/add_sms_number`,
  ADMIN_SMS_CONFIG_EDIT_NUMBER: `${API_HOST}admin/sms_setting/edit_sms_number`,
  ADMIN_SMS_CONFIG_DELETE_NUMBER: `${API_HOST}admin/sms_setting/delete_sms_number`,
  ADMIN_SCHEDULED_NOTIFICATION: `${API_HOST}admin/push_setting/get_scheduled_notfication`,
  ADMIN_SCHEDULED_NOTIFICATION_DELETE: `${API_HOST}admin/push_setting/delete_scheduled_notfication`,
  // ADMIN SMS TEMPLATES
  ADMIN_SMS_CONFIG_SMS_TEMPLATES: `${API_HOST}admin/sms_setting/sms_templates`,
  ADMIN_SMS_CONFIG_ADD_SMS_TEMPLATE: `${API_HOST}admin/sms_setting/add_sms_template`,
  ADMIN_SMS_CONFIG_EDIT_SMS_TEMPLATE: `${API_HOST}admin/sms_setting/edit_sms_template`,
  // ADMIN SMS LOGS
  ADMIN_SMS_CONFIG_SMS_LOGS: `${API_HOST}admin/sms_setting/sms_logs`,
  ADMIN_PUSH_CONFIG_PUSH_LOGS: `${API_HOST}admin/push_setting/push_logs`,
  ADMIN_SMS_CONFIG_SEND_SMS: `${API_HOST}admin/sms_setting/send_sms`,
  ADMIN_SMS_CONFIG_SEND_BULK_SMS: `${API_HOST}admin/sms_setting/send_bulk_sms`,
  ADMIN_SMS_CONFIG_GET_CLINIC_USERS: `${API_HOST}admin/users/clinic_users`
}
