import { API_HOST } from './config'
export default {
  // FRONT ROUTES
  UPDATE_LOGS_Account: `${API_HOST}front/dashboard/update_logs`,

  FRONT_ACCONUTS_DETAILS: `${API_HOST}front/account/get_user_detail`,
  FRONT_ACCONUTS_UPDATE: `${API_HOST}front/account/update_user_detail`,
  FRONT_ACCONUTS_IMAGE_UPDATE: `${API_HOST}front/account/update_user_image`,
  FRONT_ACCONUTS_PASSWORD_UPDATE: `${API_HOST}front/account/update_password`,
  FRONT_ACCONUTS_NOTIFICATIONS: `${API_HOST}front/account/get_notifications_setting`,
  FRONT_ACCONUTS_NOTIFICATIONS_UPDATE: `${API_HOST}front/account/update_notification_setting`,
  FRONT_ACCONUTS_SUBSCRIPTON_PLAN: `${API_HOST}front/account/subscription`,
  GET_ACCOUNT_TABS: `${API_HOST}front/account/get_tabs`,
  FRONT_PACKAGE_PLAN: `${API_HOST}front/authentication/upgrade`,
  UPGRADE_PACKAGE_PLAN: `${API_HOST}front/account/transaction_detail`,
  CANCEL_PACKAGE_SUBSRIPTION: `${API_HOST}front/account/cancel_subscription`,
  UPDATE_ALL_NOTIFICATIONS: `${API_HOST}front/notification/update_all_notification_setting`
}
