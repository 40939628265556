import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { challenges } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'
export const getChallenges = createAsyncThunk('adminChallenges/getChallenges', async () => {
  const response = await axiosRequest.get(challenges.ADMIN_CHALLENGES)
  return { data: response?.data?.data }
})

export const setChallenges = createAsyncThunk('adminChallenges/setChallenges', async (data) => {
  return { data }
})

export const addChallenge = createAsyncThunk('adminChallenges/addChallenge', async (data, { dispatch }) => {
  const response = await axiosRequest.post(challenges.ADMIN_ADD_CHALLENGE, data)
  dispatch(getChallenges())
  return { status: response?.data?.status ?? false, message: response?.data?.message }
})

export const updateChallenge = createAsyncThunk('adminChallenges/updateChallenge', async (data) => {
  const response = await axiosRequest.post(challenges.ADMIN_EDIT_CHALLENGE, data)
  return { status: response?.data?.status ?? false, message: response?.data?.message }
})

export const challengeDetail = createAsyncThunk('adminChallenges/challengeDetail', async (id) => {
  console.log('challenge detail id', id)
  const response = await axiosRequest.post(challenges.ADMIN_CHALLENGE_DETAIL, { id })
  console.log('challenge detail response', response.data)
  return {
    challengeDetail: response?.data?.data ?? {},
    comments: response?.data?.comments ?? [],
    participants: response?.data?.participants ?? []
  }
})

export const deleteChallenge = createAsyncThunk('adminChallenges/deleteChallenge', async (id) => {
  const response = await axiosRequest.post(challenges.ADMIN_DELETE_CHALLENGE, { id })
  return { id, status: response.data?.status }
})

// CHALLENGE CATEGORIES
export const getCategories = createAsyncThunk('adminChallenges/getCategories', async () => {
  const response = await axiosRequest.get(challenges.ADMIN_CHALLENGE_CATEGORIES)
  return { data: response?.data?.data ?? [] }
})

export const setCategories = createAsyncThunk('adminChallenges/setCategories', async (data) => {
  return { data }
})

export const addCategory = createAsyncThunk('adminChallenges/addCategory', async (data, { dispatch }) => {
  const response = await axiosRequest.post(challenges.ADMIN_ADD_CHALLENGE_CATEGORY, { ...data })
  dispatch(getCategories())
  return { data: response?.data?.data ?? {}, status: response?.data?.status ?? false }
})

export const updateCategory = createAsyncThunk('adminChallenges/updateCategory', async (data) => {
  const response = await axiosRequest.post(challenges.ADMIN_EDIT_CHALLENGE_CATEGORY, { ...data })
  return { data, status: response.data?.status }
})

export const deleteCategory = createAsyncThunk('adminChallenges/deleteCategory', async (id) => {
  const response = await axiosRequest.post(challenges.ADMIN_DELETE_CHALLENGE_CATEGORY, { id })
  return { id, status: response?.data?.status }
})

// CHALLENGE IMAGES
export const getChallengeImages = createAsyncThunk('adminChallenges/getChallengeImages', async (type) => {
  const url = type === 'image' ? challenges.ADMIN_CHALLENGE_IMAGES : challenges.ADMIN_CHALLENGE_TROPHY_IMAGES
  const response = await axiosRequest.get(url)
  console.log("challenge images", response.data)
  return { data: response?.data?.data ?? [] }
})

export const addChallengeImages = createAsyncThunk('adminChallenges/addChallengeImages', async ({ type, formdata, dispatch }) => {
  const url = type === 'image' ? challenges.ADMIN_ADD_CHALLENGE_IMAGE : challenges.ADMIN_ADD_CHALLENGE_TROPHY_IMAGE
  const response = await axiosRequest.post(url, formdata)
  dispatch(getChallengeImages(type))
  return { status: response?.data?.status ?? false }
})

export const deleteChallengeImages = createAsyncThunk('adminChallenges/deleteChallengeImages', async ({ type, id }) => {
  const url = type === 'image' ? challenges.ADMIN_DELETE_CHALLENGE_IMAGE : challenges.ADMIN_DELETE_CHALLENGE_TROPHY_IMAGE
  const response = await axiosRequest.post(url, { id })
  return { status: response?.data?.status ?? false }
})

export const setChallengeImages = createAsyncThunk('adminChallenges/setChallengeImages', async (data) => {
  return { data }
})

export const challengesSlice = createSlice({
  name: 'adminChallenges',
  initialState: {
    params: {},
    loading: true,
    data: [],
    challengeDetail: {},
    categories: [],
    comments: [],
    participants: [],
    challengeImages: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getChallenges.fulfilled, (state, { payload }) => {
        state.params = payload?.params ?? {}
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setChallenges.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(addChallenge.fulfilled, (state, { payload }) => {
        console.log(`CREATED CHALLENGE ${payload?.status}`)
        state.status = payload?.status ?? false
      })
      .addCase(getChallenges.rejected, (state) => {
        console.log("===rejected===")
        state.loading = false
      })
      .addCase(getChallenges.pending, (state) => {
        console.log("===pending===")
        state.loading = true
      })
      .addCase(updateChallenge.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CHALLENGE ${payload?.status}`)
        // displaySuccessToast('Challenge has been Edited Successfully')
      })
      .addCase(challengeDetail.fulfilled, (state, { payload }) => {
        state.challengeDetail = payload?.data
        state.comments = payload?.comments
        state.participants = payload?.participants
      })
      .addCase(deleteChallenge.fulfilled, (state, { payload }) => {
        console.log(`DELETED CHALLENGE ID ${payload?.id}`)
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(setCategories.fulfilled, (state, { payload }) => {
        state.categories = payload?.data ?? []
      })
      .addCase(addCategory.fulfilled, (state, { payload }) => {
        console.log(`ADDED CATEGORY DATA ${JSON.stringify(payload?.data)}`)
        displaySuccessToast('Category has been Added Successfully')
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        console.log(`DELETED CATEGORY ID ${payload?.id}`)
      })
      .addCase(updateCategory.fulfilled, (state, { payload }) => {
        console.log(`UPDATED CATEGORY ${JSON.stringify(payload?.data)}`)
      })
      .addCase(getChallengeImages.fulfilled, (state, { payload }) => {
        state.challengeImages = payload?.data
      })
      .addCase(addChallengeImages.fulfilled, (state, { payload }) => {
        displaySuccessToast('Image has been Added Successfully')
        console.log(`ADDED CHALLENGE IMAGE ${payload?.status}`)
        state.status = payload?.status

      })
      .addCase(deleteChallengeImages.fulfilled, (state, { payload }) => {
        console.log(`DELETED CHALLENGE IMAGE ${payload?.status}`)
        state.status = payload?.status
      })
      .addCase(setChallengeImages.fulfilled, (state, { payload }) => {
        state.challengeImages = payload?.data ?? []
      })
  }
})

export default challengesSlice.reducer
