// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { tasks } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getAdminTasks = createAsyncThunk('clinicTasks/getAdminTasks', async () => {
  const response = await axiosRequest.get(tasks.CLINIC_TASKS)
  return { data: response?.data?.data }
})

export const setTasks = createAsyncThunk('clinicTasks/setTasks', async (data) => {
  return { data }
})

export const updateTask = createAsyncThunk('clinicTasks/updateTask', async (data) => {
  const response = await axiosRequest.post(tasks.CLINIC_EDIT_TASK, data)
  // dispatch(getAdminTasks())
  return { status: response?.data?.status, message: response?.data?.message }
})

export const deleteTask = createAsyncThunk('clinicTasks/deleteTask', async (id) => {
  const response = await axiosRequest.post(tasks.CLINIC_DELETE_TASK, { id })
  return { id, status: response.data?.status }
})
//Delete Selected Tasks
export const deleteSelectedTasks = createAsyncThunk('clinicTasks/deleteSelectedTasks', async (id) => {
  const response = await axiosRequest.post(tasks.CLINIC_DELETE_TASK, id)
  console.log('-----deleteSelectedTasks----', response?.data)
  return { id, status: response.data?.status }
})
export const getBetterSameIndividual = createAsyncThunk('clinicTasks/getBetterSameIndividual', async () => {
  const response = await axiosRequest.get(tasks.CLINIC_TASKS_BETTER_SAME_INDIVIDUAL)
  return {
    betterIndividual: response?.data?.Better_individual_task,
    sameIndividual: response?.data?.Same_individual_task
  }
})

export const getBetterSameCompletion = createAsyncThunk('clinicTasks/getBetterSameCompletion', async () => {
  const response = await axiosRequest.get(tasks.CLINIC_TASKS_BETTER_SAME_COMPLETION)
  return {
    betterCompletion: response?.data?.Better_after_completion,
    sameCompletion: response?.data?.Same_after_completion
  }
})

export const adminTasksSlice = createSlice({
  name: 'adminTasks',
  initialState: {
    data: [],
    loading: true,
    betterIndividual: '',
    sameIndividual: '',
    betterCompletion: '',
    sameCompletion: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAdminTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setTasks.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getAdminTasks.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getAdminTasks.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteTask.fulfilled, (state, { payload }) => {
        console.log(`DELETED TASK ID ${payload?.id}`)
      })
      .addCase(deleteSelectedTasks.fulfilled, (state, { payload }) => {
        console.log(`DELETED TASK ID ${payload?.id}`)
      })
      .addCase(getBetterSameIndividual.fulfilled, (state, { payload }) => {
        state.betterIndividual = payload?.betterIndividual
        state.sameIndividual = payload?.sameIndividual
      })
      .addCase(getBetterSameCompletion.fulfilled, (state, { payload }) => {
        state.betterCompletion = payload?.betterCompletion
        state.sameCompletion = payload?.sameCompletion
      })
  }
})

export default adminTasksSlice.reducer
