// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dashboard, tasks } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'

export const getFrontDashboard = createAsyncThunk('frontDashboard/getFrontDashboard', async () => {
  const response = await axiosRequest.get(dashboard.FRONT_DASHBOARD)
  return { data: response?.data?.data ?? {} }
})
//
export const getRecentClassesDashboard = createAsyncThunk('frontDashboard/getRecentClassesDashboard', async () => {
  const response = await axiosRequest.get(dashboard.RECENT_DASHBOARD_CLASSES)
  return { data: response?.data?.data ?? {} }
})
//
export const featuredClassFront = createAsyncThunk('frontDashboard/featuredClassFront', async () => {
  const response = await axiosRequest.get(dashboard.FEATURE_CLASS)
  return { data: response?.data?.data ?? {} }
})


// ** GET Assignment Graph
export const getAssignmentGraph = createAsyncThunk('frontDashboard/getAssignmentGraph', async () => {
  const response = await axiosRequest.get(dashboard?.FRONT_ASSIGNMENT_GRAPH)
  return { data: response?.data?.data ?? {} }
})
// ** GET Assignment Graph
export const getTasksGraph = createAsyncThunk('frontDashboard/getTasksGraph', async () => {
  const response = await axiosRequest.get(dashboard.FRONT_TASKS_GRAPH)
  return { data: response?.data?.data ?? {} }
})

export const onCompleteSignleTask = createAsyncThunk('frontDashboard/onCompleteSignleTask', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tasks.FRONT_SINGLE_TASK_COMPLETE, data)
  if (response?.data?.status) {
    dispatch(getTasksGraph())
  }
  return {
    status: response?.data?.status ?? {}
  }
})
export const getUserAverge = createAsyncThunk('frontDashboard/getUserAverge', async () => {
  const response = await axiosRequest.get(tasks.FRONT_USER_AVERAGE)
  return {
    data: response?.data?.data ?? {}
  }
})
export const dashboardSlice = createSlice({
  name: 'frontDashboard',
  initialState: {
    data: {},
    loading: true,
    updateData: '',
    recentClassesDashboard: [],
    featureClass: [],
    imageUpload: '',
    completeTask: '',
    assignmentGrapData: {},
    assignmentGrapDataLoading: false,
    tasksGraphData: {},
    tasksGraphDataLoading: false,
    userAvergae: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFrontDashboard.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getFrontDashboard.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getFrontDashboard.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      //
      .addCase(getRecentClassesDashboard.fulfilled, (state, { payload }) => {
        state.loading = false
        state.recentClassesDashboard = payload.data
      })
      .addCase(getRecentClassesDashboard.pending, (state) => {
        state.loading = true
      })
      .addCase(getRecentClassesDashboard.rejected, (state) => {
        state.loading = false
      })
      //
      .addCase(featuredClassFront.fulfilled, (state, { payload }) => {
        state.loading = false
        state.featureClass = payload.data
      })
      .addCase(featuredClassFront.pending, (state) => {
        state.loading = true
      })
      .addCase(featuredClassFront.rejected, (state) => {
        state.loading = false
      })

      //   .addCase(updateFrontAccountDetails.fulfilled, (state, { payload }) => {
      //     state.updateData = payload?.status
      //     console.log('Data is Updated Successfully', state.updateData)
      //   })
      .addCase(getAssignmentGraph.fulfilled, (state, { payload }) => {
        state.assignmentGrapData = payload?.data ?? []
        state.assignmentGrapDataLoading = false
      })
      .addCase(getAssignmentGraph.pending, (state) => {
        state.assignmentGrapDataLoading = true
      })
      .addCase(getAssignmentGraph.rejected, (state) => {
        state.assignmentGrapDataLoading = false
      })
      .addCase(getTasksGraph.fulfilled, (state, { payload }) => {
        state.tasksGraphData = payload?.data ?? []
        state.tasksGraphDataLoading = false
      })
      .addCase(getTasksGraph.pending, (state) => {
        state.tasksGraphDataLoading = true
      })
      .addCase(getTasksGraph.rejected, (state) => {
        state.tasksGraphDataLoading = false
      })
      .addCase(onCompleteSignleTask.fulfilled, (state, { payload }) => {
        state.completeTask = payload?.status
        console.log('---------On Complete Sigle Task-------', state.completeTask)
      })
      .addCase(getUserAverge.fulfilled, (state, { payload }) => {
        state.userAvergae = payload?.data ?? {}
      })
  }
})

export default dashboardSlice.reducer
