// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { dashboard, tasks } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
import { displaySuccessToast } from '../../../../utility/helper'

export const getClinicDashboard = createAsyncThunk('clinicDashboard/getClinicDashboard', async () => {
  const response = await axiosRequest.get(dashboard.CLINIC_DASHBOARD)
  return { data: response?.data?.data ?? {} }
})
export const getRecentClinicClassesDashboard = createAsyncThunk('frontDashboard/getRecentClinicClassesDashboard', async () => {
  const response = await axiosRequest.get(dashboard.RECENT_DASHBOARD_CLASSES)
  return { data: response?.data?.data ?? {} }
})
//

export const featuredClassClinic = createAsyncThunk('frontDashboard/featuredClassClinic', async () => {
  const response = await axiosRequest.get(dashboard.FEATURE_CLASS)
  return { data: response?.data?.data ?? {} }
})
// ** GET Assignment Graph
export const getAssignmentGraph = createAsyncThunk('clinicDashboard/getAssignmentGraph', async () => {
  const response = await axiosRequest.get(dashboard.CLINIC_ASSIGNMENT_GRAPH)
  return { data: response?.data?.data ?? {} }
})
// ** GET Assignment Graph
export const getTasksGraph = createAsyncThunk('clinicDashboard/getTasksGraph', async () => {
  const response = await axiosRequest.get(dashboard.FRONT_TASKS_GRAPH)
  return { data: response?.data?.data ?? {} }
})
// // ** UPDATE ACCOUNTS DETAILS
// export const updateFrontAccountDetails = createAsyncThunk('frontDashboard/updateFrontAccountDetails', async (data) => {
//   const response = await axiosRequest.post(dashboard.FRONT_ACCONUTS_UPDATE, data)
//   console.log('----------updateFrontAccountDetails---------', response)
//   return { status: response?.data?.status }
// })

export const onCompleteSignleTask = createAsyncThunk('clinicDashboard/onCompleteSignleTask', async (data, { dispatch }) => {
  const response = await axiosRequest.post(tasks.FRONT_SINGLE_TASK_COMPLETE, data)
  if (response?.data?.status) {
    dispatch(getTasksGraph())
  }
  return {
    status: response?.data?.status ?? {}
  }
})

export const getUserAverge = createAsyncThunk('clinicDashboard/getUserAverge', async () => {
  const response = await axiosRequest.get(tasks.FRONT_USER_AVERAGE)
  return {
    data: response?.data?.data ?? {}
  }
})

export const dashboardSlice = createSlice({
  name: 'clinicDashboard',
  initialState: {
    data: {},
    loading: true,
    updateData: '',
    recentClinicClassesDashboard: [],
    featureClass: [],
    imageUpload: '',
    completeTask: '',
    assignmentGrapData: {},
    assignmentGrapDataLoading: false,
    tasksGraphData: {},
    tasksGraphDataLoading: false,
    userAvergae: {},
    userAvergaeLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getClinicDashboard.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(getClinicDashboard.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getClinicDashboard.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      //
      .addCase(getRecentClinicClassesDashboard.fulfilled, (state, { payload }) => {
        state.loading = false
        state.recentClinicClassesDashboard = payload.data
      })
      .addCase(getRecentClinicClassesDashboard.pending, (state) => {
        state.loading = true
      })
      .addCase(getRecentClinicClassesDashboard.rejected, (state) => {
        state.loading = false
      })
      //
      .addCase(featuredClassClinic.fulfilled, (state, { payload }) => {
        state.loading = false
        state.featureClass = payload.data
      })
      .addCase(featuredClassClinic.pending, (state) => {
        state.loading = true
      })
      .addCase(featuredClassClinic.rejected, (state) => {
        state.loading = false
      })
      //   .addCase(updateFrontAccountDetails.fulfilled, (state, { payload }) => {
      //     state.updateData = payload?.status
      //     console.log('Data is Updated Successfully', state.updateData)
      //   })
      .addCase(getAssignmentGraph.fulfilled, (state, { payload }) => {
        state.assignmentGrapData = payload?.data ?? []
        state.assignmentGrapDataLoading = false
      })
      .addCase(getAssignmentGraph.pending, (state) => {
        state.assignmentGrapDataLoading = true
      })
      .addCase(getAssignmentGraph.rejected, (state) => {
        state.assignmentGrapDataLoading = false
      })
      .addCase(getTasksGraph.fulfilled, (state, { payload }) => {
        state.tasksGraphData = payload?.data ?? []
        state.tasksGraphDataLoading = false
      })
      .addCase(getTasksGraph.pending, (state) => {
        state.tasksGraphDataLoading = true
      })
      .addCase(getTasksGraph.rejected, (state) => {
        state.tasksGraphDataLoading = false
      })
      .addCase(onCompleteSignleTask.fulfilled, (state, { payload }) => {
        state.completeTask = payload?.status ?? ''
        displaySuccessToast('Task Completed Successfully!')
      })
      .addCase(getUserAverge.fulfilled, (state, { payload }) => {
        state.userAvergae = payload?.data ?? []
        state.userAvergaeLoading = false
      })
      .addCase(getUserAverge.pending, (state) => {
        state.userAvergaeLoading = true
      })
      .addCase(getUserAverge.rejected, (state) => {
        state.userAvergaeLoading = false
      })
  }
})

export default dashboardSlice.reducer
