// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { inquiries } from '../../../../APIRoutes'
import axiosRequest from '../../../../APIRoutes/axiosCall'
export const getInquiries = createAsyncThunk('adminInquiries/getInquiries', async () => {
  const response = await axiosRequest.get(inquiries.ADMIN_INQUIRIES)
  return { data: response?.data?.data }
})

export const setInquiries = createAsyncThunk('adminInquiries/setInquiries', async (data) => {
  return { data }
})

export const replyInquiry = createAsyncThunk('adminInquiries/replyInquiry', async (data, { dispatch }) => {
  const response = await axiosRequest.post(inquiries.ADMIN_REPLY_INQUIRIES, data)
  if (response?.data?.status) {
    dispatch(getInquiries())
  }
  return { status: response?.data?.status }
})

export const deleteInquiries = createAsyncThunk('adminInquiries/deleteInquiries', async (ids, { dispatch }) => {
  const response = await axiosRequest.post(inquiries.ADMIN_DELETE_INQUIRIES, { ids })
  if (response?.data?.status) {
    dispatch(getInquiries())
  }
  return { ids, status: response.data?.status }
})

export const readInquiries = createAsyncThunk('adminInquiries/readInquiries', async (ids, { dispatch }) => {
  const response = await axiosRequest.post(inquiries.ADMIN_READ_INQUIRIES, { ids })
  if (response?.data?.status) {
    dispatch(getInquiries())
  }
  return { ids, status: response.data?.status }
})

export const inquiriesSlice = createSlice({
  name: 'adminInquiries',
  initialState: {
    data: [],
    loading: true
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getInquiries.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
        state.loading = false
      })
      .addCase(setInquiries.fulfilled, (state, { payload }) => {
        state.data = payload?.data ?? []
      })
      .addCase(getInquiries.pending, (state) => {
        console.log("LOADING...")
        state.loading = true
      })
      .addCase(getInquiries.rejected, (state) => {
        console.log("REJECTED...")
        state.loading = false
      })
      .addCase(deleteInquiries.fulfilled, (state, { payload }) => {
        console.log(`DELETED IDS ${payload?.ids}`)
      })
      .addCase(readInquiries.fulfilled, (state, { payload }) => {
        console.log(`MARKED IDS ${payload?.ids}`)
      })
      .addCase(replyInquiry.fulfilled, (state, { payload }) => {
        console.log(`REPLY SENT ${payload?.status}`)
      })
  }
})

export default inquiriesSlice.reducer
